import "./Instruction.scss";

import { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

import { Container } from "@mui/material";
import Alert from "@mui/material/Alert";

const InstructionBrainFarm = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Container>
            <div className="pveRulesTitle">Brain farm instruction</div>

            <div className="pveRulesWrapper">
                <div className="pveInstructionSubtitle">Purpose of the game</div>
                <div className="pveRulesItem pveInstructionItem">
                    You need to build a brain factory and accumulate a certain number of brains to finish the game and
                    get a reward. Brains are produced by factories, which you unlock, acquire and upgrade over the
                    course of the game. There is no limit to the amount of games you can play. You can however only
                    start a new game after the last one has been completed.
                </div>

                <div className="pveInstructionSubtitle">How to play</div>
                <div className="pveRulesItem pveInstructionItem">
                    Initially you have access to a port and a laboratory, also at the beginning of the game you have the
                    necessary amount of brains to buy the first factory. After the factory produces brains you need to
                    send them to the factory warehouse by clicking on factory, then you need to send a ship to transport
                    the brains from the factory warehouse to the port warehouse by simply clicking on port, then click
                    on the lab to start processing the brains and credit them to your balance.
                </div>
                <div className="pveRulesItem pveInstructionItem">
                    To automate all processes and not to constantly click on all buildings you need to hire managers for
                    each building. Managers automate all processes, increase the productivity of buildings and make it
                    possible to run the game in the background, they are hired and pumped for ZOMB tokens. The higher
                    the level of the manager, the more efficient the building is.
                </div>
                <div className="pveRulesItem pveInstructionItem">
                    During the game you have access to tasks (the “TASKS” button in the upper right corner), by
                    completing which you increase your chances of getting a reward.
                </div>
                <Alert className="pveRulesAlert" severity="warning">
                    Please note that for tasks related to PvE mode the turnover accounting is suspended every Sunday
                    from 12:00 UTC to 00:00 UTC Monday (
                    <Link className="pveInstructionLink" to="/docs/lotteryRules">
                        point 2 of the rules
                    </Link>
                    ).
                </Alert>
                <div className="pveRulesItem pveInstructionItem">
                    Clicking on the “CONTINUE” button will show you your objectives for finishing the game. This is how
                    many brains you should have on your balance, as well as how much turnover in PvE you need to make.
                    You can also see the probabilities and the size of the reward at the end of the game.
                </div>
                <div className="pveRulesItem pveInstructionItem">
                    You don't have to keep the browser tab with the game open all the time. When automating processes
                    (buying managers) the game will run in the background, i.e. even when the game is closed the
                    production of brains (factory production, transportation by ship and processing by lab) will
                    continue to run, but the performance of all buildings will be reduced by 50%.
                </div>
                <div className="pveRulesItem pveInstructionItem">
                    Your game progress is saved every minute. The save indicator is located below the main game window.
                </div>
                <div className="pveRulesItem pveInstructionItem">
                    When you open the “brain farm” game page, the game will start from your last save. The brains
                    produced from the last save to the current moment will be added to the balance of all storages - 50%
                    of the production.
                </div>
                <div className="pveRulesItem pveInstructionItem">
                    Upgrade your buildings to increase your brains production.
                </div>

                <div className="pveInstructionSubtitle">Main window interface</div>
                <img src="/img/farm/instructionScreenshot.png" alt="pve formula walken" className="pveInstructionImg" />

                <div className="pveInstructionSubtitle">Economics of the game</div>
                <div className="pveRulesItem pveInstructionItem">
                    Your reward at the end of the game depends on how many tokens you spent on buying managers. You
                    don't have to buy and upgrade all managers, you can choose the pace of the game that suits you by
                    buying as many managers and upgrading them to the level you see fit. By clicking on the “CONTINUE”
                    button you can see the size of the possible reward and the probability of getting the corresponding
                    reward. You can increase your chances of getting a reward by completing tasks during the game. There
                    is no reward for players who have not bought any managers.
                </div>
                <Alert className="pveRulesAlert" severity="warning" sx={{ textAlign: "justify" }}>
                    10% of the purchase or pumping cost of each manager will be burned. Burning will be done once a week
                    on Sundays of all tokens that have accumulated during this period. Tokens are accounted for only
                    after the end of the game (at once for all managers in the game, not for each purchase or pumping
                    separately).
                </Alert>
                <div className="pveRulesItem pveInstructionItem">
                    Under the referral system you can receive % of all manager purchases by your referral. Referral
                    rewards are given immediately for all purchases when your referral completes the game.
                </div>

                <div className="pveInstructionSubtitle">Support</div>
                <div className="pveRulesItem pveInstructionItem">
                    The game is in beta testing mode, so there may be problems at any stage. For all problems and
                    questions, please contact{" "}
                    <a
                        className="pveInstructionLink"
                        href="https://discord.gg/TfBFUEvTrd"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Discord 2/3/Z
                    </a>{" "}
                    on the game channel.
                </div>
            </div>
        </Container>
    );
};

export default InstructionBrainFarm;
