import { Link } from "react-router-dom";

import Container from "@mui/material/Container";

const NotFoundPage = () => {
    return (
        <>
            <Container>
                <div className="notFoundWrapper">
                    <div className="notFoundText">Page not found...</div>
                    <Link className="notFoundLink" to="/">
                        Back to the main page
                    </Link>
                </div>
            </Container>
        </>
    );
};

export default NotFoundPage;
