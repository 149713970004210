import { useSelector } from "react-redux";

import LoginRegister from "../loginRegister/LoginRegister";
import Lottery from "../lottery/Lottery";
import MobileMessage from "../mobileMessage/MobileMessage";

import Container from "@mui/material/Container";

const LotteryPage = () => {
    const isAuth = useSelector((state) => state.authorization.isAuth);

    return (
        <>
            <Container>
                {!isAuth ? <LoginRegister /> : <Lottery />}
                <MobileMessage />
            </Container>
        </>
    );
};

export default LotteryPage;
