import { createSlice } from "@reduxjs/toolkit";

const brainFarmRewardsTasksSlice = createSlice({
    name: "brainFarmRewardsTasks",
    initialState: {
        bronzeRewardShare: 0.255,
        bronzeRewardC: 0.3,
        silverRewardShare: 0.09,
        silverRewardC: 1.15,
        goldRewardShare: 0.005,
        goldRewardC: 2.5,
        brainsForComplete: "100000000000000000",
        turnoverForComplete: 1000,
        tasksFactory: [
            {
                taskId: 0,
                type: "factory",
                factoryId: 200,
                maxLevel: 100,
                typeReward: "bronze",
                rewardValue: 0.001,
                completed: false,
            },
            {
                taskId: 1,
                type: "factory",
                factoryId: 200,
                maxLevel: 200,
                typeReward: "bronze",
                rewardValue: 0.002,
                completed: false,
            },
            {
                taskId: 2,
                type: "factory",
                factoryId: 200,
                maxLevel: 300,
                typeReward: "bronze",
                rewardValue: 0.003,
                completed: false,
            },
            {
                taskId: 3,
                type: "factory",
                factoryId: 100,
                maxLevel: 100,
                typeReward: "bronze",
                rewardValue: 0.001,
                completed: false,
            },
            {
                taskId: 4,
                type: "factory",
                factoryId: 100,
                maxLevel: 200,
                typeReward: "bronze",
                rewardValue: 0.002,
                completed: false,
            },
            {
                taskId: 5,
                type: "factory",
                factoryId: 100,
                maxLevel: 300,
                typeReward: "bronze",
                rewardValue: 0.003,
                completed: false,
            },
            {
                taskId: 6,
                type: "factory",
                factoryId: 0,
                maxLevel: 100,
                typeReward: "bronze",
                rewardValue: 0.001,
                completed: false,
            },
            {
                taskId: 7,
                type: "factory",
                factoryId: 0,
                maxLevel: 200,
                typeReward: "bronze",
                rewardValue: 0.002,
                completed: false,
            },
            {
                taskId: 8,
                type: "factory",
                factoryId: 0,
                maxLevel: 300,
                typeReward: "bronze",
                rewardValue: 0.003,
                completed: false,
            },
            {
                taskId: 9,
                type: "factory",
                factoryId: 1,
                maxLevel: 100,
                typeReward: "bronze",
                rewardValue: 0.001,
                completed: false,
            },
            {
                taskId: 10,
                type: "factory",
                factoryId: 1,
                maxLevel: 200,
                typeReward: "bronze",
                rewardValue: 0.002,
                completed: false,
            },
            {
                taskId: 11,
                type: "factory",
                factoryId: 1,
                maxLevel: 300,
                typeReward: "silver",
                rewardValue: 0.002,
                completed: false,
            },
            {
                taskId: 12,
                type: "factory",
                factoryId: 2,
                maxLevel: 100,
                typeReward: "bronze",
                rewardValue: 0.003,
                completed: false,
            },
            {
                taskId: 13,
                type: "factory",
                factoryId: 2,
                maxLevel: 200,
                typeReward: "bronze",
                rewardValue: 0.006,
                completed: false,
            },
            {
                taskId: 14,
                type: "factory",
                factoryId: 2,
                maxLevel: 300,
                typeReward: "silver",
                rewardValue: 0.003,
                completed: false,
            },
            {
                taskId: 15,
                type: "factory",
                factoryId: 3,
                maxLevel: 100,
                typeReward: "bronze",
                rewardValue: 0.002,
                completed: false,
            },
            {
                taskId: 16,
                type: "factory",
                factoryId: 3,
                maxLevel: 150,
                typeReward: "bronze",
                rewardValue: 0.003,
                completed: false,
            },
            {
                taskId: 17,
                type: "factory",
                factoryId: 3,
                maxLevel: 200,
                typeReward: "bronze",
                rewardValue: 0.004,
                completed: false,
            },
            {
                taskId: 18,
                type: "factory",
                factoryId: 3,
                maxLevel: 250,
                typeReward: "silver",
                rewardValue: 0.001,
                completed: false,
            },
            {
                taskId: 19,
                type: "factory",
                factoryId: 3,
                maxLevel: 300,
                typeReward: "silver",
                rewardValue: 0.004,
                completed: false,
            },
            {
                taskId: 20,
                type: "factory",
                factoryId: 4,
                maxLevel: 50,
                typeReward: "bronze",
                rewardValue: 0.005,
                completed: false,
            },
            {
                taskId: 21,
                type: "factory",
                factoryId: 4,
                maxLevel: 100,
                typeReward: "bronze",
                rewardValue: 0.007,
                completed: false,
            },
            {
                taskId: 22,
                type: "factory",
                factoryId: 4,
                maxLevel: 150,
                typeReward: "silver",
                rewardValue: 0.004,
                completed: false,
            },
            {
                taskId: 23,
                type: "factory",
                factoryId: 4,
                maxLevel: 200,
                typeReward: "silver",
                rewardValue: 0.005,
                completed: false,
            },
            {
                taskId: 24,
                type: "factory",
                factoryId: 4,
                maxLevel: 250,
                typeReward: "gold",
                rewardValue: 0.001,
                completed: false,
            },
            {
                taskId: 25,
                type: "factory",
                factoryId: 4,
                maxLevel: 300,
                typeReward: "gold",
                rewardValue: 0.003,
                completed: false,
            },
        ],
        tasksManager: [
            {
                taskId: 0,
                type: "manager",
                factoryId: 200,
                maxLevel: 3,
                typeReward: "silver",
                rewardValue: 0.003,
                completed: false,
            },
            {
                taskId: 1,
                type: "manager",
                factoryId: 200,
                maxLevel: 5,
                typeReward: "gold",
                rewardValue: 0.002,
                completed: false,
            },
            {
                taskId: 2,
                type: "manager",
                factoryId: 100,
                maxLevel: 3,
                typeReward: "silver",
                rewardValue: 0.003,
                completed: false,
            },
            {
                taskId: 3,
                type: "manager",
                factoryId: 100,
                maxLevel: 5,
                typeReward: "gold",
                rewardValue: 0.002,
                completed: false,
            },
            {
                taskId: 4,
                type: "manager",
                factoryId: 0,
                maxLevel: 3,
                typeReward: "silver",
                rewardValue: 0.002,
                completed: false,
            },
            {
                taskId: 5,
                type: "manager",
                factoryId: 0,
                maxLevel: 5,
                typeReward: "gold",
                rewardValue: 0.002,
                completed: false,
            },
            {
                taskId: 6,
                type: "manager",
                factoryId: 1,
                maxLevel: 3,
                typeReward: "silver",
                rewardValue: 0.002,
                completed: false,
            },
            {
                taskId: 7,
                type: "manager",
                factoryId: 1,
                maxLevel: 5,
                typeReward: "gold",
                rewardValue: 0.003,
                completed: false,
            },
            {
                taskId: 8,
                type: "manager",
                factoryId: 2,
                maxLevel: 3,
                typeReward: "silver",
                rewardValue: 0.004,
                completed: false,
            },
            {
                taskId: 9,
                type: "manager",
                factoryId: 2,
                maxLevel: 5,
                typeReward: "gold",
                rewardValue: 0.003,
                completed: false,
            },
            {
                taskId: 10,
                type: "manager",
                factoryId: 3,
                maxLevel: 3,
                typeReward: "silver",
                rewardValue: 0.005,
                completed: false,
            },
            {
                taskId: 11,
                type: "manager",
                factoryId: 3,
                maxLevel: 5,
                typeReward: "gold",
                rewardValue: 0.003,
                completed: false,
            },
            {
                taskId: 12,
                type: "manager",
                factoryId: 4,
                maxLevel: 3,
                typeReward: "silver",
                rewardValue: 0.006,
                completed: false,
            },
            {
                taskId: 13,
                type: "manager",
                factoryId: 4,
                maxLevel: 5,
                typeReward: "gold",
                rewardValue: 0.005,
                completed: false,
            },
        ],
        tasksTurnover: [
            {
                taskId: 0,
                type: "turnover",
                factoryId: 9000,
                maxLevel: 5000,
                typeReward: "gold",
                rewardValue: 0.005,
                completed: false,
            },
            {
                taskId: 1,
                type: "turnover",
                factoryId: 9000,
                maxLevel: 10000,
                typeReward: "gold",
                rewardValue: 0.01,
                completed: false,
            },
        ],
    },
    reducers: {
        setNewGameTasks(state, action) {
            state.bronzeRewardShare = action.payload.rewards.bronzeRewardShare;
            state.silverRewardShare = action.payload.rewards.silverRewardShare;
            state.goldRewardShare = action.payload.rewards.goldRewardShare;
            state.bronzeRewardC = action.payload.rewards.bronzeRewardC;
            state.silverRewardC = action.payload.rewards.silverRewardC;
            state.goldRewardC = action.payload.rewards.goldRewardC;

            state.brainsForComplete = action.payload.completionConditions.brainsForComplete;
            state.turnoverForComplete = action.payload.completionConditions.turnoverForComplete;

            state.tasksFactory = action.payload.tasksFactory;
            state.tasksManager = action.payload.tasksManager;
            state.tasksTurnover = action.payload.tasksTurnover;
        },
        setBronzeRewardShare(state, action) {
            state.bronzeRewardShare = state.bronzeRewardShare + action.payload;
        },
        setSilverRewardShare(state, action) {
            state.silverRewardShare = state.silverRewardShare + action.payload;
        },
        setGoldRewardShare(state, action) {
            state.goldRewardShare = state.goldRewardShare + action.payload;
        },
        setTasksFactory(state, action) {
            state.tasksFactory = action.payload;
        },
        setTasksManager(state, action) {
            state.tasksManager = action.payload;
        },
        setTasksTurnover(state, action) {
            state.tasksTurnover = action.payload;
        },
    },
});

export const {
    setNewGameTasks,
    setBronzeRewardShare,
    setSilverRewardShare,
    setGoldRewardShare,
    setTasksFactory,
    setTasksManager,
    setTasksTurnover,
} = brainFarmRewardsTasksSlice.actions;

export default brainFarmRewardsTasksSlice.reducer;
