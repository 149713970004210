import { createSlice } from "@reduxjs/toolkit";

const gameStatsSlice = createSlice({
    name: "gameStats",
    initialState: {
        stats: {},
    },
    reducers: {
        setStats(state, action) {
            state.stats = action.payload;
        },
    },
});

export const { setStats } = gameStatsSlice.actions;

export default gameStatsSlice.reducer;
