import "./Deposit.scss";
import { baseUrl } from "../axios/axios";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ethers } from "ethers";

import { setDepositBalance } from "../../store/userSlice";
import { setProcessPlay, setHideBalance } from "../../store/gameProcessSlice";

import { useWeb3React } from "@web3-react/core";
import { connectors } from "../../config/connectors";
import { networkParams } from "../../config/networks";
import { mainChainId, rpc } from "../../config/constants";
import { getDisplayBalance } from "../utils/displayBalance";
import { formatAddress } from "../utils/formatAddress";
import ABI_ERC20 from "../../config/abi/ERC20.json";

import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import Skeleton from "@mui/material/Skeleton";

const toHex = (num) => {
    const val = Number(num);
    return "0x" + val.toString(16);
};

const Deposit = () => {
    const user = useSelector((state) => state.user.user);
    const hideBalance = useSelector((state) => state.gameProcess.hideBalance);
    const processPlay = useSelector((state) => state.gameProcess.processPlay);

    const [deposit, setDeposit] = useState(user.maxDeposit);
    const [loadingDeposit, setLoadingDeposit] = useState(false);
    const [disableInput, setDisableInput] = useState(false);
    const [walletBalance, setWalletBalance] = useState("");
    const [loadingWalletBalance, setLoadingWalletBalance] = useState(false);
    const [errorTransaction, setErrorTransaction] = useState(false);
    const [errorFromServer, setErrorFromServer] = useState("");

    const dispatch = useDispatch();

    const defaultErrorMsg = "Error! Reload the page and try again.";

    const { library, chainId, account, activate, deactivate } = useWeb3React();

    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const contract = new ethers.Contract(process.env.REACT_APP_ZOMB_ADDRESS, ABI_ERC20, provider);

    // set axios request
    const depositRequest = axios.create({
        withCredentials: true,
        baseURL: baseUrl,
    });
    depositRequest.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
        return config;
    });
    depositRequest.interceptors.response.use(
        (config) => {
            return config;
        },
        async (error) => {
            const originalRequest = error.config;

            if (error.response.status === 401 && error.config && !error.config._isRetry) {
                originalRequest._isRetry = true;
                axios
                    .get(`${baseUrl}/refresh`, {
                        withCredentials: true,
                    })
                    .then((res) => {
                        localStorage.setItem("token", res.data.accessToken);

                        depositRequest
                            .request(originalRequest)
                            .then((resDeposit) => {
                                // console.log(resDeposit.data);
                                dispatch(setDepositBalance(resDeposit.data));
                            })
                            .catch((e) => {
                                setErrorFromServer(e.response?.data?.message);
                                console.log(e.response?.data?.message);
                            });
                    })
                    .catch((e) => {
                        console.log("You not authorize");
                    });
            } else {
                throw error;
            }
        }
    );

    const changeDeposit = (e) => {
        if (e.target.value < user.minDeposit) {
            setDeposit("");
        } else if (e.target.value >= user.maxDeposit) {
            setDeposit(user.maxDeposit);
        } else {
            setDeposit(e.target.value);
        }
    };

    const switchNetwork = async (network) => {
        setErrorFromServer("");

        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: toHex(network) }],
            });
        } catch (switchError) {
            if (switchError.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [networkParams[toHex(network)]],
                    });
                } catch (e) {
                    console.log(e);
                    setErrorFromServer(e);
                }
            }
        }
    };

    useEffect(() => {
        const provider = window.localStorage.getItem("provider");
        if (provider) {
            activate(connectors[provider]);
        }
    }, []);

    useEffect(() => {
        coinInWallet();
    }, [account]);

    const disconnect = () => {
        window.localStorage.setItem("provider", undefined);
        deactivate();
        setErrorFromServer("");
    };

    const connectWallet = () => {
        activate(connectors.injected);
        window.localStorage.setItem("provider", "injected");
        setErrorFromServer("");
    };

    const coinInWallet = async () => {
        setLoadingWalletBalance(true);

        try {
            const balance = await contract.balanceOf(account);
            setWalletBalance(getDisplayBalance(balance, 18, 2));
        } catch (err) {
            console.error(`Failed to fetch wallet balance: ${err}`);
        }

        setLoadingWalletBalance(false);
    };

    const onDeposit = async () => {
        setErrorFromServer("");
        setErrorTransaction(false);

        if (walletBalance < deposit) {
            setErrorFromServer("Not enough ZOMB in your wallet.");
            return;
        }
        if (account.toLowerCase() !== user.userWalletAddress && user.userWalletAddress !== "") {
            setErrorFromServer("The address of the connected wallet does not match to the previously linked address.");
            return;
        }
        if (deposit === "" || deposit < user.minDeposit) {
            setErrorFromServer("The value is not set or is less than the allowable value.");
            return;
        }
        if (!user.userDepositAllowed || !user.globalDepositAllowed) {
            setErrorFromServer("The deposit feature has been temporarily disabled.");
            return;
        }

        setDisableInput(true);
        setLoadingDeposit(true);
        setLoadingWalletBalance(true);
        dispatch(setProcessPlay(true));
        dispatch(setHideBalance(true));

        try {
            // transaction
            const signer = contract.connect(library.getSigner());
            const amount = ethers.utils.parseUnits(String(deposit), 18);
            const tx = await signer.transfer(process.env.REACT_APP_RECIPIENT_ADDRESS, amount);

            tx.wait()
                .then((res) => {
                    // console.log(res);
                    const data = {
                        signature: res.transactionHash,
                        email: user.userEmail,
                    };

                    depositRequest
                        .post("/deposit", { data })
                        .then((res2) => {
                            if (res2 !== undefined) {
                                // console.log(res2.data);
                                dispatch(setDepositBalance(res2.data));
                            }
                        })
                        .catch((e) => {
                            setErrorFromServer(e.response?.data?.message);
                            console.log(e.response?.data?.message);
                        });

                    coinInWallet();
                })
                .catch((e) => {
                    console.error(`error tx ${e}`);
                    setErrorFromServer("Transaction failed, please try again.");
                })
                .finally(() => {
                    setLoadingDeposit(false);
                    setDisableInput(false);
                    dispatch(setProcessPlay(false));
                    dispatch(setHideBalance(false));
                });
        } catch (err) {
            // user's reject
            if (err.code === 4001) {
                setDisableInput(false);
                setLoadingDeposit(false);
                setLoadingWalletBalance(false);
                dispatch(setProcessPlay(false));
                dispatch(setHideBalance(false));
            } else {
                setErrorTransaction(true);
                console.log(err);
            }
        }
    };

    const skeleton = (width, height) => {
        return <Skeleton className="pveSkeleton" width={width} height={height} />;
    };

    return (
        <div className="pveBalanceWrapper">
            <div className="pveBalanceTitle">Your balance</div>
            <div className="pveBalanceValue">
                <span>{hideBalance ? skeleton(70) : Math.round(user.balance * user.decimal) / user.decimal}</span>{" "}
                <span className="pveBalanceTokenSymbol">{user.tokenSymbol}</span>
            </div>

            <img className="pveBalanceBloodyLine" src="/img/bloody-line-750-25.png" alt="bloody line" />
            {/* <div className="pveBalanceLine"></div> */}

            <div className="pveBalanceDepositTitle">Deposit</div>

            {account ? (
                <LoadingButton
                    className="pveBalanceBtnConnect pveBalanceBtnDisconnect"
                    variant="contained"
                    onClick={disconnect}
                >
                    Disconnect
                </LoadingButton>
            ) : (
                <>
                    <LoadingButton className="pveBalanceBtnConnect" variant="contained" onClick={connectWallet}>
                        Connect wallet
                    </LoadingButton>
                    <div className="pveBalanceSupportedWallets">
                        <div className="pveBalanceSupportedWalletsItem">Supported wallets:</div>
                        <div className="pveBalanceSupportedWalletsItem">
                            <img
                                className="pveBalanceSupportedWalletsImg"
                                src="/img/metamask.svg"
                                alt="metamask logo"
                            />
                            <div className="pveBalanceSupportedWalletsText">Metamask</div>
                        </div>
                        <div className="pveBalanceSupportedWalletsItem">
                            <img className="pveBalanceSupportedWalletsImg" src="/img/rabby.png" alt="rabby logo" />
                            <div className="pveBalanceSupportedWalletsText">Rabby</div>
                        </div>
                    </div>
                </>
            )}

            {account ? (
                <div className="pveBalanceWalletTokensWrapper">
                    <div className="pveBalanceAddress">Connected: {formatAddress(account)}</div>

                    {chainId !== mainChainId ? (
                        <div className="pveBalanceWrongNetworkWrapper">
                            <div className="pveBalanceWrongNetwork">Wrong network is selected.</div>
                            <LoadingButton
                                className="pveBalanceBtnConnect pveBalanceBtnSwitchNetwork"
                                variant="contained"
                                onClick={() => {
                                    switchNetwork(mainChainId);
                                }}
                            >
                                Switch network
                            </LoadingButton>
                        </div>
                    ) : (
                        <>
                            <div className="pveBalanceWalletTokensTitle">In your wallet:</div>
                            <div className="pveBalanceWalletTokensWlkn">
                                {loadingWalletBalance ? skeleton(70) : walletBalance} {user.tokenSymbol}
                            </div>
                        </>
                    )}
                </div>
            ) : null}

            <div className="pveBalanceDepositWrapper">
                <FormControl
                    variant="standard"
                    className={!disableInput ? "pveBalanceAmountDeposit" : "pveBalanceAmountDepositDisable"}
                >
                    <Input
                        id="deposit-amount"
                        className={!disableInput ? "pveBalanceDepositInput" : "pveBalanceDepositInputDisable"}
                        endAdornment={<InputAdornment position="end">{user.tokenSymbol}</InputAdornment>}
                        aria-describedby="deposit-amount-helper-text"
                        inputProps={{
                            "aria-label": "deposit amount",
                        }}
                        type="number"
                        onChange={(e) => changeDeposit(e)}
                        value={deposit}
                        disabled={disableInput}
                    />
                    <FormHelperText id="deposit-amount-helper-text">
                        Deposit amount. <br />
                        Limits: {user.minDeposit}-{user.maxDeposit}
                    </FormHelperText>
                </FormControl>
                <LoadingButton
                    className="pveBalanceBtnDeposit"
                    variant="contained"
                    color="zombieGreen"
                    disabled={!account || processPlay || chainId !== 250}
                    loading={loadingDeposit}
                    onClick={onDeposit}
                >
                    Deposit
                </LoadingButton>
            </div>

            {errorTransaction ? <div className="pveBalanceErrorMessage">{defaultErrorMsg}</div> : null}
            {errorFromServer !== "" ? <div className="pveBalanceErrorMessage">{errorFromServer}</div> : null}

            <div className="pveBalanceDepositDexWrapper">
                <div className="brainBuyTextWrapper">
                    <div className="brainBuyText">
                        buy <span>Z</span>
                    </div>
                </div>
                <Button className="brainBuyBtn" variant="contained" color="farmBlack">
                    <a
                        className="brainBuyBtnA"
                        href="https://pyreswap.finance/swap?inputCurrency=ETH&outputCurrency=0x1e6B8866Ff62De4894C92eF454799d299Abd1F2D&chainId=250"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src="/img/farm/firePyreswap.png" alt="fire pyreswap" className="brainBuyBtnIcon" />
                        PyreSwap
                    </a>
                </Button>
            </div>
        </div>
    );
};

export default Deposit;
