import "./ModalTasks.scss";
import { useSelector } from "react-redux";

import TasksItem from "./TasksItem";

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

const ModalTasks = (props) => {
    const { showModalTasks, handleCloseModalTasks } = props;

    const tasksFactory = useSelector((state) => state.brainFarmRewardsTasks.tasksFactory);
    const tasksManager = useSelector((state) => state.brainFarmRewardsTasks.tasksManager);
    const tasksTurnover = useSelector((state) => state.brainFarmRewardsTasks.tasksTurnover);

    const bronzeValue = useSelector((state) => state.brainFarmRewardsTasks.bronzeRewardShare);
    const silverValue = useSelector((state) => state.brainFarmRewardsTasks.silverRewardShare);
    const goldValue = useSelector((state) => state.brainFarmRewardsTasks.goldRewardShare);

    const bronzePercent = Math.round(bronzeValue * 100 * 100) / 100;
    const silverPercent = Math.round(silverValue * 100 * 100) / 100;
    const goldPercent = Math.round(goldValue * 100 * 100) / 100;

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showModalTasks}
                onClose={handleCloseModalTasks}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                disableAutoFocus={true}
                sx={{
                    width: "calc(100% - 15px)",
                }}
            >
                <Fade in={showModalTasks}>
                    <div className="brainModalTasksWrapper">
                        <div className="brainModalTasksWrapperInternal">
                            <div className="brainModalManagerCloseImg" onClick={handleCloseModalTasks}></div>

                            <div className="brainModalManagerTitle">Tasks</div>

                            <div className="brainModalTasksAwardWrapper">
                                <div className="brainModalTasksAwardTitle">Award probabilities</div>

                                <div className="brainModalTasksAwardValueWrapper">
                                    <div className="brainModalTasksAwardValueItem">
                                        <img
                                            src="/img/farm/coinBronze.png"
                                            alt="z-coin bronze"
                                            className="brainModalTasksAwardValueImg"
                                        />
                                        <div className="brainModalTasksAwardValueText brainModalTasksAwardValueTextBronze">
                                            {`${bronzePercent}%`}
                                        </div>
                                    </div>
                                    <div className="brainModalTasksAwardValueItem">
                                        <img
                                            src="/img/farm/coinSilver.png"
                                            alt="z-coin silver"
                                            className="brainModalTasksAwardValueImg"
                                        />
                                        <div className="brainModalTasksAwardValueText brainModalTasksAwardValueTextSilver">
                                            {`${silverPercent}%`}
                                        </div>
                                    </div>
                                    <div className="brainModalTasksAwardValueItem">
                                        <img
                                            src="/img/farm/coinGold.png"
                                            alt="z-coin gold"
                                            className="brainModalTasksAwardValueImg"
                                        />
                                        <div className="brainModalTasksAwardValueText brainModalTasksAwardValueTextGold">
                                            {`${goldPercent}%`}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="brainModalTasksMainContentWrapper">
                                <div className="brainModalTasksMainContentItem">
                                    <div className="brainModalTasksMainContentTitle">Factories</div>
                                    {tasksFactory
                                        ? tasksFactory.map((task) => {
                                              return (
                                                  <TasksItem
                                                      key={task.taskId}
                                                      type={task.type}
                                                      factoryId={task.factoryId}
                                                      maxLevel={task.maxLevel}
                                                      typeReward={task.typeReward}
                                                      rewardValue={task.rewardValue}
                                                      completed={task.completed}
                                                  />
                                              );
                                          })
                                        : null}
                                </div>
                                <div className="brainModalTasksMainContentItem">
                                    <div className="brainModalTasksMainContentTitle">Managers</div>
                                    {tasksManager
                                        ? tasksManager.map((task) => {
                                              return (
                                                  <TasksItem
                                                      key={task.taskId}
                                                      type={task.type}
                                                      factoryId={task.factoryId}
                                                      maxLevel={task.maxLevel}
                                                      typeReward={task.typeReward}
                                                      rewardValue={task.rewardValue}
                                                      completed={task.completed}
                                                  />
                                              );
                                          })
                                        : null}

                                    <div className="brainModalTasksMainContentTitle">Turnover PvE</div>
                                    {tasksTurnover
                                        ? tasksTurnover.map((task) => {
                                              return (
                                                  <TasksItem
                                                      key={task.taskId}
                                                      type={task.type}
                                                      factoryId={task.factoryId}
                                                      maxLevel={task.maxLevel}
                                                      typeReward={task.typeReward}
                                                      rewardValue={task.rewardValue}
                                                      completed={task.completed}
                                                  />
                                              );
                                          })
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export default ModalTasks;
