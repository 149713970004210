import "./ReferralProgram.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../axios/axios";
import { Link } from "react-router-dom";
import ProgressCircularPercent from "../ProgressCircularPercent/ProgressCircularPercent";
import { updateTime } from "../utils/updateTime";

import { useSelector, useDispatch } from "react-redux";
import { setRefBalance } from "../../store/userSlice";
import { setProcessPlay } from "../../store/gameProcessSlice";

import LoadingButton from "@mui/lab/LoadingButton";
import Skeleton from "@mui/material/Skeleton";
import Container from "@mui/material/Container";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const ReferralProgram = (props) => {
    const user = useSelector((state) => state.user.user);
    const hideBalance = useSelector((state) => state.gameProcess.hideBalance);

    const [loadingTransfer, setLoadingTransfer] = useState(false);
    const [errorTransfer, setErrorTransfer] = useState(false);
    const [errorMsg, setErrorMsg] = useState("Error!");

    const [showBalance, setShowBalance] = useState(true);
    const [calculate, setCalculate] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        const timer = setInterval(() => {
            const currentDate = new Date();

            if (currentDate.getUTCHours() === 1) {
                setCalculate(true);
            } else {
                setCalculate(false);
            }
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    // set axios request
    const transferRefBalanceRequest = axios.create({
        withCredentials: true,
        baseURL: baseUrl,
    });
    transferRefBalanceRequest.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
            "token"
        )}`;
        return config;
    });
    transferRefBalanceRequest.interceptors.response.use(
        (config) => {
            return config;
        },
        async (error) => {
            const originalRequest = error.config;

            if (
                error.response.status === 401 &&
                error.config &&
                !error.config._isRetry
            ) {
                originalRequest._isRetry = true;
                axios
                    .get(`${baseUrl}/refresh`, {
                        withCredentials: true,
                    })
                    .then((res) => {
                        localStorage.setItem("token", res.data.accessToken);

                        dispatch(setProcessPlay(true));
                        setLoadingTransfer(true);
                        transferRefBalanceRequest
                            .request(originalRequest)
                            .then((res2) => {
                                // console.log(res2.data);
                                dispatch(setRefBalance(res2.data));
                            })
                            .catch((e) => {
                                console.log(e.response?.data?.message);
                                setErrorTransfer(true);
                                setErrorMsg(e.response?.data?.message);
                            })
                            .finally(() => {
                                setLoadingTransfer(false);
                                setShowBalance(true);
                                dispatch(setProcessPlay(false));
                            });
                    })
                    .catch((e) => {
                        console.log("you not authorize");
                    });
            } else {
                throw error;
            }
        }
    );

    const transferBalance = () => {
        setErrorTransfer(false);
        setErrorMsg("");
        setLoadingTransfer(true);
        setShowBalance(false);
        dispatch(setProcessPlay(true));
        const userEmail = user.userEmail;

        transferRefBalanceRequest
            .post("/changeRefBalance", { userEmail })
            .then((res) => {
                console.log(res.data);
                if (res !== undefined) {
                    dispatch(setRefBalance(res.data));

                    setLoadingTransfer(false);
                    setShowBalance(true);
                    dispatch(setProcessPlay(false));
                }
            })
            .catch((e) => {
                console.log(e.response?.data?.message);
                setErrorTransfer(true);
                setErrorMsg(e.response?.data?.message);
                setShowBalance(true);
                dispatch(setProcessPlay(false));
            });
    };

    // hint over
    const hintOver = (hintElement, imgAttribute, contractAttribute) => (e) => {
        const elements = document.querySelectorAll(hintElement);
        const hintImgSc = e.target.getAttribute(imgAttribute);

        elements.forEach((item) => {
            const atrContract = item.getAttribute(contractAttribute);
            if (atrContract === hintImgSc) {
                item.classList.remove("hide");
                item.classList.add("show");
            }
        });
    };
    // hint out
    const hintOut = (hintElement, imgAttribute, contractAttribute) => (e) => {
        const elements = document.querySelectorAll(hintElement);
        const hintImgSc = e.target.getAttribute(imgAttribute);

        elements.forEach((item) => {
            const atrContract = item.getAttribute(contractAttribute);
            if (atrContract === hintImgSc) {
                item.classList.remove("show");
                item.classList.add("hide");
            }
        });
    };
    // click to copy contract address
    const clickToCopy = (e) => {
        const copyImg = e.target;
        const contract = e.target.getAttribute("data-hint-img-contract");
        const allChecks = document.querySelectorAll("[data-copy-check]");
        const allHint = document.querySelectorAll("[data-hint-text]");

        copyImg.classList.remove("instant-show");
        copyImg.classList.add("instant-hide");

        allChecks.forEach((item) => {
            const check = item.getAttribute("data-copy-check");
            if (contract === check) {
                item.classList.remove("instant-hide");
                item.classList.add("instant-show");

                allHint.forEach((hint) => {
                    const hintContract = hint.getAttribute("data-hint-text");
                    if (contract === hintContract) {
                        hint.textContent = "Copied";

                        navigator.clipboard.writeText(contract).then(() => {
                            setTimeout(() => {
                                item.classList.remove("instant-show");
                                item.classList.add("instant-hide");
                                copyImg.classList.remove("instant-hide");
                                copyImg.classList.add("instant-show");
                                hint.textContent = "Copy code";
                            }, 1000);
                        });
                    }
                });
            }
        });
    };

    const skeleton = (width, height) => {
        return (
            <Skeleton className="pveSkeleton" width={width} height={height} />
        );
    };

    return (
        <Container>
            <div className="pveRefWrapper">
                <div className="pveWithdrawLine"></div>
                <div className="pveBalanceDepositTitle">Referral program</div>
                <div className="pveLotteryDescriptionWrapper smoothBackground">
                    <div className="pveLotteryDescription">
                        Invite your friends and get % of every bet of the
                        invited! Read more about the terms and conditions{" "}
                        <Link
                            className="pveLotteryRulesLink"
                            to="/docs/referralProgram"
                        >
                            here
                        </Link>
                        .
                    </div>
                </div>

                <div className="pveRefCodesWrapper">
                    <div className="pveRefCodesItem">
                        <div className="pveRefCodesItemWrapper">
                            <div className="pveRefCodesTitle">
                                Your referral code:
                            </div>
                            <div className="pveRefCodesValue">
                                {user.refCode}
                            </div>
                            {/* copy icon */}
                            <div className="listCopyWrapper">
                                <img
                                    className="listCopyIcon instant-show"
                                    src="/img/copyIcon.svg"
                                    alt="copy icon"
                                    data-hint-img-contract={user.refCode}
                                    onMouseEnter={hintOver(
                                        "[data-hint-contract]",
                                        "data-hint-img-contract",
                                        "data-hint-contract"
                                    )}
                                    onMouseLeave={hintOut(
                                        "[data-hint-contract]",
                                        "data-hint-img-contract",
                                        "data-hint-contract"
                                    )}
                                    onClick={clickToCopy}
                                />
                                <img
                                    className="listCopyCheckIcon instant-hide"
                                    src="/img/checkCircleIcon.svg"
                                    alt="check in circle"
                                    data-copy-check={user.refCode}
                                    data-hint-img-contract={user.refCode}
                                    onMouseEnter={hintOver(
                                        "[data-hint-contract]",
                                        "data-hint-img-contract",
                                        "data-hint-contract"
                                    )}
                                    onMouseLeave={hintOut(
                                        "[data-hint-contract]",
                                        "data-hint-img-contract",
                                        "data-hint-contract"
                                    )}
                                />
                                <div
                                    className="listHintCopyWrapper listHintCopyPosition hide"
                                    data-hint-contract={user.refCode}
                                >
                                    <div
                                        className="listHintCopyText"
                                        data-hint-text={user.refCode}
                                    >
                                        Copy code
                                    </div>
                                    <div className="listHintCopyTriangle"></div>
                                </div>
                            </div>
                        </div>
                        <div className="pveRefCodesItemWrapper">
                            <div className="pveRefCodesTitle">Your %:</div>
                            <div className="pveRefCodesPercentValue">
                                {user.refPercent}
                            </div>
                            <Link
                                className="pveLotteryRulesLink pveRefCodesLinkMore"
                                to="/docs/referralProgram"
                            >
                                read more
                            </Link>
                        </div>
                    </div>
                    <div className="pveRefCodesItem">
                        <div className="pveRefCodesItemWrapper">
                            <div className="pveRefCodesTitle">
                                You are invited:
                            </div>
                            <div className="pveRefCodesValue pveRefCodesValueInvited">
                                {user.invited === "" ? "------" : user.invited}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pveRefUpdateInfo">
                    Last referral update:{" "}
                    {updateTime(user.lastUpdateReferralStats)}
                </div>

                {!calculate ? (
                    <>
                        <div className="pveRefBalanceWrapper">
                            <div className="pveRefBalanceItem">
                                <div className="pveFaucetBalanceTitle pveRefBalanceTitle">
                                    Your referral balance
                                </div>
                                <div className="pveBalanceValue">
                                    {showBalance ? (
                                        <>
                                            <span>{user.referralBalance}</span>{" "}
                                            <span className="pveBalanceTokenSymbol">
                                                {user.tokenSymbol}
                                            </span>
                                        </>
                                    ) : (
                                        skeleton(70)
                                    )}
                                </div>
                            </div>
                            <div className="pveRefBalanceItem">
                                <div className="pveRefBalanceArrow">
                                    <KeyboardDoubleArrowRightIcon
                                        className="pveRefBalanceArrowIcon"
                                        color="zombieGreen"
                                    />
                                </div>
                            </div>
                            <div className="pveRefBalanceItem">
                                <div className="pveFaucetBalanceTitle pveRefBalanceTitle">
                                    Your main balance
                                </div>
                                <div className="pveBalanceValue">
                                    {showBalance ? (
                                        <>
                                            <span>
                                                {hideBalance
                                                    ? skeleton(70)
                                                    : Math.round(
                                                          user.balance *
                                                              user.decimal
                                                      ) / user.decimal}
                                            </span>{" "}
                                            <span className="pveBalanceTokenSymbol">
                                                {user.tokenSymbol}
                                            </span>
                                        </>
                                    ) : (
                                        skeleton(70)
                                    )}
                                </div>
                            </div>
                        </div>

                        <LoadingButton
                            className="pveRefBtn"
                            variant="contained"
                            onClick={transferBalance}
                            loading={loadingTransfer}
                            disabled={user.referralBalance <= 0}
                        >
                            Transfer to main balance
                        </LoadingButton>

                        {errorTransfer ? (
                            <div className="pveFaucetWinText pveFaucetError">
                                {errorMsg}
                            </div>
                        ) : null}

                        <div className="pveFaucetBalanceTitle pveRefNumberRefTitle">
                            Number of referrals invited
                        </div>
                        <div className="pveRefNumberRefValue">
                            {user.referralCount}
                        </div>

                        <div className="pveFaucetBalanceTitle pveRefStatsTitle">
                            Your referral reward statistic,{" "}
                            <span className="pveBalanceTokenSymbol pveRefStatsTitleSymbol">
                                {user.tokenSymbol}
                            </span>
                        </div>
                        <div className="pveRefStatsWrapper">
                            <div className="pveRefStatsItem">
                                <div className="pveRefStatsPeriodName">Day</div>
                                <div className="pveRefStatsValue">
                                    {user.referralStats.rewardsDay}
                                </div>
                            </div>
                            <div className="pveRefStatsItem">
                                <div className="pveRefStatsPeriodName">
                                    Week
                                </div>
                                <div className="pveRefStatsValue">
                                    {user.referralStats.rewardsWeek}
                                </div>
                            </div>
                            <div className="pveRefStatsItem">
                                <div className="pveRefStatsPeriodName">
                                    Month
                                </div>
                                <div className="pveRefStatsValue">
                                    {user.referralStats.rewardsMonth}
                                </div>
                            </div>
                        </div>
                        <div className="pveRefStatsItem pveRefStatsItemAllTime">
                            <div className="pveRefStatsPeriodName">
                                All time
                            </div>
                            <div className="pveRefStatsValue">
                                {user.referralStats.rewardsAllTime}
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="pveRefCalculate">
                        <ProgressCircularPercent />
                        <div className="pveRefCalculate">
                            From 01:00 UTC to 02:00 UTC referral rewards are
                            being calculated, please wait.
                        </div>
                    </div>
                )}
            </div>
        </Container>
    );
};

export default ReferralProgram;
