/* global BigInt */
import "./Factory.scss";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    setDecreaseWarehouseMain,
    setWarehouseFactories,
    setFactory_0_value,
    setFactory_0_base_value,
    setFactory_0_lvl,
    setFactory_0_price_lvl_up,
    setFactory_0_work,
    setFactory_0_manager_lvl_up,
    setFactory_0_bought,
    setFactory_0_prod_ready,
    setFactory_0_speed,
    setFactory_1_value,
    setFactory_1_base_value,
    setFactory_1_lvl,
    setFactory_1_price_lvl_up,
    setFactory_1_work,
    setFactory_1_manager_lvl_up,
    setFactory_1_bought,
    setFactory_1_prod_ready,
    setFactory_1_speed,
    setFactory_2_value,
    setFactory_2_base_value,
    setFactory_2_lvl,
    setFactory_2_price_lvl_up,
    setFactory_2_work,
    setFactory_2_manager_lvl_up,
    setFactory_2_bought,
    setFactory_2_prod_ready,
    setFactory_2_speed,
    setFactory_3_value,
    setFactory_3_base_value,
    setFactory_3_lvl,
    setFactory_3_price_lvl_up,
    setFactory_3_work,
    setFactory_3_manager_lvl_up,
    setFactory_3_bought,
    setFactory_3_prod_ready,
    setFactory_3_speed,
    setFactory_4_value,
    setFactory_4_base_value,
    setFactory_4_lvl,
    setFactory_4_price_lvl_up,
    setFactory_4_work,
    setFactory_4_manager_lvl_up,
    setFactory_4_bought,
    setFactory_4_prod_ready,
    setFactory_4_speed,
} from "../../../store/brainFarmSlice";

import ModalManager from "./ModalManager";
import {
    stopPlayAnimation,
    disableFactoryCursorPointer,
    multipleAnimation,
    circularAnimation1Time,
    levelLinePercentFactory,
    openModal,
    closeModal,
    abbreviationNumbers,
} from "../functions";

import Button from "@mui/material/Button";

const Factory = (props) => {
    const { factoryId, saveIntervalId, saveInterval, circularAnimationName, shakeAnimationName, positionClass } = props;

    const dispatch = useDispatch();

    const intervalShakeRef = useRef(0);
    const intervalHandProdRef = useRef(0);
    const intervalAutoProdRef = useRef(0);

    const [showModalManager, setShowModalManager] = useState(false);
    const [factoryMultipleLvl, setFactoryMultipleLvl] = useState(2);

    const handleCloseModalManager = () => {
        setShowModalManager(false);
        closeModal();
    };
    const handleShowModalManager = () => {
        setShowModalManager(true);
        openModal();
    };

    let mainIcon = "";
    let classMainIcon = "";
    let managerImg = "";

    if (factoryId === 0) {
        mainIcon = "/img/farm/shovel100.png";
        classMainIcon = "brainFactoryMainIcon0";
        managerImg = "/img/farm/shovelManager200.png";
    } else if (factoryId === 1) {
        mainIcon = "/img/farm/minerHelmet100.png";
        classMainIcon = "brainFactoryMainIcon1";
        managerImg = "/img/farm/minerManager200.png";
    } else if (factoryId === 2) {
        mainIcon = "/img/farm/book100.png";
        classMainIcon = "brainFactoryMainIcon2";
        managerImg = "/img/farm/templeManager200.png";
    } else if (factoryId === 3) {
        mainIcon = "/img/farm/canningFactory100.png";
        classMainIcon = "brainFactoryMainIcon3";
        managerImg = "/img/farm/canningManager200.png";
    } else if (factoryId === 4) {
        mainIcon = "/img/farm/bank100.png";
        classMainIcon = "brainFactoryMainIcon4";
        managerImg = "/img/farm/bankManager200.png";
    }

    const warehouse_main = useSelector((state) => state.brainFarm.warehouse_main);
    const factory_c_lvl_up = useSelector((state) => state.brainFarm.factory_c_lvl_up);

    const saveLoaded = useSelector((state) => state.brainFarm.saveLoaded);

    const factory_0_value = useSelector((state) => state.brainFarm.factory_0_value);
    const factory_0_base_value = useSelector((state) => state.brainFarm.factory_0_base_value);
    const factory_0_init_base_value = useSelector((state) => state.brainFarm.factory_0_init_base_value);
    const factory_0_lvl = useSelector((state) => state.brainFarm.factory_0_lvl);
    const factory_0_speed = useSelector((state) => state.brainFarm.factory_0_speed);
    const factory_0_init_speed = useSelector((state) => state.brainFarm.factory_0_init_speed);
    const factory_0_price_lvl_up = useSelector((state) => state.brainFarm.factory_0_price_lvl_up);
    const factory_0_work = useSelector((state) => state.brainFarm.factory_0_work);
    const factory_0_manager_lvl = useSelector((state) => state.brainFarm.factory_0_manager_lvl);
    const factory_0_manager_price = useSelector((state) => state.brainFarm.factory_0_manager_price);
    const factory_0_manager_value = useSelector((state) => state.brainFarm.factory_0_manager_value);
    const factory_0_init_price = useSelector((state) => state.brainFarm.factory_0_init_price);
    const factory_0_bought = useSelector((state) => state.brainFarm.factory_0_bought);
    const factory_0_prod_ready = useSelector((state) => state.brainFarm.factory_0_prod_ready);

    const factory_1_value = useSelector((state) => state.brainFarm.factory_1_value);
    const factory_1_base_value = useSelector((state) => state.brainFarm.factory_1_base_value);
    const factory_1_init_base_value = useSelector((state) => state.brainFarm.factory_1_init_base_value);
    const factory_1_lvl = useSelector((state) => state.brainFarm.factory_1_lvl);
    const factory_1_speed = useSelector((state) => state.brainFarm.factory_1_speed);
    const factory_1_init_speed = useSelector((state) => state.brainFarm.factory_1_init_speed);
    const factory_1_price_lvl_up = useSelector((state) => state.brainFarm.factory_1_price_lvl_up);
    const factory_1_work = useSelector((state) => state.brainFarm.factory_1_work);
    const factory_1_manager_lvl = useSelector((state) => state.brainFarm.factory_1_manager_lvl);
    const factory_1_manager_price = useSelector((state) => state.brainFarm.factory_1_manager_price);
    const factory_1_manager_value = useSelector((state) => state.brainFarm.factory_1_manager_value);
    const factory_1_init_price = useSelector((state) => state.brainFarm.factory_1_init_price);
    const factory_1_bought = useSelector((state) => state.brainFarm.factory_1_bought);
    const factory_1_prod_ready = useSelector((state) => state.brainFarm.factory_1_prod_ready);

    const factory_2_value = useSelector((state) => state.brainFarm.factory_2_value);
    const factory_2_base_value = useSelector((state) => state.brainFarm.factory_2_base_value);
    const factory_2_init_base_value = useSelector((state) => state.brainFarm.factory_2_init_base_value);
    const factory_2_lvl = useSelector((state) => state.brainFarm.factory_2_lvl);
    const factory_2_speed = useSelector((state) => state.brainFarm.factory_2_speed);
    const factory_2_init_speed = useSelector((state) => state.brainFarm.factory_2_init_speed);
    const factory_2_price_lvl_up = useSelector((state) => state.brainFarm.factory_2_price_lvl_up);
    const factory_2_work = useSelector((state) => state.brainFarm.factory_2_work);
    const factory_2_manager_lvl = useSelector((state) => state.brainFarm.factory_2_manager_lvl);
    const factory_2_manager_price = useSelector((state) => state.brainFarm.factory_2_manager_price);
    const factory_2_manager_value = useSelector((state) => state.brainFarm.factory_2_manager_value);
    const factory_2_init_price = useSelector((state) => state.brainFarm.factory_2_init_price);
    const factory_2_bought = useSelector((state) => state.brainFarm.factory_2_bought);
    const factory_2_prod_ready = useSelector((state) => state.brainFarm.factory_2_prod_ready);

    const factory_3_value = useSelector((state) => state.brainFarm.factory_3_value);
    const factory_3_base_value = useSelector((state) => state.brainFarm.factory_3_base_value);
    const factory_3_init_base_value = useSelector((state) => state.brainFarm.factory_3_init_base_value);
    const factory_3_lvl = useSelector((state) => state.brainFarm.factory_3_lvl);
    const factory_3_speed = useSelector((state) => state.brainFarm.factory_3_speed);
    const factory_3_init_speed = useSelector((state) => state.brainFarm.factory_3_init_speed);
    const factory_3_price_lvl_up = useSelector((state) => state.brainFarm.factory_3_price_lvl_up);
    const factory_3_work = useSelector((state) => state.brainFarm.factory_3_work);
    const factory_3_manager_lvl = useSelector((state) => state.brainFarm.factory_3_manager_lvl);
    const factory_3_manager_price = useSelector((state) => state.brainFarm.factory_3_manager_price);
    const factory_3_manager_value = useSelector((state) => state.brainFarm.factory_3_manager_value);
    const factory_3_init_price = useSelector((state) => state.brainFarm.factory_3_init_price);
    const factory_3_bought = useSelector((state) => state.brainFarm.factory_3_bought);
    const factory_3_prod_ready = useSelector((state) => state.brainFarm.factory_3_prod_ready);

    const factory_4_value = useSelector((state) => state.brainFarm.factory_4_value);
    const factory_4_base_value = useSelector((state) => state.brainFarm.factory_4_base_value);
    const factory_4_init_base_value = useSelector((state) => state.brainFarm.factory_4_init_base_value);
    const factory_4_lvl = useSelector((state) => state.brainFarm.factory_4_lvl);
    const factory_4_speed = useSelector((state) => state.brainFarm.factory_4_speed);
    const factory_4_init_speed = useSelector((state) => state.brainFarm.factory_4_init_speed);
    const factory_4_price_lvl_up = useSelector((state) => state.brainFarm.factory_4_price_lvl_up);
    const factory_4_work = useSelector((state) => state.brainFarm.factory_4_work);
    const factory_4_manager_lvl = useSelector((state) => state.brainFarm.factory_4_manager_lvl);
    const factory_4_manager_price = useSelector((state) => state.brainFarm.factory_4_manager_price);
    const factory_4_manager_value = useSelector((state) => state.brainFarm.factory_4_manager_value);
    const factory_4_init_price = useSelector((state) => state.brainFarm.factory_4_init_price);
    const factory_4_bought = useSelector((state) => state.brainFarm.factory_4_bought);
    const factory_4_prod_ready = useSelector((state) => state.brainFarm.factory_4_prod_ready);

    let factoryValue,
        factoryBaseValue,
        factoryInitBaseValue,
        factoryLvl,
        factorySpeed,
        factoryInitSpeed,
        factoryPriceLvlUp,
        factoryWork,
        factoryManager,
        factoryManagerPrice,
        factoryManagerValue,
        factoryInitPrice,
        factoryBought,
        factoryProdReady,
        setFactoryWork,
        setFactoryLvl,
        setFactoryValue,
        setFactoryBaseValue,
        setFactoryPriceLvlUp,
        setFactoryManagerLvlUp,
        setFactoryBought,
        setFactoryProdReady,
        setFactorySpeed = 0;

    if (factoryId === 0) {
        factoryValue = BigInt(factory_0_value);
        factoryBaseValue = BigInt(factory_0_base_value);
        factoryInitBaseValue = BigInt(factory_0_init_base_value);
        factoryLvl = factory_0_lvl;
        factorySpeed = factory_0_speed;
        factoryInitSpeed = factory_0_init_speed;
        factoryPriceLvlUp = BigInt(factory_0_price_lvl_up);
        factoryWork = factory_0_work;
        factoryManager = factory_0_manager_lvl;
        factoryManagerPrice = factory_0_manager_price;
        factoryManagerValue = factory_0_manager_value;
        factoryInitPrice = BigInt(factory_0_init_price);
        factoryBought = factory_0_bought;
        factoryProdReady = factory_0_prod_ready;

        setFactoryWork = (action) => {
            return setFactory_0_work(action);
        };
        setFactoryLvl = (action) => {
            return setFactory_0_lvl(action);
        };
        setFactoryValue = (action) => {
            return setFactory_0_value(action);
        };
        setFactoryBaseValue = (action) => {
            return setFactory_0_base_value(action);
        };
        setFactoryPriceLvlUp = (action) => {
            return setFactory_0_price_lvl_up(action);
        };
        setFactoryManagerLvlUp = (action) => {
            return setFactory_0_manager_lvl_up(action);
        };
        setFactoryBought = (action) => {
            return setFactory_0_bought(action);
        };
        setFactoryProdReady = (action) => {
            return setFactory_0_prod_ready(action);
        };
        setFactorySpeed = (action) => {
            return setFactory_0_speed(action);
        };
    } else if (factoryId === 1) {
        factoryValue = BigInt(factory_1_value);
        factoryBaseValue = BigInt(factory_1_base_value);
        factoryInitBaseValue = BigInt(factory_1_init_base_value);
        factoryLvl = factory_1_lvl;
        factorySpeed = factory_1_speed;
        factoryInitSpeed = factory_1_init_speed;
        factoryPriceLvlUp = BigInt(factory_1_price_lvl_up);
        factoryWork = factory_1_work;
        factoryManager = factory_1_manager_lvl;
        factoryManagerPrice = factory_1_manager_price;
        factoryManagerValue = factory_1_manager_value;
        factoryInitPrice = BigInt(factory_1_init_price);
        factoryBought = factory_1_bought;
        factoryProdReady = factory_1_prod_ready;

        setFactoryWork = (action) => {
            return setFactory_1_work(action);
        };
        setFactoryLvl = (action) => {
            return setFactory_1_lvl(action);
        };
        setFactoryValue = (action) => {
            return setFactory_1_value(action);
        };
        setFactoryBaseValue = (action) => {
            return setFactory_1_base_value(action);
        };
        setFactoryPriceLvlUp = (action) => {
            return setFactory_1_price_lvl_up(action);
        };
        setFactoryManagerLvlUp = (action) => {
            return setFactory_1_manager_lvl_up(action);
        };
        setFactoryBought = (action) => {
            return setFactory_1_bought(action);
        };
        setFactoryProdReady = (action) => {
            return setFactory_1_prod_ready(action);
        };
        setFactorySpeed = (action) => {
            return setFactory_1_speed(action);
        };
    } else if (factoryId === 2) {
        factoryValue = BigInt(factory_2_value);
        factoryBaseValue = BigInt(factory_2_base_value);
        factoryInitBaseValue = BigInt(factory_2_init_base_value);
        factoryLvl = factory_2_lvl;
        factorySpeed = factory_2_speed;
        factoryInitSpeed = factory_2_init_speed;
        factoryPriceLvlUp = BigInt(factory_2_price_lvl_up);
        factoryWork = factory_2_work;
        factoryManager = factory_2_manager_lvl;
        factoryManagerPrice = factory_2_manager_price;
        factoryManagerValue = factory_2_manager_value;
        factoryInitPrice = BigInt(factory_2_init_price);
        factoryBought = factory_2_bought;
        factoryProdReady = factory_2_prod_ready;

        setFactoryWork = (action) => {
            return setFactory_2_work(action);
        };
        setFactoryLvl = (action) => {
            return setFactory_2_lvl(action);
        };
        setFactoryValue = (action) => {
            return setFactory_2_value(action);
        };
        setFactoryBaseValue = (action) => {
            return setFactory_2_base_value(action);
        };
        setFactoryPriceLvlUp = (action) => {
            return setFactory_2_price_lvl_up(action);
        };
        setFactoryManagerLvlUp = (action) => {
            return setFactory_2_manager_lvl_up(action);
        };
        setFactoryBought = (action) => {
            return setFactory_2_bought(action);
        };
        setFactoryProdReady = (action) => {
            return setFactory_2_prod_ready(action);
        };
        setFactorySpeed = (action) => {
            return setFactory_2_speed(action);
        };
    } else if (factoryId === 3) {
        factoryValue = BigInt(factory_3_value);
        factoryBaseValue = BigInt(factory_3_base_value);
        factoryInitBaseValue = BigInt(factory_3_init_base_value);
        factoryLvl = factory_3_lvl;
        factorySpeed = factory_3_speed;
        factoryInitSpeed = factory_3_init_speed;
        factoryPriceLvlUp = BigInt(factory_3_price_lvl_up);
        factoryWork = factory_3_work;
        factoryManager = factory_3_manager_lvl;
        factoryManagerPrice = factory_3_manager_price;
        factoryManagerValue = factory_3_manager_value;
        factoryInitPrice = BigInt(factory_3_init_price);
        factoryBought = factory_3_bought;
        factoryProdReady = factory_3_prod_ready;

        setFactoryWork = (action) => {
            return setFactory_3_work(action);
        };
        setFactoryLvl = (action) => {
            return setFactory_3_lvl(action);
        };
        setFactoryValue = (action) => {
            return setFactory_3_value(action);
        };
        setFactoryBaseValue = (action) => {
            return setFactory_3_base_value(action);
        };
        setFactoryPriceLvlUp = (action) => {
            return setFactory_3_price_lvl_up(action);
        };
        setFactoryManagerLvlUp = (action) => {
            return setFactory_3_manager_lvl_up(action);
        };
        setFactoryBought = (action) => {
            return setFactory_3_bought(action);
        };
        setFactoryProdReady = (action) => {
            return setFactory_3_prod_ready(action);
        };
        setFactorySpeed = (action) => {
            return setFactory_3_speed(action);
        };
    } else if (factoryId === 4) {
        factoryValue = BigInt(factory_4_value);
        factoryBaseValue = BigInt(factory_4_base_value);
        factoryInitBaseValue = BigInt(factory_4_init_base_value);
        factoryLvl = factory_4_lvl;
        factorySpeed = factory_4_speed;
        factoryInitSpeed = factory_4_init_speed;
        factoryPriceLvlUp = BigInt(factory_4_price_lvl_up);
        factoryWork = factory_4_work;
        factoryManager = factory_4_manager_lvl;
        factoryManagerPrice = factory_4_manager_price;
        factoryManagerValue = factory_4_manager_value;
        factoryInitPrice = BigInt(factory_4_init_price);
        factoryBought = factory_4_bought;
        factoryProdReady = factory_4_prod_ready;

        setFactoryWork = (action) => {
            return setFactory_4_work(action);
        };
        setFactoryLvl = (action) => {
            return setFactory_4_lvl(action);
        };
        setFactoryValue = (action) => {
            return setFactory_4_value(action);
        };
        setFactoryBaseValue = (action) => {
            return setFactory_4_base_value(action);
        };
        setFactoryPriceLvlUp = (action) => {
            return setFactory_4_price_lvl_up(action);
        };
        setFactoryManagerLvlUp = (action) => {
            return setFactory_4_manager_lvl_up(action);
        };
        setFactoryBought = (action) => {
            return setFactory_4_bought(action);
        };
        setFactoryProdReady = (action) => {
            return setFactory_4_prod_ready(action);
        };
        setFactorySpeed = (action) => {
            return setFactory_4_speed(action);
        };
    }

    const factoryBuy = (buy, save = false) => {
        if (buy) {
            dispatch(setDecreaseWarehouseMain(factoryInitPrice.toString()));
            dispatch(setFactoryBought(true));

            circularAnimation1Time(factoryId, circularAnimationName, factorySpeed, stopPlayAnimation);
        }

        // save loaded
        if (save) {
            if (factoryBought) {
                circularAnimation1Time(factoryId, circularAnimationName, factorySpeed, stopPlayAnimation);
            }
        }

        // shake animation for hand collection
        const timerProdId = setTimeout(() => {
            // launch shake animation after hand collection
            if (!buy) {
                stopPlayAnimation(shakeAnimationName);
            }

            dispatch(setFactoryProdReady(true));

            const circleWrapper = document.querySelectorAll(".brainFactoryCircleWrapper");
            if (circleWrapper) {
                circleWrapper.forEach((elem) => {
                    const elemId = elem.getAttribute("data-factory-id");

                    if (elemId == factoryId) {
                        elem.style.animation = `${shakeAnimationName} 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both`;
                        elem.style.cursor = "pointer";

                        clearInterval(intervalShakeRef.current);

                        const timerIdShakeAnim = setInterval(() => {
                            stopPlayAnimation(shakeAnimationName);
                        }, 4000);

                        intervalShakeRef.current = timerIdShakeAnim;
                    }
                });
            }

            circularAnimation1Time(factoryId, circularAnimationName, 2, stopPlayAnimation);
        }, factorySpeed);
        intervalHandProdRef.current = timerProdId;
    };

    const factoryHandCollect = () => {
        if (factoryBought && factoryProdReady) {
            clearInterval(intervalShakeRef.current);

            dispatch(setWarehouseFactories(factoryValue.toString()));
            dispatch(setFactoryProdReady(false));

            circularAnimation1Time(factoryId, circularAnimationName, factorySpeed, stopPlayAnimation);

            disableFactoryCursorPointer(factoryId);

            factoryBuy(false);
        }
    };

    const factoryPlusProduct = () => {
        circularAnimation1Time(factoryId, circularAnimationName, factorySpeed, stopPlayAnimation);

        dispatch(setWarehouseFactories(factoryValue.toString()));
    };

    // buy managers
    useEffect(() => {
        if (!factoryWork && factoryManager > 0) {
            const timerIdFactory = setInterval(factoryPlusProduct, factorySpeed);
            intervalAutoProdRef.current = timerIdFactory;

            dispatch(setFactoryWork(true));
        }

        return () => {
            clearInterval(intervalAutoProdRef.current);
        };
    }, [factoryManager, factoryLvl]);

    const factoryPlusLvl = () => {
        // active hand mode (wait collect)
        if (factoryBought && factoryProdReady) {
            factoryHandCollect();
            // active hand mode (production process)
        } else if (factoryBought && !factoryProdReady && factoryManager === 0) {
            clearTimeout(intervalHandProdRef.current);
            factoryBuy(false);
        }

        stopPlayAnimation(circularAnimationName);

        clearInterval(intervalAutoProdRef.current);
        dispatch(setFactoryLvl(1));

        if (factoryLvl < 24) {
            dispatch(setFactoryValue((factoryValue + factoryBaseValue).toString()));
        } else if (factoryLvl === 24) {
            dispatch(setFactoryValue(((factoryValue + factoryBaseValue) * BigInt(2)).toString()));
            setFactoryMultipleLvl(2);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 24 && factoryLvl < 49) {
            dispatch(setFactoryValue((factoryValue + factoryBaseValue * BigInt(2)).toString()));
        } else if (factoryLvl === 49) {
            dispatch(setFactoryValue(((factoryValue + factoryBaseValue * BigInt(2)) * BigInt(4)).toString()));
            setFactoryMultipleLvl(4);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 49 && factoryLvl < 99) {
            dispatch(setFactoryValue((factoryValue + factoryBaseValue * BigInt(2) * BigInt(4)).toString()));
        } else if (factoryLvl === 99) {
            dispatch(
                setFactoryValue(((factoryValue + factoryBaseValue * BigInt(2) * BigInt(4)) * BigInt(8)).toString())
            );
            setFactoryMultipleLvl(8);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 99 && factoryLvl < 199) {
            dispatch(setFactoryValue((factoryValue + factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8)).toString()));
        } else if (factoryLvl === 199) {
            dispatch(
                setFactoryValue(
                    ((factoryValue + factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8)) * BigInt(16)).toString()
                )
            );
            setFactoryMultipleLvl(16);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 199 && factoryLvl < 299) {
            dispatch(
                setFactoryValue(
                    (factoryValue + factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16)).toString()
                )
            );
        } else if (factoryLvl === 299) {
            dispatch(
                setFactoryValue(
                    (
                        (factoryValue + factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16)) *
                        BigInt(32)
                    ).toString()
                )
            );
            setFactoryMultipleLvl(32);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 299) {
            dispatch(
                setFactoryValue(
                    (
                        factoryValue +
                        factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16) * BigInt(32)
                    ).toString()
                )
            );
        }

        dispatch(
            setFactoryPriceLvlUp(
                ((factoryPriceLvlUp * BigInt(Math.round(factory_c_lvl_up * 100))) / BigInt(100)).toString()
            )
        );
        dispatch(setDecreaseWarehouseMain(factoryPriceLvlUp.toString()));
        dispatch(setFactoryWork(false));
    };

    // if switch between page on site
    useEffect(() => {
        // ready hand collect
        if (factoryProdReady) {
            const circleWrapper = document.querySelectorAll(".brainFactoryCircleWrapper");
            if (circleWrapper) {
                circleWrapper.forEach((elem) => {
                    const elemId = elem.getAttribute("data-factory-id");

                    if (elemId == factoryId) {
                        elem.style.animation = `${shakeAnimationName} 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both`;
                        elem.style.cursor = "pointer";

                        clearInterval(intervalShakeRef.current);

                        const timerIdShakeAnim = setInterval(() => {
                            stopPlayAnimation(shakeAnimationName);
                        }, 4000);

                        intervalShakeRef.current = timerIdShakeAnim;
                    }
                });
            }

            circularAnimation1Time(factoryId, circularAnimationName, 2, stopPlayAnimation);
        }

        return () => {
            clearInterval(intervalShakeRef.current);
            clearTimeout(intervalHandProdRef.current);
            clearInterval(intervalAutoProdRef.current);
            dispatch(setFactoryProdReady(false));
            dispatch(setFactoryWork(false));
        };
    }, []);

    // save loaded
    useEffect(() => {
        if (factoryManager === 0 && factoryBought) {
            factoryBuy(false, true);
        } else if (factoryManager > 0 && factoryBought) {
            circularAnimation1Time(factoryId, circularAnimationName, factorySpeed, stopPlayAnimation);
        }
    }, [saveLoaded]);

    return (
        <div className={positionClass}>
            <ModalManager
                open={showModalManager}
                close={handleCloseModalManager}
                type={"factory"}
                factoryManager={factoryManager}
                factoryManagerPrice={factoryManagerPrice}
                factoryBought={factoryBought}
                factoryManagerValue={factoryManagerValue}
                factoryProdReady={factoryProdReady}
                shakeAnimationTimer={intervalShakeRef.current}
                factoryValue={factoryValue}
                setFactoryBaseValue={setFactoryBaseValue}
                factoryInitBaseValue={factoryInitBaseValue}
                setFactoryProdReady={setFactoryProdReady}
                circularAnimationName={circularAnimationName}
                factoryId={factoryId}
                setFactoryWork={setFactoryWork}
                setFactoryManagerLvlUp={setFactoryManagerLvlUp}
                setFactoryValue={setFactoryValue}
                handProductionTimer={intervalHandProdRef.current}
                autoCollectTimer={intervalAutoProdRef.current}
                factorySpeed={factorySpeed}
                setFactorySpeed={setFactorySpeed}
                factoryInitSpeed={factoryInitSpeed}
                factoryLvl={factoryLvl}
                managerImg={managerImg}
                managerIcon={mainIcon}
                saveIntervalId={saveIntervalId}
                saveInterval={saveInterval}
            />

            <div className="brainFactoryWrapper">
                {/* manager */}
                {factoryBought ? (
                    <div className="brainFactoryManagerWrapper" onClick={handleShowModalManager}>
                        {factoryManager > 0 ? (
                            <img src={managerImg} alt="manager icon" className="brainFactoryManagerImg" />
                        ) : (
                            <div className="brainFactoryManagerNoImg">?</div>
                        )}

                        <img src="/img/farm/farmArrow.png" alt="green arrow" className="brainFactoryManagerArrow" />
                    </div>
                ) : null}

                <div className="brainFactoryCircleWrapper" data-factory-id={factoryId} onClick={factoryHandCollect}>
                    <img src="/img/farm/factoryBaseCircle.png" alt="factory circle" className="brainFactoryCircleImg" />

                    <div className="brainFactoryCircle">
                        <img
                            src={mainIcon}
                            alt="factory icon"
                            className={
                                factoryBought ? classMainIcon + " grayscaleOff" : classMainIcon + " grayscaleOn70"
                            }
                        />
                        {factoryBought ? (
                            <div className="brainFactoryCircleValue">{abbreviationNumbers(factoryValue)}</div>
                        ) : (
                            <img
                                src="/img/farm/factoryCloset.png"
                                alt="factory closet icon"
                                className="brainFactoryClosetIcon"
                            />
                        )}

                        <div className="brainFactoryMultiple" data-factory-id={factoryId}>
                            x {factoryMultipleLvl}
                        </div>
                    </div>

                    <div className="brainFactoryCircular">
                        <div className="brainFactoryCircularProgressWrapper" data-factory-id={factoryId}>
                            <progress className="brainFactoryCircularProgress " min="0" max="100"></progress>
                        </div>
                    </div>
                </div>

                <div className="brainFactoryRectangle">
                    <img src="/img/farm/factoryBaseRectangle.png" alt="factory circle" />
                    {!factoryBought ? (
                        <Button
                            className="brainFactoryBtnInit"
                            variant="contained"
                            color="farmMain"
                            startIcon={
                                <img
                                    src="/img/farm/brainIcon.png"
                                    alt="brain"
                                    className="brainFactoryBtnImg brainFactoryBtnImgDisabled"
                                />
                            }
                            onClick={() => {
                                factoryBuy(true);
                            }}
                            disabled={BigInt(warehouse_main) < factoryInitPrice}
                            sx={{
                                "&.Mui-disabled": {
                                    background: "#818181",
                                    color: "#c0c0c0",
                                    border: "2px solid #706363 !important",
                                },
                            }}
                        >
                            {abbreviationNumbers(factoryInitPrice)}
                        </Button>
                    ) : (
                        <>
                            <div className="brainFactoryLevel">{factoryLvl}</div>
                            <div className="brainFactoryProgressBarLine">
                                <div
                                    className="brainFactoryProgressBarOuter"
                                    style={{
                                        width: `${70 * levelLinePercentFactory(factoryLvl)}px`,
                                    }}
                                ></div>
                            </div>
                            <Button
                                className="brainFactoryBtnLvl"
                                variant="contained"
                                color="farmMain"
                                onClick={factoryPlusLvl}
                                disabled={BigInt(warehouse_main) < factoryPriceLvlUp}
                                startIcon={
                                    <img
                                        src="/img/farm/brainIcon.png"
                                        alt="brain"
                                        className={
                                            BigInt(warehouse_main) < factoryPriceLvlUp
                                                ? "brainFactoryBtnImg brainFactoryBtnImgDisabled"
                                                : "brainFactoryBtnImg"
                                        }
                                    />
                                }
                                sx={{
                                    "&.Mui-disabled": {
                                        background: "#818181",
                                        color: "#c0c0c0",
                                        border: "2px solid #706363 !important",
                                    },
                                }}
                            >
                                {abbreviationNumbers(factoryPriceLvlUp)}
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Factory;
