export const networkParams = {
    "0xfa": {
        chainId: "0xfa",
        rpcUrls: ["https://rpc.ankr.com/fantom"],
        chainName: "Fantom Opera",
        nativeCurrency: { name: "FTM", decimals: 18, symbol: "FTM" },
        blockExplorerUrls: ["https://ftmscan.com"],
        iconUrls: [
            "https://seeklogo.com/images/F/fantom-ftm-logo-3566C53917-seeklogo.com.png",
        ],
    },
};
