import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Container } from "@mui/material";

const LotteryRules = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Container>
            <div className="pveRulesTitle">Lottery rules</div>
            <div className="pveRulesWrapper">
                <div className="pveRulesItem">
                    1. The lottery is open to any player who has received at
                    least 1 ticket.
                </div>
                <div className="pveRulesItem">
                    2. The lottery is held every week. From 00:00 UTC Monday to
                    12:00 UTC Sunday you receive tickets from the turnover of
                    your bets. From 12:00 UTC Sunday to 00:00 UTC Monday, ticket
                    issuance is closed and the current lottery is calculated and
                    prizes are issued.
                </div>
                <div className="pveRulesItem">
                    3. You get tickets from the turnover of your bets. <br />{" "}
                    Example: the turnover per ticket is 50 ZOMB. You have made 4
                    bets 10, 15, 20, 30. No matter if you won or lost, your
                    turnover is 10 + 15 + 20 + 30 = 75 ZOMB. You get 1 ticket.
                    If you make another bet 25, your turnover will be 75 + 25 =
                    100 and you will get a second ticket.
                </div>
                <div className="pveRulesItem">
                    4. The more tickets you have, the better your chances of
                    winning a prize. The winners are selected randomly among all
                    participants. 1 account cannot take more than 1 place.
                </div>
                <div className="pveRulesItem">
                    5. The prize is credited to the winner to your account
                    balance.
                </div>
                <div className="pveRulesItem">
                    6. The lottery has minimum requirements for the number of
                    tickets and participants. If at least 1 requirement is not
                    met, the lottery will not be calculated and no prizes will
                    be awarded. All your tickets will be carried over to the
                    next week. At the discretion of the administration the prize
                    pool will either be added to the next week's pool or will
                    not be added. Also for the new lottery the minimum
                    requirements for the number of tickets and the number of
                    participants are updated.
                </div>
                <div className="pveRulesItem">
                    7. Each new lottery has different terms and conditions. The
                    following parameters are subject to change:
                    <br /> - turnover per ticket
                    <br /> - prize pool
                    <br /> - minimum number of tickets
                    <br /> - minimum number of participants
                </div>
                <div className="pveRulesItem">
                    8. The administration will handle all lottery disputes on a
                    case-by-case basis.
                </div>
                <div className="pveRulesItem">
                    9. The administration reserves the right to stop the lottery
                    in case of errors, unfair play and other force majeure
                    cases.
                </div>
                <div className="pveRulesItem">
                    10. Lottery rules are subject to change. The current rules
                    are posted on this page.
                </div>
            </div>
        </Container>
    );
};

export default LotteryRules;
