/* global BigInt */
import { createSlice } from "@reduxjs/toolkit";

const brainFarmSlice = createSlice({
    name: "brainFarm",
    initialState: {
        saveLoaded: false, // save loaded from server
        gameRunning: false, // game is running or have to start a new game
        saveDate: 0, // date of last save
        spent: 0, // spent ZOMB

        warehouse_main: "6",
        warehouse_factories: "0", // warehouse for factories
        warehouse_port: "0", // warehouse for port

        port_value: "320",
        port_base_value: "320",
        port_init_base_value: "320",
        port_lvl: 1,
        port_init_speed: 16000, // ms
        port_speed: 16000,
        port_price_lvl_up: "114",
        port_work: false, // control auto work
        port_manager_lvl: 0,
        port_manager_price: {
            lvl_1: 1,
            lvl_2: 2,
            lvl_3: 3,
            lvl_4: 4,
            lvl_5: 5,
        },
        port_manager_value: {
            lvl_1: 1.25,
            lvl_2: 1.75,
            lvl_3: 1.3,
            lvl_4: 1.8,
            lvl_5: 3.25,
        },
        port_bought: true,
        port_transfer_process: false, // whether transportation is in progress
        port_current_transport: "0", // how much the ship is currently carrying
        port_prod_ready: false, // control hand picking
        port_path_point: "", // "port point start" - port warehouse point, "port point end" - fabric warehouse point

        lab_value: "240",
        lab_base_value: "240",
        lab_init_base_value: "240",
        lab_lvl: 1,
        lab_init_speed: 20000,
        lab_speed: 20000,
        lab_price_lvl_up: "114",
        lab_work: false,
        lab_manager_lvl: 0,
        lab_manager_price: {
            lvl_1: 11,
            lvl_2: 21,
            lvl_3: 31,
            lvl_4: 41,
            lvl_5: 51,
        },
        lab_manager_value: {
            lvl_1: 1.25,
            lvl_2: 1.75,
            lvl_3: 1.65,
            lvl_4: 2.65,
            lvl_5: 3.5,
        },
        lab_bought: true,
        lab_process: false, // lab process (hand)
        lab_auto_process: false, // lab process (auto)
        lab_prod_ready: false,

        port_c_lvl_up: 1.1, // coefficient for multiple level up price for port and lab
        factory_c_lvl_up: 1.15, // coefficient for multiple level up price for all factories

        // grave digging factory
        factory_0_value: "12",
        factory_0_base_value: "12",
        factory_0_init_base_value: "12",
        factory_0_lvl: 1,
        factory_0_speed: 4000,
        factory_0_init_speed: 4000,
        factory_0_price_lvl_up: "6",
        factory_0_work: false,
        factory_0_manager_lvl: 0,
        factory_0_manager_price: {
            lvl_1: 1,
            lvl_2: 2,
            lvl_3: 3,
            lvl_4: 4,
            lvl_5: 5,
        },
        factory_0_manager_value: {
            lvl_1: 1.1,
            lvl_2: 1.6,
            lvl_3: 1.65,
            lvl_4: 2,
            lvl_5: 3.6,
        },
        factory_0_init_price: "6",
        factory_0_bought: false,
        factory_0_prod_ready: false,

        // mine factory
        factory_1_value: "2300",
        factory_1_base_value: "2300",
        factory_1_init_base_value: "2300",
        factory_1_lvl: 1,
        factory_1_speed: 15000,
        factory_1_init_speed: 15000,
        factory_1_price_lvl_up: "1350",
        factory_1_work: false,
        factory_1_manager_lvl: 0,
        factory_1_manager_price: {
            lvl_1: 10,
            lvl_2: 20,
            lvl_3: 30,
            lvl_4: 40,
            lvl_5: 50,
        },
        factory_1_manager_value: {
            lvl_1: 1.1,
            lvl_2: 1.5,
            lvl_3: 1.65,
            lvl_4: 2,
            lvl_5: 2.8,
        },
        factory_1_init_price: "1200",
        factory_1_bought: false,
        factory_1_prod_ready: false,
        factory_1_display: false, // display factory when warehouse_main >= factory_1_init_price

        // temple
        factory_2_value: "2200000",
        factory_2_base_value: "2200000",
        factory_2_init_base_value: "2200000",
        factory_2_lvl: 1,
        factory_2_speed: 30000,
        factory_2_init_speed: 30000,
        factory_2_price_lvl_up: "1470000",
        factory_2_work: false,
        factory_2_manager_lvl: 0,
        factory_2_manager_price: {
            lvl_1: 10,
            lvl_2: 20,
            lvl_3: 30,
            lvl_4: 40,
            lvl_5: 50,
        },
        factory_2_manager_value: {
            lvl_1: 1.1,
            lvl_2: 1.45,
            lvl_3: 1.65,
            lvl_4: 2,
            lvl_5: 2.45,
        },
        factory_2_init_price: "1270000",
        factory_2_bought: false,
        factory_2_prod_ready: false,
        factory_2_display: false,

        // canning factory
        factory_3_value: "2340000000",
        factory_3_base_value: "2340000000",
        factory_3_init_base_value: "2340000000",
        factory_3_lvl: 1,
        factory_3_speed: 60000,
        factory_3_init_speed: 60000,
        factory_3_price_lvl_up: "1670000000",
        factory_3_work: false,
        factory_3_manager_lvl: 0,
        factory_3_manager_price: {
            lvl_1: 10,
            lvl_2: 20,
            lvl_3: 30,
            lvl_4: 40,
            lvl_5: 50,
        },
        factory_3_manager_value: {
            lvl_1: 1.1,
            lvl_2: 1.4,
            lvl_3: 1.65,
            lvl_4: 2,
            lvl_5: 2,
        },
        factory_3_init_price: "1460000000",
        factory_3_bought: false,
        factory_3_prod_ready: false,
        factory_3_display: false,

        // bank
        factory_4_value: "159000000000",
        factory_4_base_value: "159000000000",
        factory_4_init_base_value: "159000000000",
        factory_4_lvl: 1,
        factory_4_speed: 120000,
        factory_4_init_speed: 120000,
        factory_4_price_lvl_up: "127000000000",
        factory_4_work: false,
        factory_4_manager_lvl: 0,
        factory_4_manager_price: {
            lvl_1: 10,
            lvl_2: 20,
            lvl_3: 30,
            lvl_4: 40,
            lvl_5: 50,
        },
        factory_4_manager_value: {
            lvl_1: 1.1,
            lvl_2: 1.35,
            lvl_3: 1.65,
            lvl_4: 2,
            lvl_5: 1.85,
        },
        factory_4_init_price: "111000000000",
        factory_4_bought: false,
        factory_4_prod_ready: false,
        factory_4_display: false,
    },
    reducers: {
        // new game
        setNewGameFactories(state, action) {
            state.gameRunning = action.payload.gameRunning;
            state.saveDate = action.payload.startDate;
            state.spent = action.payload.spent;

            state.warehouse_main = action.payload.warehouses.warehouseMain;
            state.warehouse_factories = action.payload.warehouses.warehouseFactories;
            state.warehouse_port = action.payload.warehouses.warehousePort;

            state.port_c_lvl_up = action.payload.coefficients.portCLvlUp;
            state.factory_c_lvl_up = action.payload.coefficients.factoryCLvlUp;

            state.port_value = action.payload.port.portValue;
            state.port_base_value = action.payload.port.portBaseValue;
            state.port_init_base_value = action.payload.port.portInitBaseValue;
            state.port_lvl = action.payload.port.portLvl;
            state.port_init_speed = action.payload.port.portInitSpeed;
            state.port_speed = action.payload.port.portSpeed;
            state.port_price_lvl_up = action.payload.port.portPriceLvlUp;
            state.port_work = action.payload.port.portWork;
            state.port_manager_lvl = action.payload.port.portManagerLvl;
            state.port_manager_price = action.payload.port.portManagerPrice;
            state.port_manager_value = action.payload.port.portManagerValue;
            state.port_bought = action.payload.port.portBought;
            state.port_transfer_process = action.payload.port.portTransferProcess;
            state.port_current_transport = action.payload.port.portCurrentTransport;
            state.port_prod_ready = action.payload.port.portProdReady;
            state.port_path_point = action.payload.port.portPathPoint;

            state.lab_value = action.payload.lab.labValue;
            state.lab_base_value = action.payload.lab.labBaseValue;
            state.lab_init_base_value = action.payload.lab.labInitBaseValue;
            state.lab_lvl = action.payload.lab.labLvl;
            state.lab_init_speed = action.payload.lab.labInitSpeed;
            state.lab_speed = action.payload.lab.labSpeed;
            state.lab_price_lvl_up = action.payload.lab.labPriceLvlUp;
            state.lab_work = action.payload.lab.labWork;
            state.lab_manager_lvl = action.payload.lab.labManagerLvl;
            state.lab_manager_price = action.payload.lab.labManagerPrice;
            state.lab_manager_value = action.payload.lab.labManagerValue;
            state.lab_bought = action.payload.lab.labBought;
            state.lab_process = action.payload.lab.labProcess;
            state.lab_auto_process = action.payload.lab.labAutoProcess;
            state.lab_prod_ready = action.payload.lab.labProdReady;

            state.factory_0_value = action.payload.factory0.factory0Value;
            state.factory_0_base_value = action.payload.factory0.factory0BaseValue;
            state.factory_0_init_base_value = action.payload.factory0.factory0InitBaseValue;
            state.factory_0_lvl = action.payload.factory0.factory0Lvl;
            state.factory_0_init_speed = action.payload.factory0.factory0InitSpeed;
            state.factory_0_speed = action.payload.factory0.factory0Speed;
            state.factory_0_price_lvl_up = action.payload.factory0.factory0PriceLvlUp;
            state.factory_0_work = action.payload.factory0.factory0Work;
            state.factory_0_manager_lvl = action.payload.factory0.factory0ManagerLvl;
            state.factory_0_manager_price = action.payload.factory0.factory0ManagerPrice;
            state.factory_0_manager_value = action.payload.factory0.factory0ManagerValue;
            state.factory_0_init_price = action.payload.factory0.factory0InitPrice;
            state.factory_0_bought = action.payload.factory0.factory0Bought;
            state.factory_0_prod_ready = action.payload.factory0.factory0ProdReady;

            state.factory_1_value = action.payload.factory1.factory1Value;
            state.factory_1_base_value = action.payload.factory1.factory1BaseValue;
            state.factory_1_init_base_value = action.payload.factory1.factory1InitBaseValue;
            state.factory_1_lvl = action.payload.factory1.factory1Lvl;
            state.factory_1_init_speed = action.payload.factory1.factory1InitSpeed;
            state.factory_1_speed = action.payload.factory1.factory1Speed;
            state.factory_1_price_lvl_up = action.payload.factory1.factory1PriceLvlUp;
            state.factory_1_work = action.payload.factory1.factory1Work;
            state.factory_1_manager_lvl = action.payload.factory1.factory1ManagerLvl;
            state.factory_1_manager_price = action.payload.factory1.factory1ManagerPrice;
            state.factory_1_manager_value = action.payload.factory1.factory1ManagerValue;
            state.factory_1_init_price = action.payload.factory1.factory1InitPrice;
            state.factory_1_bought = action.payload.factory1.factory1Bought;
            state.factory_1_prod_ready = action.payload.factory1.factory1ProdReady;
            state.factory_1_display = action.payload.factory1.factory1Display;

            state.factory_2_value = action.payload.factory2.factory2Value;
            state.factory_2_base_value = action.payload.factory2.factory2BaseValue;
            state.factory_2_init_base_value = action.payload.factory2.factory2InitBaseValue;
            state.factory_2_lvl = action.payload.factory2.factory2Lvl;
            state.factory_2_init_speed = action.payload.factory2.factory2InitSpeed;
            state.factory_2_speed = action.payload.factory2.factory2Speed;
            state.factory_2_price_lvl_up = action.payload.factory2.factory2PriceLvlUp;
            state.factory_2_work = action.payload.factory2.factory2Work;
            state.factory_2_manager_lvl = action.payload.factory2.factory2ManagerLvl;
            state.factory_2_manager_price = action.payload.factory2.factory2ManagerPrice;
            state.factory_2_manager_value = action.payload.factory2.factory2ManagerValue;
            state.factory_2_init_price = action.payload.factory2.factory2InitPrice;
            state.factory_2_bought = action.payload.factory2.factory2Bought;
            state.factory_2_prod_ready = action.payload.factory2.factory2ProdReady;
            state.factory_2_display = action.payload.factory2.factory2Display;

            state.factory_3_value = action.payload.factory3.factory3Value;
            state.factory_3_base_value = action.payload.factory3.factory3BaseValue;
            state.factory_3_init_base_value = action.payload.factory3.factory3InitBaseValue;
            state.factory_3_lvl = action.payload.factory3.factory3Lvl;
            state.factory_3_init_speed = action.payload.factory3.factory3InitSpeed;
            state.factory_3_speed = action.payload.factory3.factory3Speed;
            state.factory_3_price_lvl_up = action.payload.factory3.factory3PriceLvlUp;
            state.factory_3_work = action.payload.factory3.factory3Work;
            state.factory_3_manager_lvl = action.payload.factory3.factory3ManagerLvl;
            state.factory_3_manager_price = action.payload.factory3.factory3ManagerPrice;
            state.factory_3_manager_value = action.payload.factory3.factory3ManagerValue;
            state.factory_3_init_price = action.payload.factory3.factory3InitPrice;
            state.factory_3_bought = action.payload.factory3.factory3Bought;
            state.factory_3_prod_ready = action.payload.factory3.factory3ProdReady;
            state.factory_3_display = action.payload.factory3.factory3Display;

            state.factory_4_value = action.payload.factory4.factory4Value;
            state.factory_4_base_value = action.payload.factory4.factory4BaseValue;
            state.factory_4_init_base_value = action.payload.factory4.factory4InitBaseValue;
            state.factory_4_lvl = action.payload.factory4.factory4Lvl;
            state.factory_4_init_speed = action.payload.factory4.factory4InitSpeed;
            state.factory_4_speed = action.payload.factory4.factory4Speed;
            state.factory_4_price_lvl_up = action.payload.factory4.factory4PriceLvlUp;
            state.factory_4_work = action.payload.factory4.factory4Work;
            state.factory_4_manager_lvl = action.payload.factory4.factory4ManagerLvl;
            state.factory_4_manager_price = action.payload.factory4.factory4ManagerPrice;
            state.factory_4_manager_value = action.payload.factory4.factory4ManagerValue;
            state.factory_4_init_price = action.payload.factory4.factory4InitPrice;
            state.factory_4_bought = action.payload.factory4.factory4Bought;
            state.factory_4_prod_ready = action.payload.factory4.factory4ProdReady;
            state.factory_4_display = action.payload.factory4.factory4Display;
        },
        setSaveDate(state, action) {
            state.saveDate = action.payload;
        },
        setSaveLoaded(state, action) {
            state.saveLoaded = true;
        },
        setSpent(state, action) {
            state.spent = action.payload;
        },
        setGameRunning(state, action) {
            state.gameRunning = action.payload;
        },
        // warehouses
        setDecreaseWarehouseMain(state, action) {
            state.warehouse_main = (BigInt(state.warehouse_main) - BigInt(action.payload)).toString();
        },
        setWarehouseMain(state, action) {
            state.warehouse_main = (BigInt(state.warehouse_main) + BigInt(action.payload)).toString();
        },
        setWarehouseFactories(state, action) {
            state.warehouse_factories = (BigInt(state.warehouse_factories) + BigInt(action.payload)).toString();
        },
        setTransportWarehouseFactories(state, action) {
            state.warehouse_factories = action.payload;
        },
        setDecreaseWarehousePort(state, action) {
            state.warehouse_port = (BigInt(state.warehouse_port) - BigInt(action.payload)).toString();
        },
        setWarehousePort(state, action) {
            state.warehouse_port = (BigInt(state.warehouse_port) + BigInt(action.payload)).toString();
        },
        setInitialWarehouseFactories(state, action) {
            state.warehouse_factories = action.payload;
        },

        // port
        setPort_value(state, action) {
            state.port_value = action.payload;
        },
        setPort_base_value(state, action) {
            state.port_base_value = action.payload;
        },
        setPort_lvl(state, action) {
            state.port_lvl = state.port_lvl + action.payload;
        },
        setPort_speed(state, action) {
            state.port_speed = action.payload;
        },
        setPort_price_lvl_up(state, action) {
            state.port_price_lvl_up = action.payload;
        },
        setPort_work(state, action) {
            state.port_work = action.payload;
        },
        setPort_manager_lvl_up(state, action) {
            state.port_manager_lvl = action.payload;
        },
        setPort_manager_price(state, action) {
            state.port_manager_price = action.payload;
        },
        setPort_manager_value(state, action) {
            state.port_manager_value = action.payload;
        },
        setPort_transfer_process(state, action) {
            state.port_transfer_process = action.payload;
        },
        setPort_current_transport(state, action) {
            state.port_current_transport = action.payload;
        },
        setPort_prod_ready(state, action) {
            state.port_prod_ready = action.payload;
        },
        setPort_path_point(state, action) {
            state.port_path_point = action.payload;
        },

        // lab
        setLab_value(state, action) {
            state.lab_value = action.payload;
        },
        setLab_base_value(state, action) {
            state.lab_base_value = action.payload;
        },
        setLab_lvl(state, action) {
            state.lab_lvl = state.lab_lvl + action.payload;
        },
        setLab_speed(state, action) {
            state.lab_speed = action.payload;
        },
        setLab_price_lvl_up(state, action) {
            state.lab_price_lvl_up = action.payload;
        },
        setLab_work(state, action) {
            state.lab_work = action.payload;
        },
        setLab_manager_lvl_up(state, action) {
            state.lab_manager_lvl = action.payload;
        },
        setLab_manager_price(state, action) {
            state.lab_manager_price = action.payload;
        },
        setLab_manager_value(state, action) {
            state.lab_manager_value = action.payload;
        },
        setLab_prod_ready(state, action) {
            state.lab_prod_ready = action.payload;
        },
        setLab_process(state, action) {
            state.lab_process = action.payload;
        },
        setLab_auto_process(state) {
            state.lab_auto_process = !state.lab_auto_process;
        },

        // grave digging factory
        setFactory_0_value(state, action) {
            state.factory_0_value = action.payload;
        },
        setFactory_0_base_value(state, action) {
            state.factory_0_base_value = action.payload;
        },
        setFactory_0_lvl(state, action) {
            state.factory_0_lvl = state.factory_0_lvl + action.payload;
        },
        setFactory_0_speed(state, action) {
            state.factory_0_speed = action.payload;
        },
        setFactory_0_price_lvl_up(state, action) {
            state.factory_0_price_lvl_up = action.payload;
        },
        setFactory_0_work(state, action) {
            state.factory_0_work = action.payload;
        },
        setFactory_0_manager_lvl_up(state, action) {
            state.factory_0_manager_lvl = action.payload;
        },
        setFactory_0_manager_price(state, action) {
            state.factory_0_manager_price = action.payload;
        },
        setFactory_0_manager_value(state, action) {
            state.factory_0_manager_value = action.payload;
        },
        setFactory_0_bought(state, action) {
            state.factory_0_bought = action.payload;
        },
        setFactory_0_prod_ready(state, action) {
            state.factory_0_prod_ready = action.payload;
        },

        // mine factory
        setFactory_1_value(state, action) {
            state.factory_1_value = action.payload;
        },
        setFactory_1_base_value(state, action) {
            state.factory_1_base_value = action.payload;
        },
        setFactory_1_lvl(state, action) {
            state.factory_1_lvl = state.factory_1_lvl + action.payload;
        },
        setFactory_1_speed(state, action) {
            state.factory_1_speed = action.payload;
        },
        setFactory_1_price_lvl_up(state, action) {
            state.factory_1_price_lvl_up = action.payload;
        },
        setFactory_1_work(state, action) {
            state.factory_1_work = action.payload;
        },
        setFactory_1_manager_lvl_up(state, action) {
            state.factory_1_manager_lvl = action.payload;
        },
        setFactory_1_manager_price(state, action) {
            state.factory_1_manager_price = action.payload;
        },
        setFactory_1_manager_value(state, action) {
            state.factory_1_manager_value = action.payload;
        },
        setFactory_1_bought(state, action) {
            state.factory_1_bought = action.payload;
        },
        setFactory_1_prod_ready(state, action) {
            state.factory_1_prod_ready = action.payload;
        },
        setFactory_1_display(state, action) {
            state.factory_1_display = action.payload;
        },

        // temple
        setFactory_2_value(state, action) {
            state.factory_2_value = action.payload;
        },
        setFactory_2_base_value(state, action) {
            state.factory_2_base_value = action.payload;
        },
        setFactory_2_lvl(state, action) {
            state.factory_2_lvl = state.factory_2_lvl + action.payload;
        },
        setFactory_2_speed(state, action) {
            state.factory_2_speed = action.payload;
        },
        setFactory_2_price_lvl_up(state, action) {
            state.factory_2_price_lvl_up = action.payload;
        },
        setFactory_2_work(state, action) {
            state.factory_2_work = action.payload;
        },
        setFactory_2_manager_lvl_up(state, action) {
            state.factory_2_manager_lvl = action.payload;
        },
        setFactory_2_manager_price(state, action) {
            state.factory_2_manager_price = action.payload;
        },
        setFactory_2_manager_value(state, action) {
            state.factory_2_manager_value = action.payload;
        },
        setFactory_2_bought(state, action) {
            state.factory_2_bought = action.payload;
        },
        setFactory_2_prod_ready(state, action) {
            state.factory_2_prod_ready = action.payload;
        },
        setFactory_2_display(state, action) {
            state.factory_2_display = action.payload;
        },

        // canning factory
        setFactory_3_value(state, action) {
            state.factory_3_value = action.payload;
        },
        setFactory_3_base_value(state, action) {
            state.factory_3_base_value = action.payload;
        },
        setFactory_3_lvl(state, action) {
            state.factory_3_lvl = state.factory_3_lvl + action.payload;
        },
        setFactory_3_speed(state, action) {
            state.factory_3_speed = action.payload;
        },
        setFactory_3_price_lvl_up(state, action) {
            state.factory_3_price_lvl_up = action.payload;
        },
        setFactory_3_work(state, action) {
            state.factory_3_work = action.payload;
        },
        setFactory_3_manager_lvl_up(state, action) {
            state.factory_3_manager_lvl = action.payload;
        },
        setFactory_3_manager_price(state, action) {
            state.factory_3_manager_price = action.payload;
        },
        setFactory_3_manager_value(state, action) {
            state.factory_3_manager_value = action.payload;
        },
        setFactory_3_bought(state, action) {
            state.factory_3_bought = action.payload;
        },
        setFactory_3_prod_ready(state, action) {
            state.factory_3_prod_ready = action.payload;
        },
        setFactory_3_display(state, action) {
            state.factory_3_display = action.payload;
        },

        // bank
        setFactory_4_value(state, action) {
            state.factory_4_value = action.payload;
        },
        setFactory_4_base_value(state, action) {
            state.factory_4_base_value = action.payload;
        },
        setFactory_4_lvl(state, action) {
            state.factory_4_lvl = state.factory_4_lvl + action.payload;
        },
        setFactory_4_speed(state, action) {
            state.factory_4_speed = action.payload;
        },
        setFactory_4_price_lvl_up(state, action) {
            state.factory_4_price_lvl_up = action.payload;
        },
        setFactory_4_work(state, action) {
            state.factory_4_work = action.payload;
        },
        setFactory_4_manager_lvl_up(state, action) {
            state.factory_4_manager_lvl = action.payload;
        },
        setFactory_4_manager_price(state, action) {
            state.factory_4_manager_price = action.payload;
        },
        setFactory_4_manager_value(state, action) {
            state.factory_4_manager_value = action.payload;
        },
        setFactory_4_bought(state, action) {
            state.factory_4_bought = action.payload;
        },
        setFactory_4_prod_ready(state, action) {
            state.factory_4_prod_ready = action.payload;
        },
        setFactory_4_display(state, action) {
            state.factory_4_display = action.payload;
        },
    },
});

export const {
    setNewGameFactories,
    setSaveDate,
    setSaveLoaded,
    setSpent,
    setGameRunning,
    // warehouse
    setDecreaseWarehouseMain,
    setWarehouseMain,
    setWarehouseFactories,
    setTransportWarehouseFactories,
    setDecreaseWarehousePort,
    setWarehousePort,
    setInitialWarehouseFactories,
    // port
    setPort_c_value,
    setPort_value,
    setPort_base_value,
    setPort_lvl,
    setPort_speed,
    setPort_price_lvl_up,
    setPort_work,
    setPort_manager_lvl_up,
    setPort_manager_price,
    setPort_manager_value,
    setPort_transfer_process,
    setPort_current_transport,
    setPort_prod_ready,
    setPort_path_point,
    // lab
    setLab_value,
    setLab_base_value,
    setLab_lvl,
    setLab_speed,
    setLab_price_lvl_up,
    setLab_work,
    setLab_manager_lvl_up,
    setLab_manager_price,
    setLab_manager_value,
    setLab_prod_ready,
    setLab_process,
    setLab_auto_process,
    // grave digging factory
    setFactory_0_value,
    setFactory_0_base_value,
    setFactory_0_lvl,
    setFactory_0_speed,
    setFactory_0_price_lvl_up,
    setFactory_0_work,
    setFactory_0_manager_lvl_up,
    setFactory_0_manager_price,
    setFactory_0_manager_value,
    setFactory_0_bought,
    setFactory_0_prod_ready,
    // mine factory
    setFactory_1_value,
    setFactory_1_base_value,
    setFactory_1_lvl,
    setFactory_1_speed,
    setFactory_1_price_lvl_up,
    setFactory_1_work,
    setFactory_1_manager_lvl_up,
    setFactory_1_manager_price,
    setFactory_1_manager_value,
    setFactory_1_bought,
    setFactory_1_prod_ready,
    setFactory_1_display,
    // temple
    setFactory_2_value,
    setFactory_2_base_value,
    setFactory_2_lvl,
    setFactory_2_speed,
    setFactory_2_price_lvl_up,
    setFactory_2_work,
    setFactory_2_manager_lvl_up,
    setFactory_2_manager_price,
    setFactory_2_manager_value,
    setFactory_2_bought,
    setFactory_2_prod_ready,
    setFactory_2_display,
    // canning factory
    setFactory_3_value,
    setFactory_3_base_value,
    setFactory_3_lvl,
    setFactory_3_speed,
    setFactory_3_price_lvl_up,
    setFactory_3_work,
    setFactory_3_manager_lvl_up,
    setFactory_3_manager_price,
    setFactory_3_manager_value,
    setFactory_3_bought,
    setFactory_3_prod_ready,
    setFactory_3_display,
    // bank
    setFactory_4_value,
    setFactory_4_base_value,
    setFactory_4_lvl,
    setFactory_4_speed,
    setFactory_4_price_lvl_up,
    setFactory_4_work,
    setFactory_4_manager_lvl_up,
    setFactory_4_manager_price,
    setFactory_4_manager_value,
    setFactory_4_bought,
    setFactory_4_prod_ready,
    setFactory_4_display,
} = brainFarmSlice.actions;

export default brainFarmSlice.reducer;
