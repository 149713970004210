import { useSelector } from "react-redux";

const useSaveData = () => {
    const user = useSelector((state) => state.user.user);

    const warehouse_main = useSelector((state) => state.brainFarm.warehouse_main);
    const warehouse_factories = useSelector((state) => state.brainFarm.warehouse_factories);
    const warehouse_port = useSelector((state) => state.brainFarm.warehouse_port);
    const spent = useSelector((state) => state.brainFarm.spent);

    const bronzeRewardShare = useSelector((state) => state.brainFarmRewardsTasks.bronzeRewardShare);
    const silverRewardShare = useSelector((state) => state.brainFarmRewardsTasks.silverRewardShare);
    const goldRewardShare = useSelector((state) => state.brainFarmRewardsTasks.goldRewardShare);

    const port_value = useSelector((state) => state.brainFarm.port_value);
    const port_base_value = useSelector((state) => state.brainFarm.port_base_value);
    const port_init_base_value = useSelector((state) => state.brainFarm.port_init_base_value);
    const port_lvl = useSelector((state) => state.brainFarm.port_lvl);
    const port_speed = useSelector((state) => state.brainFarm.port_speed);
    const port_init_speed = useSelector((state) => state.brainFarm.port_init_speed);
    const port_price_lvl_up = useSelector((state) => state.brainFarm.port_price_lvl_up);
    const port_manager_lvl = useSelector((state) => state.brainFarm.port_manager_lvl);

    const lab_value = useSelector((state) => state.brainFarm.lab_value);
    const lab_base_value = useSelector((state) => state.brainFarm.lab_base_value);
    const lab_init_base_value = useSelector((state) => state.brainFarm.lab_init_base_value);
    const lab_lvl = useSelector((state) => state.brainFarm.lab_lvl);
    const lab_speed = useSelector((state) => state.brainFarm.lab_speed);
    const lab_init_speed = useSelector((state) => state.brainFarm.lab_init_speed);
    const lab_price_lvl_up = useSelector((state) => state.brainFarm.lab_price_lvl_up);
    const lab_manager_lvl = useSelector((state) => state.brainFarm.lab_manager_lvl);
    const lab_process = useSelector((state) => state.brainFarm.lab_process);

    const factory_0_value = useSelector((state) => state.brainFarm.factory_0_value);
    const factory_0_base_value = useSelector((state) => state.brainFarm.factory_0_base_value);
    const factory_0_init_base_value = useSelector((state) => state.brainFarm.factory_0_init_base_value);
    const factory_0_lvl = useSelector((state) => state.brainFarm.factory_0_lvl);
    const factory_0_speed = useSelector((state) => state.brainFarm.factory_0_speed);
    const factory_0_init_speed = useSelector((state) => state.brainFarm.factory_0_init_speed);
    const factory_0_price_lvl_up = useSelector((state) => state.brainFarm.factory_0_price_lvl_up);
    const factory_0_manager_lvl = useSelector((state) => state.brainFarm.factory_0_manager_lvl);
    const factory_0_bought = useSelector((state) => state.brainFarm.factory_0_bought);

    const factory_1_value = useSelector((state) => state.brainFarm.factory_1_value);
    const factory_1_base_value = useSelector((state) => state.brainFarm.factory_1_base_value);
    const factory_1_init_base_value = useSelector((state) => state.brainFarm.factory_1_init_base_value);
    const factory_1_lvl = useSelector((state) => state.brainFarm.factory_1_lvl);
    const factory_1_speed = useSelector((state) => state.brainFarm.factory_1_speed);
    const factory_1_init_speed = useSelector((state) => state.brainFarm.factory_1_init_speed);
    const factory_1_price_lvl_up = useSelector((state) => state.brainFarm.factory_1_price_lvl_up);
    const factory_1_manager_lvl = useSelector((state) => state.brainFarm.factory_1_manager_lvl);
    const factory_1_bought = useSelector((state) => state.brainFarm.factory_1_bought);
    const factory_1_display = useSelector((state) => state.brainFarm.factory_1_display);

    const factory_2_value = useSelector((state) => state.brainFarm.factory_2_value);
    const factory_2_base_value = useSelector((state) => state.brainFarm.factory_2_base_value);
    const factory_2_init_base_value = useSelector((state) => state.brainFarm.factory_2_init_base_value);
    const factory_2_lvl = useSelector((state) => state.brainFarm.factory_2_lvl);
    const factory_2_speed = useSelector((state) => state.brainFarm.factory_2_speed);
    const factory_2_init_speed = useSelector((state) => state.brainFarm.factory_2_init_speed);
    const factory_2_price_lvl_up = useSelector((state) => state.brainFarm.factory_2_price_lvl_up);
    const factory_2_manager_lvl = useSelector((state) => state.brainFarm.factory_2_manager_lvl);
    const factory_2_bought = useSelector((state) => state.brainFarm.factory_2_bought);
    const factory_2_display = useSelector((state) => state.brainFarm.factory_2_display);

    const factory_3_value = useSelector((state) => state.brainFarm.factory_3_value);
    const factory_3_base_value = useSelector((state) => state.brainFarm.factory_3_base_value);
    const factory_3_init_base_value = useSelector((state) => state.brainFarm.factory_3_init_base_value);
    const factory_3_lvl = useSelector((state) => state.brainFarm.factory_3_lvl);
    const factory_3_speed = useSelector((state) => state.brainFarm.factory_3_speed);
    const factory_3_init_speed = useSelector((state) => state.brainFarm.factory_3_init_speed);
    const factory_3_price_lvl_up = useSelector((state) => state.brainFarm.factory_3_price_lvl_up);
    const factory_3_manager_lvl = useSelector((state) => state.brainFarm.factory_3_manager_lvl);
    const factory_3_bought = useSelector((state) => state.brainFarm.factory_3_bought);
    const factory_3_display = useSelector((state) => state.brainFarm.factory_3_display);

    const factory_4_value = useSelector((state) => state.brainFarm.factory_4_value);
    const factory_4_base_value = useSelector((state) => state.brainFarm.factory_4_base_value);
    const factory_4_init_base_value = useSelector((state) => state.brainFarm.factory_4_init_base_value);
    const factory_4_lvl = useSelector((state) => state.brainFarm.factory_4_lvl);
    const factory_4_speed = useSelector((state) => state.brainFarm.factory_4_speed);
    const factory_4_init_speed = useSelector((state) => state.brainFarm.factory_4_init_speed);
    const factory_4_price_lvl_up = useSelector((state) => state.brainFarm.factory_4_price_lvl_up);
    const factory_4_manager_lvl = useSelector((state) => state.brainFarm.factory_4_manager_lvl);
    const factory_4_bought = useSelector((state) => state.brainFarm.factory_4_bought);
    const factory_4_display = useSelector((state) => state.brainFarm.factory_4_display);

    const tasksFactory = useSelector((state) => state.brainFarmRewardsTasks.tasksFactory);
    const tasksManager = useSelector((state) => state.brainFarmRewardsTasks.tasksManager);
    const tasksTurnover = useSelector((state) => state.brainFarmRewardsTasks.tasksTurnover);

    const saveData = {
        balance: user.balance,
        email: user.userEmail,
        brainFarmData: {
            warehouses: {
                warehouseMain: warehouse_main,
                warehouseFactories: warehouse_factories,
                warehousePort: warehouse_port,
            },
            rewards: {
                bronzeRewardShare: bronzeRewardShare,
                silverRewardShare: silverRewardShare,
                goldRewardShare: goldRewardShare,
            },
            port: {
                portValue: port_value,
                portBaseValue: port_base_value,
                portInitBaseValue: port_init_base_value,
                portLvl: port_lvl,
                portSpeed: port_speed,
                portInitSpeed: port_init_speed,
                portPriceLvlUp: port_price_lvl_up,
                portManagerLvl: port_manager_lvl,
            },
            lab: {
                labValue: lab_value,
                labBaseValue: lab_base_value,
                labInitBaseValue: lab_init_base_value,
                labLvl: lab_lvl,
                labSpeed: lab_speed,
                labInitSpeed: lab_init_speed,
                labPriceLvlUp: lab_price_lvl_up,
                labManagerLvl: lab_manager_lvl,
                labProcess: lab_process,
            },
            factory0: {
                factory0Value: factory_0_value,
                factory0BaseValue: factory_0_base_value,
                factory0InitBaseValue: factory_0_init_base_value,
                factory0Lvl: factory_0_lvl,
                factory0Speed: factory_0_speed,
                factory0InitSpeed: factory_0_init_speed,
                factory0PriceLvlUp: factory_0_price_lvl_up,
                factory0ManagerLvl: factory_0_manager_lvl,
                factory0Bought: factory_0_bought,
            },
            factory1: {
                factory1Value: factory_1_value,
                factory1BaseValue: factory_1_base_value,
                factory1InitBaseValue: factory_1_init_base_value,
                factory1Lvl: factory_1_lvl,
                factory1Speed: factory_1_speed,
                factory1InitSpeed: factory_1_init_speed,
                factory1PriceLvlUp: factory_1_price_lvl_up,
                factory1ManagerLvl: factory_1_manager_lvl,
                factory1Bought: factory_1_bought,
                factory1Display: factory_1_display,
            },
            factory2: {
                factory2Value: factory_2_value,
                factory2BaseValue: factory_2_base_value,
                factory2InitBaseValue: factory_2_init_base_value,
                factory2Lvl: factory_2_lvl,
                factory2Speed: factory_2_speed,
                factory2InitSpeed: factory_2_init_speed,
                factory2PriceLvlUp: factory_2_price_lvl_up,
                factory2ManagerLvl: factory_2_manager_lvl,
                factory2Bought: factory_2_bought,
                factory2Display: factory_2_display,
            },
            factory3: {
                factory3Value: factory_3_value,
                factory3BaseValue: factory_3_base_value,
                factory3InitBaseValue: factory_3_init_base_value,
                factory3Lvl: factory_3_lvl,
                factory3Speed: factory_3_speed,
                factory3InitSpeed: factory_3_init_speed,
                factory3PriceLvlUp: factory_3_price_lvl_up,
                factory3ManagerLvl: factory_3_manager_lvl,
                factory3Bought: factory_3_bought,
                factory3Display: factory_3_display,
            },
            factory4: {
                factory4Value: factory_4_value,
                factory4BaseValue: factory_4_base_value,
                factory4InitBaseValue: factory_4_init_base_value,
                factory4Lvl: factory_4_lvl,
                factory4Speed: factory_4_speed,
                factory4InitSpeed: factory_4_init_speed,
                factory4PriceLvlUp: factory_4_price_lvl_up,
                factory4ManagerLvl: factory_4_manager_lvl,
                factory4Bought: factory_4_bought,
                factory4Display: factory_4_display,
            },
            tasksFactory: tasksFactory,
            tasksManager: tasksManager,
            tasksTurnover: tasksTurnover,
            spent: spent,
        },
    };

    return saveData;
};

export default useSaveData;
