import "./App.css";
import Header from "../header/Header";
import Footer from "../footer/Footer";
// header pages
import NotFoundPage from "../pages/NotFoundPage";
import MainPage from "../pages/MainPage";
import GamePage from "../pages/GamePage";
import BrainFarmPage from "../pages/BrainFarmPage";
import PaymentsPage from "../pages/PaymentsPage";
import LotteryPage from "../pages/LotteryPage";
import FaucetPage from "../pages/FaucetPage";
import ReferralProgramPage from "../pages/ReferralProgramPage";
import DocsPage from "../pages/DocsPage";
// docs pages
import Rules from "../docs/pages/Rules";
import Instruction from "../docs/pages/Instruction";
import InstructionPve from "../docs/pages/InstructionPve";
import InstructionBrainFarm from "../docs/pages/InstructionBrainFarm";
import Faq from "../docs/pages/Faq";
import LotteryRules from "../docs/pages/LotteryRules";
import LotteryHistory from "../docs/pages/LotteryHistory";
import FaucetRules from "../docs/pages/FaucetRules";
import ReferralRules from "../docs/pages/ReferralRules";
// dashboard page
import DashboardStatsPage from "../pages/DashboardStatsPage";

// imports for connect wallet
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const getLibrary = (provider) => {
    const library = new ethers.providers.Web3Provider(provider);
    library.pollingInterval = 8000; // frequency provider is polling
    return library;
};

const Content = () => {
    return (
        <Router>
            <div className="App">
                <Header />

                <div className="content">
                    <Routes>
                        {/* main pages in header */}
                        <Route path="*" element={<NotFoundPage />} />
                        <Route path="/" element={<MainPage />} />
                        <Route path="/game" element={<GamePage />} />
                        <Route path="/brainFarm" element={<BrainFarmPage />} />
                        <Route path="/payments" element={<PaymentsPage />} />
                        <Route path="/lottery" element={<LotteryPage />} />
                        <Route path="/faucet" element={<FaucetPage />} />
                        <Route path="/referral" element={<ReferralProgramPage />} />
                        <Route path="/docs" element={<DocsPage />} />

                        {/* docs pages */}
                        <Route path="/docs/rules" element={<Rules />} />
                        <Route path="/docs/instruction" element={<Instruction />} />
                        <Route path="/docs/instructionPve" element={<InstructionPve />} />
                        <Route path="/docs/instructionBrainFarm" element={<InstructionBrainFarm />} />
                        <Route path="/docs/faq" element={<Faq />} />
                        <Route path="/docs/lotteryRules" element={<LotteryRules />} />
                        <Route path="/docs/lotteryHistory" element={<LotteryHistory />} />
                        <Route path="/docs/faucet" element={<FaucetRules />} />
                        <Route path="/docs/referralProgram" element={<ReferralRules />} />

                        {/* statistic dashboard page */}
                        <Route path="/mainInfoDashboard" element={<DashboardStatsPage />} />
                    </Routes>
                </div>

                <Footer />
            </div>
        </Router>
    );
};

function App() {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <Content />
        </Web3ReactProvider>
    );
}

export default App;
