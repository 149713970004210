// function for format EVM address -> 0x1d2r...5gR96
export const formatAddress = (address) => {
    const match = address.match(
        /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/
    );
    if (!match) {
        return address;
    }
    return `${match[1]}…${match[2]}`;
};
