/* global BigInt */
import "./ShipPath.scss";
import { useSelector } from "react-redux";

import { abbreviationNumbers } from "../functions";

const ShipPath = () => {
    const portCurrentTransport = useSelector((state) => state.brainFarm.port_current_transport);
    const portValue = useSelector((state) => state.brainFarm.port_value);

    let shipIcon = "";
    let shipPercent = 0;

    if (portCurrentTransport !== "0") {
        shipPercent = 1 / Number(BigInt(portValue) / BigInt(portCurrentTransport));
    }

    if (shipPercent === 0) {
        shipIcon = "/img/farm/shipIcon0.png";
    } else if (shipPercent > 0 && shipPercent <= 0.2) {
        shipIcon = "/img/farm/shipIcon20.png";
    } else if (shipPercent > 0.2 && shipPercent <= 0.4) {
        shipIcon = "/img/farm/shipIcon40.png";
    } else if (shipPercent > 0.4 && shipPercent <= 0.6) {
        shipIcon = "/img/farm/shipIcon60.png";
    } else if (shipPercent > 0.6 && shipPercent <= 0.8) {
        shipIcon = "/img/farm/shipIcon80.png";
    } else if (shipPercent > 0.8) {
        shipIcon = "/img/farm/shipIcon100.png";
    }

    return (
        <div className="brainShipPathWrapper">
            <div className="brainShipPathDot">
                <img src={shipIcon} alt="ship icon" className="brainShipPathImg" />
                <div className="brainShipPathValue">{abbreviationNumbers(portCurrentTransport)}</div>
            </div>
        </div>
    );
};

export default ShipPath;
