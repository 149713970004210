import Docs from "../docs/Docs";

import Container from "@mui/material/Container";

const DocsPage = () => {
    return (
        <>
            <Container>
                <Docs />
            </Container>
        </>
    );
};

export default DocsPage;
