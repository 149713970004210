const commonIcon = (
    <svg
        className="pveRaceRarityIcon"
        width="26"
        height="26"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="22"
            cy="22"
            r="20.1667"
            stroke="#B2B7BE"
            strokeWidth="3.66667"
        ></circle>
        <circle cx="22" cy="22" r="5.5" fill="#B2B7BE"></circle>
    </svg>
);
const uncommonIcon = (
    <svg
        className="pveRaceRarityIcon"
        width="26"
        height="26"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="22"
            cy="22"
            r="20.1667"
            stroke="#81C259"
            strokeWidth="3.66667"
        ></circle>
        <path
            d="M19.5121 16.8134C20.3369 15.4937 20.7494 14.8338 21.2774 14.6075C21.7385 14.4099 22.2606 14.4099 22.7217 14.6075C23.2497 14.8338 23.6622 15.4937 24.487 16.8134L28.3612 23.0122C29.2767 24.477 29.7345 25.2093 29.6859 25.8137C29.6435 26.3406 29.3759 26.8235 28.9516 27.1386C28.4648 27.5002 27.6011 27.5002 25.8738 27.5002H18.1253C16.398 27.5002 15.5343 27.5002 15.0476 27.1386C14.6232 26.8235 14.3556 26.3406 14.3132 25.8137C14.2646 25.2093 14.7224 24.477 15.6379 23.0122L19.5121 16.8134Z"
            fill="#81C259"
        ></path>
    </svg>
);
const rareIcon = (
    <svg
        className="pveRaceRarityIcon"
        width="26"
        height="26"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="22"
            cy="22"
            r="20.1667"
            stroke="#47ACF3"
            strokeWidth="3.66667"
        ></circle>
        <path
            d="M18.8888 15.9448C19.9778 14.8557 20.5223 14.3112 21.1502 14.1072C21.7026 13.9277 22.2975 13.9277 22.8498 14.1072C23.4777 14.3112 24.0223 14.8557 25.1113 15.9448L28.0554 18.8889C29.1445 19.9779 29.689 20.5225 29.893 21.1504C30.0725 21.7027 30.0725 22.2976 29.893 22.85C29.689 23.4779 29.1445 24.0224 28.0554 25.1114L25.1113 28.0556C24.0223 29.1446 23.4777 29.6891 22.8498 29.8931C22.2975 30.0726 21.7026 30.0726 21.1502 29.8931C20.5223 29.6891 19.9778 29.1446 18.8888 28.0556L15.9446 25.1114C14.8556 24.0224 14.3111 23.4779 14.1071 22.85C13.9276 22.2976 13.9276 21.7027 14.1071 21.1504C14.3111 20.5225 14.8556 19.9779 15.9446 18.8889L18.8888 15.9448Z"
            fill="#47ACF3"
        ></path>
    </svg>
);
const epicIcon = (
    <svg
        className="pveRaceRarityIcon"
        width="26"
        height="26"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="22"
            cy="22"
            r="20.1667"
            stroke="#826DD4"
            strokeWidth="3.66667"
        ></circle>
        <path
            d="M19.3763 16.2473C20.2208 14.5584 20.643 13.714 21.2144 13.443C21.7116 13.2072 22.2884 13.2072 22.7856 13.443C23.357 13.714 23.7792 14.5584 24.6237 16.2473L25.2294 17.4588C25.3916 17.7833 25.4728 17.9455 25.5811 18.0861C25.6773 18.2109 25.7891 18.3227 25.9139 18.4189C26.0545 18.5272 26.2167 18.6084 26.5412 18.7706L27.7527 19.3763C29.4416 20.2208 30.286 20.643 30.557 21.2144C30.7928 21.7116 30.7928 22.2884 30.557 22.7856C30.286 23.357 29.4416 23.7792 27.7527 24.6237L26.5412 25.2294C26.2167 25.3916 26.0545 25.4728 25.9139 25.5811C25.7891 25.6773 25.6773 25.7891 25.5811 25.9139C25.4728 26.0545 25.3916 26.2167 25.2294 26.5412L24.6237 27.7527C23.7792 29.4416 23.357 30.286 22.7856 30.557C22.2884 30.7928 21.7116 30.7928 21.2144 30.557C20.643 30.286 20.2208 29.4416 19.3763 27.7527L18.7706 26.5412C18.6084 26.2167 18.5272 26.0545 18.4189 25.9139C18.3227 25.7891 18.2109 25.6773 18.0861 25.5811C17.9455 25.4728 17.7833 25.3916 17.4588 25.2294L16.2473 24.6237C14.5584 23.7792 13.714 23.357 13.443 22.7856C13.2072 22.2884 13.2072 21.7116 13.443 21.2144C13.714 20.643 14.5584 20.2208 16.2473 19.3763L17.4588 18.7706C17.7833 18.6084 17.9455 18.5272 18.0861 18.4189C18.2109 18.3227 18.3227 18.2109 18.4189 18.0861C18.5272 17.9455 18.6084 17.7833 18.7706 17.4588L19.3763 16.2473Z"
            fill="#826DD4"
        ></path>
    </svg>
);
const legendaryIcon = (
    <svg
        className="pveRaceRarityIcon"
        width="26"
        height="26"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="22"
            cy="22"
            r="20.1667"
            stroke="#FFB82F"
            strokeWidth="3.66667"
        ></circle>
        <path
            d="M20.8492 12.782C21.32 11.8282 22.6801 11.8282 23.1509 12.782L25.2037 16.9415C25.3906 17.3202 25.7519 17.5827 26.1699 17.6435L30.7602 18.3105C31.8128 18.4634 32.2331 19.757 31.4714 20.4995L28.1499 23.7371C27.8474 24.032 27.7094 24.4568 27.7808 24.8731L28.5649 29.4448C28.7447 30.4931 27.6443 31.2926 26.7029 30.7976L22.5972 28.6392C22.2234 28.4426 21.7767 28.4426 21.4029 28.6392L17.2972 30.7976C16.3557 31.2926 15.2554 30.4931 15.4352 29.4448L16.2193 24.8731C16.2907 24.4568 16.1527 24.032 15.8502 23.7371L12.5287 20.4995C11.767 19.757 12.1873 18.4634 13.2399 18.3105L17.8301 17.6435C18.2481 17.5827 18.6095 17.3202 18.7964 16.9415L20.8492 12.782Z"
            fill="#FFB82F"
        ></path>
    </svg>
);
const uniqueIcon = (
    <svg
        className="pveRaceRarityIcon"
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="22"
            cy="22"
            r="20.1667"
            stroke="#F1564C"
            strokeWidth="3.66667"
        ></circle>
        <path
            d="M23.8182 14.7274C23.8182 13.7232 23.0042 12.9092 22 12.9092C20.9959 12.9092 20.1818 13.7232 20.1818 14.7274V29.2728C20.1818 30.277 20.9959 31.091 22 31.091C23.0042 31.091 23.8182 30.277 23.8182 29.2728V14.7274Z"
            fill="#F1564C"
        ></path>
        <path
            d="M29.2075 19.9377C30.0771 19.4356 30.375 18.3236 29.873 17.454C29.3709 16.5844 28.2589 16.2864 27.3893 16.7885L14.7925 24.0612C13.9229 24.5633 13.625 25.6753 14.127 26.5449C14.6291 27.4145 15.7411 27.7125 16.6107 27.2104L29.2075 19.9377Z"
            fill="#F1564C"
        ></path>
        <path
            d="M27.3893 27.2108C28.2589 27.7129 29.3709 27.4149 29.873 26.5453C30.375 25.6757 30.0771 24.5637 29.2075 24.0616L16.6107 16.7889C15.7411 16.2868 14.6291 16.5848 14.127 17.4544C13.625 18.324 13.9229 19.436 14.7925 19.9381L27.3893 27.2108Z"
            fill="#F1564C"
        ></path>
    </svg>
);
const strengthIcon = (
    <svg
        className="pveRaceValueIcon"
        width="24"
        height="23"
        viewBox="0 0 24 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.5925 20.1356L6.41021 22.7042C4.91429 23.4396 3.88852 22.7042 4.12359 21.0522L4.85018 16.0643C4.94635 15.4035 4.63648 14.4656 4.17702 13.9753L0.661616 10.3729C-0.492376 9.17921 -0.097027 7.97486 1.5378 7.69775L6.47432 6.84511C7.1368 6.72787 7.93818 6.15234 8.25873 5.55549L10.5881 1.1111C11.3574 -0.370366 12.6289 -0.370366 13.409 1.1111L15.7383 5.55549C16.0482 6.15234 16.8496 6.73853 17.5227 6.84511L22.4592 7.69775C24.1048 7.97486 24.4894 9.18987 23.3354 10.3836L19.8307 13.986C19.3606 14.4656 19.0614 15.4141 19.1575 16.0749L19.8841 21.0629C20.1192 22.7149 19.0934 23.4503 17.5975 22.7149L12.4152 20.1463C12.1481 20.0078 11.8489 20.0078 11.5925 20.1356Z"
            fill="#FFFFFE"
        ></path>
    </svg>
);
const staminaIcon = (
    <svg
        className="pveRaceValueIcon"
        width="24"
        height="21"
        viewBox="0 0 24 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.1058 20.3636C11.9655 20.3636 11.7902 20.3286 11.65 20.2585C11.6149 20.2235 11.5799 20.2235 11.5448 20.1885L11.2643 19.9783C10.5281 19.4878 8.84524 18.2967 7.72335 17.4209C3.41106 14.0928 1.23739 11.6755 0.816679 9.85378C0.606324 9.25822 0.501146 8.52253 0.501146 7.78684C0.466087 5.78996 1.23739 3.89819 2.56964 2.53191C3.79671 1.30576 5.37438 0.605098 7.05722 0.605098C8.95042 0.605098 10.7034 1.37582 11.9305 2.7421C13.1575 1.34079 14.8754 0.5 16.7686 0.5H16.9089C20.4849 0.5 23.4649 3.65296 23.5 7.50657C23.5 8.24226 23.3948 8.97795 23.2195 9.67861C22.8689 11.4303 20.6953 13.9877 16.5232 17.3508C14.9105 18.6821 13.6133 19.663 12.6667 20.2585C12.4563 20.3286 12.281 20.3636 12.1058 20.3636Z"
            fill="white"
        ></path>
    </svg>
);
const speedIcon = (
    <svg
        className="pveRaceValueIcon"
        width="24"
        height="20"
        viewBox="0 0 24 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 2C8 0.895431 8.89543 0 10 0H22C23.1046 0 24 0.895431 24 2C24 3.10457 23.1046 4 22 4H10C8.89543 4 8 3.10457 8 2ZM4 10C4 8.89543 4.89543 8 6 8H18C19.1046 8 20 8.89543 20 10C20 11.1046 19.1046 12 18 12H6C4.89543 12 4 11.1046 4 10ZM2 16C0.895431 16 0 16.8954 0 18C0 19.1046 0.895431 20 2 20H14C15.1046 20 16 19.1046 16 18C16 16.8954 15.1046 16 14 16H2Z"
            fill="white"
        ></path>
    </svg>
);
const ghostBody = (
    <svg
        width="300"
        height="300"
        viewBox="0 0 750 700"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="pveRaceGhostBody"
    >
        <path
            opacity="0.5"
            d="M331 664C252.669 664 189.169 653.448 189.169 640.432C189.169 627.416 252.669 616.865 331 616.865C409.331 616.865 472.831 627.416 472.831 640.432C472.831 653.448 409.331 664 331 664Z"
            fill="#ECF0F4"
        ></path>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M279.529 130.038C285.701 131.507 291.289 133.609 296.281 136.033C293.417 136.752 290.588 137.557 287.795 138.446C271.368 130.02 241.623 117.562 225.18 127.712C209.65 137.299 211.889 170.217 215.209 191.493C198.807 214.561 189.169 242.744 189.169 273.171V449.142C189.169 479.59 189.169 497.642 193.109 511.011V556.74C193.109 580.184 212.171 599.189 235.686 599.189H237.206C242.486 599.189 247.54 598.231 252.205 596.481V597.225V599.189C252.205 619.797 268.963 636.505 289.633 636.505C310.303 636.505 327.06 619.797 327.06 599.189V597.225V555.982H334.94V597.225V599.189C334.94 619.797 351.698 636.505 372.368 636.505C393.038 636.505 409.795 619.797 409.795 599.189V597.225V555.982H427.861C499.99 555.982 576.557 525.715 564.005 368.149C550.663 200.654 612.693 174.973 612.693 174.973C535.349 174.973 490.001 194.873 463.57 222.803C459.972 213.394 455.394 204.465 449.961 196.143C453.605 175.374 457.474 138.03 440.76 127.712C423.793 117.239 392.663 130.837 376.617 139.238C372.265 137.766 367.819 136.498 363.29 135.447C355.155 127.226 344.035 118.634 329.41 112.499C324.336 110.379 318.448 112.745 316.269 117.807C315.403 119.823 315.249 121.961 315.7 123.938C307.139 118.442 296.567 113.39 283.983 110.405C278.682 109.155 273.381 112.521 272.16 117.944C270.938 123.366 274.228 128.789 279.529 130.038Z"
            fill="#E1E7EC"
        ></path>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M296.33 555.982H359.29C369.462 555.982 378.334 555.982 386.157 555.852V438.191C386.157 399.118 354.385 367.441 315.194 367.441H244.373C222.074 367.441 202.178 377.695 189.169 393.731V449.142C189.169 486.54 189.169 505.239 196.469 519.523C202.889 532.086 213.136 542.303 225.738 548.704C240.065 555.982 258.82 555.982 296.33 555.982ZM261.934 502.835C260.782 503.986 260.782 505.852 261.934 507.003L267.726 512.775L261.934 518.547C260.782 519.698 260.782 521.564 261.934 522.714C263.089 523.863 264.96 523.863 266.114 522.714L271.904 516.94L277.693 522.714C278.848 523.863 280.719 523.863 281.873 522.714C283.026 521.564 283.026 519.698 281.873 518.547L276.082 512.775L281.873 507.003C283.026 505.852 283.026 503.986 281.873 502.835C280.719 501.686 278.848 501.686 277.693 502.835L271.904 508.609L266.114 502.835C264.96 501.686 263.089 501.686 261.934 502.835Z"
            fill="#ECF0F4"
        ></path>
    </svg>
);
const ghostArm = (
    <svg
        width="300"
        height="300"
        viewBox="0 0 750 700"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="pveRaceGhostArm"
    >
        <path
            d="M476 346H388V556.771C388 580.646 407.354 600 431.229 600H432.771C456.646 600 476 580.646 476 556.771V346Z"
            fill="#E1E7EC"
        ></path>
    </svg>
);

export {
    commonIcon,
    uncommonIcon,
    rareIcon,
    epicIcon,
    legendaryIcon,
    uniqueIcon,
    strengthIcon,
    staminaIcon,
    speedIcon,
    ghostBody,
    ghostArm,
};
