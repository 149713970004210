import { useSelector } from "react-redux";

import LoginRegister from "../loginRegister/LoginRegister";
import DashboardStats from "../dashboardStats/DashboardStats";

import Container from "@mui/material/Container";

const DashboardStatsPage = () => {
    const isAuth = useSelector((state) => state.authorization.isAuth);

    return (
        <>
            <Container>
                {!isAuth ? <LoginRegister /> : <DashboardStats />}
            </Container>
        </>
    );
};

export default DashboardStatsPage;
