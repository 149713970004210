import "./Faucet.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../axios/axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { SmartCaptcha } from "@yandex/smart-captcha";
import SlotCounter from "react-slot-counter";

import { useSelector, useDispatch } from "react-redux";
import { setBalance } from "../../store/userSlice";
import { setProcessPlay } from "../../store/gameProcessSlice";

import LoadingButton from "@mui/lab/LoadingButton";
import Skeleton from "@mui/material/Skeleton";
import Container from "@mui/material/Container";

import ProgressCountdown from "../progressCountdown/ProgressCountdown";

const Faucet = () => {
    const user = useSelector((state) => state.user.user);
    const hideBalance = useSelector((state) => state.gameProcess.hideBalance);

    const [adBlockDetected, setAdBlockDetected] = useState(false);
    const [loadingAdBlock, setLoadingAdBlock] = useState(true);

    const [tokenCaptcha, setTokenCaptcha] = useState("");

    const [count, setCount] = useState(0);
    const [timer, setTimer] = useState(user.lastTimeFaucet + 3600000);
    const [reward, setReward] = useState(0);
    const [winMsg, setWinMsg] = useState(false);
    const [showBalance, setShowBalance] = useState(true);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("Error!");

    const dispatch = useDispatch();

    // set axios request
    const faucetRequest = axios.create({
        withCredentials: true,
        baseURL: baseUrl,
    });
    faucetRequest.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
            "token"
        )}`;
        return config;
    });
    faucetRequest.interceptors.response.use(
        (config) => {
            return config;
        },
        async (error) => {
            const originalRequest = error.config;

            if (
                error.response.status === 401 &&
                error.config &&
                !error.config._isRetry
            ) {
                originalRequest._isRetry = true;
                axios
                    .get(`${baseUrl}/refresh`, {
                        withCredentials: true,
                    })
                    .then((res) => {
                        localStorage.setItem("token", res.data.accessToken);

                        setLoading(true);
                        dispatch(setProcessPlay(true));

                        faucetRequest
                            .request(originalRequest)
                            .then((res2) => {
                                // console.log(res2.data);
                                dispatch(setBalance(res2.data));

                                setTimer(res2.data.timer);
                                setCount(res2.data.luckyNumber);
                                setReward(res2.data.reward);

                                setTimeout(() => {
                                    setWinMsg(true);
                                    setShowBalance(true);
                                }, 1400);
                            })
                            .catch((e) => {
                                console.log(e.response?.data?.message);
                                setError(true);
                                setErrorMsg(e.response?.data?.message);
                                setShowBalance(true);
                            })
                            .finally(() => {
                                setLoading(false);
                                dispatch(setProcessPlay(false));
                            });
                    })
                    .catch((e) => {
                        console.log("you not authorize");
                    });
            } else {
                throw error;
            }
        }
    );

    useEffect(() => {
        let adBlock = false;
        // ad block from adblock-detect-react
        const url = "https://www3.doubleclick.net";

        let controller = new AbortController();
        setTimeout(() => controller.abort(), 5000);

        // try axios instead fetch?
        fetch(url, {
            // axios
            // timeout: 5000,
            method: "HEAD",
            mode: "no-cors",
            cache: "no-store",
            signal: controller.signal,
        })
            .then(({ redirected }) => {
                if (redirected) {
                    adBlock = true;
                }
            })
            .catch((e) => {
                // axios
                // if (e.code === "ECONNABORTED") {
                //     adBlock = false;
                // } else {
                //     adBlock = true;
                // }

                if (e.name === "AbortError") {
                    adBlock = false;
                } else {
                    adBlock = true;
                }
            })
            .finally(() => {
                setAdBlockDetected(adBlock);
                setLoadingAdBlock(false);
                // console.log(adBlock);
            });
    }, []);

    const roll = () => {
        setError(false);
        setErrorMsg("");
        setWinMsg(false);
        setShowBalance(false);
        setLoading(true);
        dispatch(setProcessPlay(true));
        const userEmail = user.userEmail;

        faucetRequest
            .post("/faucet", { userEmail })
            .then((res) => {
                // console.log(res.data);
                if (res !== undefined) {
                    dispatch(setBalance(res.data));

                    setTimer(res.data.timer);
                    setCount(res.data.luckyNumber);
                    setReward(res.data.reward);
                    setLoading(false);
                    dispatch(setProcessPlay(false));

                    setTimeout(() => {
                        setWinMsg(true);
                        setShowBalance(true);
                    }, 1400);
                }
            })
            .catch((e) => {
                console.log(e.response?.data?.message);
                setError(true);
                setErrorMsg(e.response?.data?.message);
                setShowBalance(true);
                dispatch(setProcessPlay(false));
            });
    };

    // token captcha
    const handleTokenExpired = () => {
        setTokenCaptcha("");
    };

    const btnDisabled = () => {
        let result = true;
        let captcha = false;
        let ad = false;
        let time = true;
        const currentDate = new Date();

        if (tokenCaptcha.length > 0) {
            captcha = true;
        }

        if (adBlockDetected) {
            ad = true;
        }

        // < 0
        if (timer - currentDate.getTime() < 0) {
            time = true;
        } else {
            time = false;
        }

        result = !(captcha && !ad && time);

        return result;
    };

    const tableReward1 =
        Math.round(user.globalFaucetMinValue * user.decimal) / user.decimal;
    const tableReward2 =
        Math.round(user.globalFaucetMinValue * 10 * user.decimal) /
        user.decimal;
    const tableReward3 =
        Math.round(user.globalFaucetMinValue * 100 * user.decimal) /
        user.decimal;
    const tableReward4 =
        Math.round(user.globalFaucetMinValue * 1000 * user.decimal) /
        user.decimal;
    const tableReward5 =
        Math.round(user.globalFaucetMinValue * 10000 * user.decimal) /
        user.decimal;
    const tableReward6 =
        Math.round(user.globalFaucetMinValue * 10000 * 2 * user.decimal) /
        user.decimal;

    const skeleton = (width, height) => {
        return (
            <Skeleton className="pveSkeleton" width={width} height={height} />
        );
    };

    return (
        <Container>
            <div className="pveFaucetWrapper">
                <div className="pveWithdrawLine"></div>
                <div className="pveBalanceDepositTitle">
                    Faucet{" "}
                    <span className="pveFaucetTitle">{user.tokenSymbol}</span>
                </div>
                <div className="pveLotteryDescriptionWrapper smoothBackground">
                    <div className="pveLotteryDescription">
                        Every hour you can get some{" "}
                        <span className="pveFaucetTitle">
                            {user.tokenSymbol}
                        </span>{" "}
                        to your balance. You must have your{" "}
                        <span className="pveFaucetAdBlocker">
                            ad blocker disabled
                        </span>{" "}
                        to get it! Read more about the terms and conditions{" "}
                        <Link className="pveLotteryRulesLink" to="/docs/faucet">
                            here
                        </Link>
                        .
                    </div>
                </div>

                <div className="pveFaucetText">
                    Please click the ROLL button to{" "}
                    <span className="pveFaucetTitle">
                        WIN UP TO {tableReward6} IN FREE {user.tokenSymbol}{" "}
                        EVERY HOUR!
                    </span>
                </div>

                <div className="pveFaucetHorizontalAd">
                    {/* <ins className="65e47788188da91d00dfc259 pveFaucetAdH"></ins> */}
                    <div className="pveFaucetAdsTestModeH">Advertising</div>
                </div>

                <div className="pveFaucetMainBlockWrapper">
                    <div className="pveFaucetMainItem">
                        <div className="pveFaucetVerticalAd">
                            {/* <ins className="65e4787d188da91d00dfc8f6 pveFaucetAdV"></ins> */}
                            <div className="pveFaucetAdsTestModeV">
                                Advertising
                            </div>
                        </div>
                    </div>
                    <div className="pveFaucetMainItem">
                        <div className="pveFaucetBalanceTitle">
                            Your balance
                        </div>
                        <div className="pveBalanceValue">
                            {showBalance ? (
                                <>
                                    <span>
                                        {hideBalance
                                            ? skeleton(70)
                                            : Math.round(
                                                  user.balance * user.decimal
                                              ) / user.decimal}
                                    </span>{" "}
                                    <span className="pveBalanceTokenSymbol">
                                        {user.tokenSymbol}
                                    </span>
                                </>
                            ) : (
                                skeleton(70)
                            )}
                        </div>

                        <div className="pveFaucetMainTableWrapper">
                            <div className="pveFaucetTableColumn">
                                <div className="pveFaucetTableTitle">
                                    Lucky number
                                </div>
                                <div className="pveFaucetTableRow">
                                    1 - 9885
                                </div>
                                <div className="pveFaucetTableRow">
                                    9886 - 9985
                                </div>
                                <div className="pveFaucetTableRow">
                                    9986 - 9993
                                </div>
                                <div className="pveFaucetTableRow">
                                    9994 - 9997
                                </div>
                                <div className="pveFaucetTableRow">
                                    9998 - 9999
                                </div>
                                <div className="pveFaucetTableRow">10000</div>
                            </div>
                            <div className="pveFaucetTableColumn">
                                <div className="pveFaucetTableTitle">
                                    Payout
                                </div>
                                <div className="pveFaucetTableRow">
                                    {tableReward1} {user.tokenSymbol}
                                </div>
                                <div className="pveFaucetTableRow">
                                    {tableReward2} {user.tokenSymbol}
                                </div>
                                <div className="pveFaucetTableRow">
                                    {tableReward3} {user.tokenSymbol}
                                </div>
                                <div className="pveFaucetTableRow">
                                    {tableReward4} {user.tokenSymbol}
                                </div>
                                <div className="pveFaucetTableRow">
                                    {tableReward5} {user.tokenSymbol}
                                </div>
                                <div className="pveFaucetTableRow">
                                    {tableReward6} {user.tokenSymbol}
                                </div>
                            </div>
                        </div>

                        <div className="pveFaucetDigitsWrapper">
                            <SlotCounter
                                value={count}
                                startValue={"00000"}
                                duration={1}
                                autoAnimationStart={false}
                                animateUnchanged={true}
                                charClassName="pveFaucetDigit"
                            />
                        </div>

                        {winMsg ? (
                            <div className="pveFaucetWinText">
                                You win {reward} <span>{user.tokenSymbol}</span>
                            </div>
                        ) : null}

                        {error ? (
                            <div className="pveFaucetWinText pveFaucetError">
                                {errorMsg}
                            </div>
                        ) : null}

                        <div
                            className={
                                adBlockDetected
                                    ? "pveFaucetAdBlockerEnable show"
                                    : "pveFaucetAdBlockerEnable hide"
                            }
                        >
                            Disable your ad blocker!
                        </div>

                        <LoadingButton
                            className="pveFaucetBtn"
                            variant="contained"
                            onClick={roll}
                            loading={loadingAdBlock || loading}
                            disabled={btnDisabled()}
                        >
                            ROLL!
                        </LoadingButton>

                        <div className="pveCaptcha">
                            <SmartCaptcha
                                sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                                onSuccess={setTokenCaptcha}
                                language="en"
                                onTokenExpired={handleTokenExpired}
                                // test={true}
                            />
                        </div>

                        <div
                            className={
                                btnDisabled()
                                    ? "pveFaucetTimer show"
                                    : "pveFaucetTimer hide"
                            }
                        >
                            <ProgressCountdown
                                base={moment().toDate()}
                                hideBar={true}
                                faucet={true}
                                deadline={new Date(timer)} // milliseconds
                            />
                        </div>
                    </div>
                    <div className="pveFaucetMainItem">
                        <div className="pveFaucetVerticalAd">
                            {/* <ins className="65e478f6dff9ffeb6e70ee8c pveFaucetAdV"></ins> */}
                            <div className="pveFaucetAdsTestModeV">
                                Advertising
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Faucet;
