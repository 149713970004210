import "./DashboardStats.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../axios/axios";
import { updateTime } from "../utils/updateTime";

import { useSelector } from "react-redux";

import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";

const DashboardStats = () => {
    const user = useSelector((state) => state.user.user);

    const [dashboardData, setDashboardData] = useState();
    const [loadingDashboard, setLoadingDashboard] = useState(true);
    const [errorDashboard, setErrorDashboard] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const userEmail = user.userEmail;

    // set axios request
    const dashboardRequest = axios.create({
        withCredentials: true,
        baseURL: baseUrl,
    });
    dashboardRequest.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
        return config;
    });
    dashboardRequest.interceptors.response.use(
        (config) => {
            return config;
        },
        async (error) => {
            const originalRequest = error.config;

            if (error.response.status === 401 && error.config && !error.config._isRetry) {
                originalRequest._isRetry = true;
                axios
                    .get(`${baseUrl}/dashboardStats`, {
                        withCredentials: true,
                    })
                    .then((res) => {
                        localStorage.setItem("token", res.data.accessToken);

                        dashboardRequest
                            .request(originalRequest)
                            .then((resDashboard) => {
                                // console.log(resDashboard.data);
                                setDashboardData(resDashboard.data);
                            })
                            .catch((e) => {
                                console.log(e.response?.data?.message);
                                setErrorMsg(e.response?.data?.message);
                                setErrorDashboard(true);
                            })
                            .finally(() => {
                                setLoadingDashboard(false);
                            });
                    })
                    .catch((e) => {
                        console.log("You not authorize");
                    });
            } else {
                throw error;
            }
        }
    );

    const getDashboardData = async () => {
        setLoadingDashboard(true);
        setErrorDashboard(false);
        setErrorMsg("");
        dashboardRequest
            .post("/dashboardStats", { userEmail })
            .then((res) => {
                if (res !== undefined) {
                    setDashboardData(res.data);
                    // console.log(res.data);
                    setLoadingDashboard(false);
                }
            })
            .catch((e) => {
                console.log(e.response?.data?.message);
                setErrorMsg(e.response?.data?.message);
                setErrorDashboard(true);
                setLoadingDashboard(false);
            });
    };

    useEffect(() => {
        getDashboardData();
    }, []);

    // function for calculate profit table value.
    // EP - expected profit, L - lottery, RP - referral program, T - total
    // 1 - 1 day, 7 - 7 days, 30 - 30 days, AT - all time
    // Example: RP7 - value referral program for 7 days
    const calcProfitTable = (data, value) => {
        const EP1 = data.profitStats.profit.expectedProfitYesterday;
        const EP7 = data.profitStats.profit.expectedProfit7Days;
        const EP30 = data.profitStats.profit.expectedProfit30Days;
        const EPAT = data.profitStats.profit.allExpectedProfit;

        const lotteryProportion = data.lottery.lotteryPercent / data.profitStats.expectedProfitFor1BetPercent;
        const L1 =
            Math.round(
                data.profitStats.profit.expectedProfitYesterday * lotteryProportion * data.mainGameParams.decimalBalance
            ) / data.mainGameParams.decimalBalance;
        const L7 =
            Math.round(
                data.profitStats.profit.expectedProfit7Days * lotteryProportion * data.mainGameParams.decimalBalance
            ) / data.mainGameParams.decimalBalance;
        const L30 =
            Math.round(
                data.profitStats.profit.expectedProfit30Days * lotteryProportion * data.mainGameParams.decimalBalance
            ) / data.mainGameParams.decimalBalance;
        const LAT =
            Math.round(
                data.profitStats.profit.allExpectedProfit * lotteryProportion * data.mainGameParams.decimalBalance
            ) / data.mainGameParams.decimalBalance;

        const RP1 = data.profitStats.referralRewards.rewardsDay;
        const RP7 = data.profitStats.referralRewards.rewardsWeek;
        const RP30 = data.profitStats.referralRewards.rewardsMonth;
        const RPAT = data.profitStats.referralRewards.rewardsAllTime;

        const T1 = EP1 - L1 - RP1;
        const T7 = EP7 - L7 - RP7;
        const T30 = EP30 - L30 - RP30;
        const TAT = EPAT - LAT - RPAT;

        if (value === "EP1") {
            return EP1.toLocaleString();
        }
        if (value === "EP7") {
            return EP7.toLocaleString();
        }
        if (value === "EP30") {
            return EP30.toLocaleString();
        }
        if (value === "EPAT") {
            return EPAT.toLocaleString();
        }

        if (value === "L1") {
            return L1.toLocaleString();
        }
        if (value === "L7") {
            return L7.toLocaleString();
        }
        if (value === "L30") {
            return L30.toLocaleString();
        }
        if (value === "LAT") {
            return LAT.toLocaleString();
        }

        if (value === "RP1") {
            return RP1.toLocaleString();
        }
        if (value === "RP7") {
            return RP7.toLocaleString();
        }
        if (value === "RP30") {
            return RP30.toLocaleString();
        }
        if (value === "RPAT") {
            return RPAT.toLocaleString();
        }

        if (value === "T1") {
            return T1.toLocaleString();
        }
        if (value === "T7") {
            return T7.toLocaleString();
        }
        if (value === "T30") {
            return T30.toLocaleString();
        }
        if (value === "TAT") {
            return TAT.toLocaleString();
        }
    };

    // console.log(dashboardData);

    return (
        <Container>
            {!loadingDashboard ? (
                !errorDashboard ? (
                    <>
                        <div className="pveBalanceDepositTitle">Game statistic</div>
                        <div className="dashboardToken">
                            Token: <span>{dashboardData.tokenSymbol}</span>
                        </div>

                        <div className="dashboardGameParamsWrapper">
                            <div className="dashboardGameParamsItem">
                                <div className="dashboardGameParamsTitle">Main game parameters</div>
                                <div className="dashboardGameParamsSubWrapper">
                                    <div className="dashboardGameParamsSubItem">
                                        <div className="dashboardGameParamsName">Users (all registrations)</div>
                                        <div className="dashboardGameParamsName">Games PvE (all time)</div>
                                        <div className="dashboardGameParamsName">Cemeteries (all time)</div>
                                        <div className="dashboardGameParamsName">Games last week</div>
                                        <div className="dashboardGameParamsName">Games last month</div>
                                        <div className="dashboardGameParamsName">Decimal balance</div>
                                    </div>
                                    <div className="dashboardGameParamsSubItem">
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.mainGameParams.users.toLocaleString()}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.mainGameParams.games.allGames.toLocaleString()}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.mainGameParams.games.allCemeteries.toLocaleString()}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.mainGameParams.games.games7Days.toLocaleString()}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.mainGameParams.games.games30Days.toLocaleString()}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {String(dashboardData.mainGameParams.decimalBalance).length - 1}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="dashboardGameParamsItem">
                                <div className="dashboardGameParamsTitle">Ratios</div>
                                <div className="dashboardGameParamsSubWrapper">
                                    <div className="dashboardGameParamsSubItem">
                                        <div className="dashboardGameParamsName colorCommon">Common</div>
                                        <div className="dashboardGameParamsName colorUncommon">Uncommon</div>
                                        <div className="dashboardGameParamsName colorRare">Rare</div>
                                        <div className="dashboardGameParamsName colorEpic">Epic</div>
                                        <div className="dashboardGameParamsName colorLegendary">Legendary</div>
                                    </div>
                                    <div className="dashboardGameParamsSubItem">
                                        <div className="dashboardGameParamsValue colorCommon">
                                            {dashboardData.ratios.ratioCommon}
                                        </div>
                                        <div className="dashboardGameParamsValue colorUncommon">
                                            {dashboardData.ratios.ratioUncommon}
                                        </div>
                                        <div className="dashboardGameParamsValue colorRare">
                                            {dashboardData.ratios.ratioRare}
                                        </div>
                                        <div className="dashboardGameParamsValue colorEpic">
                                            {dashboardData.ratios.ratioEpic}
                                        </div>
                                        <div className="dashboardGameParamsValue colorLegendary">
                                            {dashboardData.ratios.ratioLegendary}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="dashboardGameParamsItem">
                                <div className="dashboardGameParamsTitle">Global permits</div>
                                <div className="dashboardGameParamsSubWrapper">
                                    <div className="dashboardGameParamsSubItem">
                                        <div className="dashboardGameParamsName">Deposit allowed</div>
                                        <div className="dashboardGameParamsName">Withdraw allowed</div>
                                        <div className="dashboardGameParamsName">Play allowed PvE</div>
                                        <div className="dashboardGameParamsName">Play allowed brain farm</div>
                                        <div className="dashboardGameParamsName">Lottery allowed</div>
                                        <div className="dashboardGameParamsName">Lottery ticket count</div>
                                        <div className="dashboardGameParamsName">Faucet allowed</div>
                                        <div className="dashboardGameParamsName">Transfer of ref. balance</div>
                                    </div>
                                    <div className="dashboardGameParamsSubItem">
                                        <div
                                            className={
                                                dashboardData.permits.depositAllowed
                                                    ? "dashboardGameParamsValue textGreen"
                                                    : "dashboardGameParamsValue textRed"
                                            }
                                        >
                                            {String(dashboardData.permits.depositAllowed)}
                                        </div>
                                        <div
                                            className={
                                                dashboardData.permits.withdrawAllowed
                                                    ? "dashboardGameParamsValue textGreen"
                                                    : "dashboardGameParamsValue textRed"
                                            }
                                        >
                                            {String(dashboardData.permits.withdrawAllowed)}
                                        </div>
                                        <div
                                            className={
                                                dashboardData.permits.playAllowed
                                                    ? "dashboardGameParamsValue textGreen"
                                                    : "dashboardGameParamsValue textRed"
                                            }
                                        >
                                            {String(dashboardData.permits.playAllowed)}
                                        </div>
                                        <div
                                            className={
                                                dashboardData.permits.playAllowedBrainFarm
                                                    ? "dashboardGameParamsValue textGreen"
                                                    : "dashboardGameParamsValue textRed"
                                            }
                                        >
                                            {String(dashboardData.permits.playAllowedBrainFarm)}
                                        </div>
                                        <div
                                            className={
                                                dashboardData.permits.lotteryAllowed
                                                    ? "dashboardGameParamsValue textGreen"
                                                    : "dashboardGameParamsValue textRed"
                                            }
                                        >
                                            {String(dashboardData.permits.lotteryAllowed)}
                                        </div>
                                        <div
                                            className={
                                                dashboardData.permits.ticketCount
                                                    ? "dashboardGameParamsValue textGreen"
                                                    : "dashboardGameParamsValue textRed"
                                            }
                                        >
                                            {String(dashboardData.permits.ticketCount)}
                                        </div>
                                        <div
                                            className={
                                                dashboardData.permits.faucetAllowed
                                                    ? "dashboardGameParamsValue textGreen"
                                                    : "dashboardGameParamsValue textRed"
                                            }
                                        >
                                            {String(dashboardData.permits.faucetAllowed)}
                                        </div>
                                        <div
                                            className={
                                                dashboardData.permits.changeRefBalanceAllowed
                                                    ? "dashboardGameParamsValue textGreen"
                                                    : "dashboardGameParamsValue textRed"
                                            }
                                        >
                                            {String(dashboardData.permits.changeRefBalanceAllowed)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="dashboardGameParamsItem">
                                <div className="dashboardGameParamsTitle">Limitations</div>
                                <div className="dashboardGameParamsSubWrapper">
                                    <div className="dashboardGameParamsSubItem">
                                        <div className="dashboardGameParamsName">Min deposit (for 1 tx)</div>
                                        <div className="dashboardGameParamsName">Max deposit (for 1 tx)</div>
                                        <div className="dashboardGameParamsName">Min withdraw (for 1 tx)</div>
                                        <div className="dashboardGameParamsName">Max withdraw (for 1 tx)</div>
                                        <div className="dashboardGameParamsName">Min bet</div>
                                        <div className="dashboardGameParamsName">Max bet</div>
                                        <div className="dashboardGameParamsName dashboardGameParamsNameSmallFs">
                                            Delayed withdrawal without fee, hours
                                        </div>
                                    </div>
                                    <div className="dashboardGameParamsSubItem">
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.limitations.minDeposit}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.limitations.maxDeposit.toLocaleString()}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.limitations.minWithdraw}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.limitations.maxWithdraw.toLocaleString()}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.limitations.minBet}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.limitations.maxBet}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.limitations.delayHWithdraw}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="dashboardToken dashboardSubTitle">Mechanics</div>

                        <div className="dashboardGameParamsWrapper dashboardGameParamsWrapperLine2">
                            <div className="dashboardGameParamsItem dashboardGameParamsItemLine2">
                                <div className="dashboardGameParamsTitle">Lottery</div>
                                <div className="dashboardGameParamsSubWrapper">
                                    <div className="dashboardGameParamsSubItem">
                                        <div className="dashboardGameParamsName">Turnover for ticket</div>
                                        <div className="dashboardGameParamsName">Percentage of income</div>
                                        <div className="dashboardGameParamsName">Ticket price (not used)</div>
                                        <div className="dashboardGameParamsName">Current lottery:</div>
                                        <div className="dashboardGameParamsName">Number of lottery</div>
                                        <div className="dashboardGameParamsName">Last update</div>
                                        <div className="dashboardGameParamsName">Start date</div>
                                        <div className="dashboardGameParamsName">Finish date</div>
                                        <div className="dashboardGameParamsName">Min participants</div>
                                        <div className="dashboardGameParamsName">Participants</div>
                                        <div className="dashboardGameParamsName">Min tickets</div>
                                        <div className="dashboardGameParamsName">Tickets</div>
                                        <div className="dashboardGameParamsName">Prize pool</div>
                                        <div className="dashboardGameParamsName">1st place reward</div>
                                        <div className="dashboardGameParamsName">2st place reward</div>
                                        <div className="dashboardGameParamsName">3st place reward</div>
                                    </div>
                                    <div className="dashboardGameParamsSubItem">
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.lottery.turnoverForTicket.toLocaleString()}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.lottery.lotteryPercent}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.lottery.ticketPrice.toLocaleString()}
                                        </div>
                                        <div className="dashboardGameParamsValue">~</div>
                                        <div className="dashboardGameParamsValue">
                                            # {dashboardData.lottery.currentLottery.numberOfLottery}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {updateTime(dashboardData.lottery.currentLottery.lastUpdate)}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {updateTime(dashboardData.lottery.currentLottery.dateStart)}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {updateTime(dashboardData.lottery.currentLottery.dateFinish)}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.lottery.currentLottery.minParticipants}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.lottery.currentLottery.participants}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.lottery.currentLottery.minTickets}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.lottery.currentLottery.tickets}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.lottery.currentLottery.sumReward.toLocaleString()}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.lottery.currentLottery.place1Reward.toLocaleString()}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.lottery.currentLottery.place2Reward.toLocaleString()}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.lottery.currentLottery.place3Reward.toLocaleString()}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="dashboardGameParamsItem dashboardGameParamsItemLine2">
                                <div className="dashboardGameParamsTitle">Faucet</div>
                                <div className="dashboardGameParamsSubWrapper">
                                    <div className="dashboardGameParamsSubItem">
                                        <div className="dashboardGameParamsName">Min reward</div>
                                        <div className="dashboardGameParamsName">Period between rewards, hours</div>
                                        <div className="dashboardGameParamsName">Last update statistic</div>
                                        <div className="dashboardGameParamsName">Payout for day</div>
                                        <div className="dashboardGameParamsName">Payout for week</div>
                                        <div className="dashboardGameParamsName">Payout for month</div>
                                    </div>
                                    <div className="dashboardGameParamsSubItem">
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.faucet.faucetMinValue}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.faucet.faucetPeriodTimeH}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {updateTime(dashboardData.faucet.lastUpdateFaucetStats)}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.faucet.payout.payoutDay}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.faucet.payout.payoutWeek}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.faucet.payout.payoutMonth}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="dashboardToken dashboardSubTitle">Profit statistic</div>

                        <div className="dashboardGameParamsWrapper dashboardGameParamsWrapperLine3">
                            <div className="dashboardGameParamsItem dashboardGameParamsItemLine3">
                                <div className="dashboardGameParamsTitle">Main parameters</div>
                                <div className="dashboardGameParamsSubWrapper">
                                    <div className="dashboardGameParamsSubItem">
                                        <div className="dashboardGameParamsName">Last update deposit/withdraw</div>
                                        <div className="dashboardGameParamsName">All deposits (all time)</div>
                                        <div className="dashboardGameParamsName">All withdraws (all time)</div>
                                        <div className="dashboardGameParamsName">Expected profit for one bet, %</div>
                                        <div className="dashboardGameParamsName">Common referral percent</div>
                                        <div className="dashboardGameParamsName">Margin percentage, probability</div>
                                        <div className="dashboardGameParamsName">
                                            Percent for burn (of manager's price), {dashboardData.tokenSymbol}
                                        </div>
                                        <div className="dashboardGameParamsName">
                                            Burned, {dashboardData.tokenSymbol}
                                        </div>
                                    </div>
                                    <div className="dashboardGameParamsSubItem">
                                        <div className="dashboardGameParamsValue">
                                            {updateTime(dashboardData.profitStats.lastUpdateDepoHistory)}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.profitStats.allDeposits.toLocaleString()}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.profitStats.allWithdraws.toLocaleString()}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.profitStats.expectedProfitFor1BetPercent}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.profitStats.referralPercentCommon}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.profitStats.margePercent}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.profitStats.percentForBurn}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.profitStats.burned.toLocaleString()}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="dashboardGameParamsItem dashboardGameParamsItemLine3">
                                <div className="dashboardGameParamsTitle">Profit</div>
                                <div className="dashboardGameParamsSubWrapper">
                                    <div className="dashboardGameParamsSubItem">
                                        <div className="dashboardGameParamsName">Last update profit</div>
                                        <div className="dashboardGameParamsName">Last update referral statistic</div>
                                        <div className="dashboardGameParamsName">Withdrawal fee (all time)</div>
                                        <div className="dashboardGameParamsName">Withdrawal fee for 1 day</div>
                                        <div className="dashboardGameParamsName">Withdrawal fee for 7 days</div>
                                        <div className="dashboardGameParamsName">Withdrawal fee for 30 days</div>
                                    </div>
                                    <div className="dashboardGameParamsSubItem">
                                        <div className="dashboardGameParamsValue">
                                            {updateTime(dashboardData.profitStats.lastUpdateProfitHistory)}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {updateTime(dashboardData.profitStats.referralRewards.lastUpdate)}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.profitStats.fees.allWithdrawFees.toLocaleString()}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.profitStats.fees.withdrawFeesYesterday.toLocaleString()}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.profitStats.fees.withdrawFees7Days.toLocaleString()}
                                        </div>
                                        <div className="dashboardGameParamsValue">
                                            {dashboardData.profitStats.fees.withdrawFees30Days.toLocaleString()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="dashboardTableWrapper">
                            <div className="dashboardTableRow">
                                <div className="dashboardTableCell dashboard-border-none dashboardTableToken">
                                    {dashboardData.tokenSymbol}
                                </div>
                                <div className="dashboardTableCell dashboard-border-top">
                                    Expected profit ({dashboardData.profitStats.expectedProfitFor1BetPercent} % + brain
                                    farm)
                                </div>
                                <div className="dashboardTableCell dashboard-border-top">
                                    Lottery ({dashboardData.lottery.lotteryPercent} %)
                                </div>
                                <div className="dashboardTableCell dashboard-border-top">
                                    Ref. program (real values)
                                </div>
                                <div className="dashboardTableCell dashboard-border-top dashboard-border-right">
                                    Total
                                </div>
                            </div>
                            <div className="dashboardTableRow">
                                <div className="dashboardTableCell dashboard-border-left">1 day</div>
                                <div className="dashboardTableCell textGreen">
                                    {calcProfitTable(dashboardData, "EP1")}
                                </div>
                                <div className="dashboardTableCell textRed">{calcProfitTable(dashboardData, "L1")}</div>
                                <div className="dashboardTableCell textRed">
                                    {calcProfitTable(dashboardData, "RP1")}
                                </div>
                                <div className="dashboardTableCell dashboard-border-right textGreen">
                                    {calcProfitTable(dashboardData, "T1")}
                                </div>
                            </div>
                            <div className="dashboardTableRow">
                                <div className="dashboardTableCell dashboard-border-left">7 days</div>
                                <div className="dashboardTableCell textGreen">
                                    {calcProfitTable(dashboardData, "EP7")}
                                </div>
                                <div className="dashboardTableCell textRed">{calcProfitTable(dashboardData, "L7")}</div>
                                <div className="dashboardTableCell textRed">
                                    {calcProfitTable(dashboardData, "RP7")}
                                </div>
                                <div className="dashboardTableCell dashboard-border-right textGreen">
                                    {calcProfitTable(dashboardData, "T7")}
                                </div>
                            </div>
                            <div className="dashboardTableRow">
                                <div className="dashboardTableCell dashboard-border-left">30 days</div>
                                <div className="dashboardTableCell textGreen">
                                    {calcProfitTable(dashboardData, "EP30")}
                                </div>
                                <div className="dashboardTableCell textRed">
                                    {calcProfitTable(dashboardData, "L30")}
                                </div>
                                <div className="dashboardTableCell textRed">
                                    {calcProfitTable(dashboardData, "RP30")}
                                </div>
                                <div className="dashboardTableCell dashboard-border-right textGreen">
                                    {calcProfitTable(dashboardData, "T30")}
                                </div>
                            </div>
                            <div className="dashboardTableRow">
                                <div className="dashboardTableCell dashboard-border-bottom dashboard-border-left">
                                    All time
                                </div>
                                <div className="dashboardTableCell dashboard-border-bottom textGreen">
                                    {calcProfitTable(dashboardData, "EPAT")}
                                </div>
                                <div className="dashboardTableCell dashboard-border-bottom textRed">
                                    {calcProfitTable(dashboardData, "LAT")}
                                </div>
                                <div className="dashboardTableCell dashboard-border-bottom textRed">
                                    {calcProfitTable(dashboardData, "RPAT")}
                                </div>
                                <div className="dashboardTableCell dashboard-border-bottom dashboard-border-right textGreen">
                                    {calcProfitTable(dashboardData, "TAT")}
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="dashboardError">{errorMsg}</div>
                )
            ) : (
                <CircularProgress color="primary" className="pveFormSpinner" />
            )}
        </Container>
    );
};

export default DashboardStats;
