import "./Instruction.scss";

import { Link } from "react-router-dom";

import { Container } from "@mui/material";
import Alert from "@mui/material/Alert";

const Instruction = () => {
    return (
        <Container>
            <div className="pveRulesTitle">Game instruction</div>
            <div className="pveInstructionSubtitleMain">
                The game is currently only available for the desktop version, for devices with a screen width greater
                than 1000 px.
            </div>
            <div className="pveRulesWrapper">
                <div className="pveInstructionSubtitle">1. Account</div>
                <div className="pveRulesItem pveInstructionItem">
                    You must create an account to play the game. There is currently no password recovery feature, so if
                    you lose your password, you will lose access to your account.
                </div>
                <div className="pveInstructionSubtitle">2. Funding your account</div>
                <div className="pveRulesItem pveInstructionItem">
                    To start the game it is necessary to replenish your account. To do this, you need to connect your
                    web3 wallet (
                    <a className="pveInstructionLink" href="https://metamask.io" target="_blank" rel="noreferrer">
                        Metamask
                    </a>
                    ,{" "}
                    <a className="pveInstructionLink" href="https://rabby.io" target="_blank" rel="noreferrer">
                        Rabby
                    </a>
                    ), click the button "CONNECT WALLET". Then enter the required amount according to the limits in the
                    field below. Then press "DEPOSIT" button. At the moment of replenishment, your wallet is linked to
                    your account. In the future you will be able to interact with the game (ZOMB deposit and withdrawal)
                    only from this wallet. After the transaction is completed, you will see your balance. If you have
                    problems at this point, contact{" "}
                    <a
                        className="pveInstructionLink"
                        href="https://discord.gg/TfBFUEvTrd"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Discord 2/3/Z
                    </a>{" "}
                    in the z-game thread.
                    <Alert className="pveRulesAlert" severity="error" sx={{ textAlign: "justify" }}>
                        If the “DEPOSIT” button is not active after connecting your wallet, you need to start a new PvE
                        game (click the “NEW ZOMBIE” button) and wait for a new zombie to appear, and then make a
                        withdrawal.
                    </Alert>
                </div>
                <div className="pveInstructionSubtitle">3. Game choice</div>
                <div className="pveRulesItem pveInstructionItem">
                    <div>There are currently 2 modes of play:</div>
                    <ul className="pveInstructionList">
                        <li>
                            <Link className="pveInstructionLink" to="/docs/instructionPve">
                                PvE
                            </Link>
                        </li>
                        <li>
                            <Link className="pveInstructionLink" to="/docs/instructionBrainFarm">
                                Brain Farm
                            </Link>
                        </li>
                    </ul>
                    <div>Detailed description of each game you can see by clicking on the links above.</div>
                </div>
                <div className="pveInstructionSubtitle">4. Withdrawal</div>
                <div className="pveRulesItem pveInstructionItem">
                    There are 2 types of withdrawals: with commission and without commission. Withdrawal without
                    commission is available 24 hours after the last deposit or the last withdrawal. In the withdrawal
                    block you can see the time counter, how much time you have left to wait for the withdrawal without
                    commission. Withdrawal with commission is available at any time, the commission is 5% of the
                    withdrawal amount. Also, withdrawal is possible only to the same wallet from which the deposit was
                    made. If you lose access to your wallet, you will not be able to withdraw funds. At the moment there
                    is a limitation on withdrawal of funds. Withdrawal limits can be found on the “Payments” page in the
                    “Withdraw” block. During beta testing the limit will be extended.
                </div>
                <Alert className="pveRulesAlert" severity="error" sx={{ textAlign: "justify" }}>
                    If the “WITHDRAW” button is not active after connecting your wallet, you need to start a new PvE
                    game (click the “NEW ZOMBIE” button) and wait for a new zombie to appear, and then make a
                    withdrawal.
                </Alert>
            </div>
        </Container>
    );
};

export default Instruction;
