import "./MobileMessage.scss";

const MobileMessage = () => {
    return (
        <div className="pveMobileMessageWrapper">
            <div className="pveMobileMessageText">
                The game is only available on devices with a screen width
                greater than 1000 px!
            </div>
            ;
        </div>
    );
};

export default MobileMessage;
