import "./Instruction.scss";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Container } from "@mui/material";

const InstructionPve = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Container>
            <div className="pveRulesTitle">PvE instruction</div>

            <div className="pveRulesWrapper">
                <div className="pveInstructionSubtitle">1. Choice of zombie in the game</div>
                <div className="pveRulesItem pveInstructionItem">
                    Zombies are chosen randomly (your zombie and your opponent) from a database of 235,000 zombies.
                    Currently there are only 200 zombie images, so images will be repeated. After registering, you will
                    be matched with a random zombie for the game. Later on, to start a new battle and pick a new random
                    zombie, you will need to click on the “NEW ZOMBIE” button, the button will become active. At this
                    point you cannot “skip” a zombie and pick up another without completing the game.
                </div>
                <div className="pveInstructionSubtitle">2. Your zombie card (located on the left)</div>
                <div className="pveRulesItem pveInstructionItem">
                    The zombie card shows its name, rarity, characteristics and the odds for the bet. If you see your
                    zombie, you need to select a battle type (in the center of the screen). The battle type is selected
                    based on the zombie's strong characteristic.
                </div>
                <div className="pveInstructionSubtitle">3. Betting</div>
                <div className="pveRulesItem pveInstructionItem">
                    To start a battle, you need to place a bet. The betting odds are indicated on your zombie's card and
                    depend on its rarity. At the moment the bet limit is limited, but it will be expanded as beta
                    testing progresses. You can bet no less than 1 ZOMB and no more than the set limit. In the “Your
                    Bet” block you can set your bet using the “+”, “-” buttons or by entering a value from the keyboard.
                    In the center of the screen for convenience you can see your balance and possible reward in case of
                    winning. In case of a loss, the bet amount is not refunded.
                </div>
                <div className="pveInstructionSubtitle">4. The course of the battle</div>
                <div className="pveRulesItem pveInstructionItem">
                    After selecting the type of battle and bet, press the “PLAY!” button. After that, the opponent will
                    be randomly selected and the battle will start. At the top of the game screen you will see the
                    points that each zombie has scored. The points are calculated according to the formula: <br />
                    <img src="/img/pve-formula.jpg" alt="pve formula walken" className="pveInstructionImg" /> <br />
                    When the battle is over, you will see a victory or defeat message, and the “NEW ZOMBIE” button will
                    become active to start a new game. The zombie with the most points wins.
                </div>
            </div>
        </Container>
    );
};

export default InstructionPve;
