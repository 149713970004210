import "./Withdraw.scss";
import { baseUrl } from "../axios/axios";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { setWithdrawBalance, setPlayAllowed } from "../../store/userSlice";
import { setProcessPlay } from "../../store/gameProcessSlice";

import { useWeb3React } from "@web3-react/core";
import { connectors } from "../../config/connectors";
import { formatAddress } from "../utils/formatAddress";

import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";

import ProgressCountdown from "../progressCountdown/ProgressCountdown";

const Withdraw = () => {
    const user = useSelector((state) => state.user.user);
    const processPlay = useSelector((state) => state.gameProcess.processPlay);

    const [withdraw, setWithdraw] = useState(0);
    const [loadingWithdraw, setLoadingWithdraw] = useState(false);
    const [disableInput, setDisableInput] = useState(false);
    const [error, setError] = useState("");
    const [signature, setSignature] = useState("");

    const dispatch = useDispatch();

    const { account, activate, deactivate } = useWeb3React();

    // set axios request
    const withdrawRequest = axios.create({
        withCredentials: true,
        baseURL: baseUrl,
    });
    withdrawRequest.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
            "token"
        )}`;
        return config;
    });
    withdrawRequest.interceptors.response.use(
        (config) => {
            return config;
        },
        async (error) => {
            const originalRequest = error.config;

            if (
                error.response.status === 401 &&
                error.config &&
                !error.config._isRetry
            ) {
                originalRequest._isRetry = true;
                axios
                    .get(`${baseUrl}/refresh`, {
                        withCredentials: true,
                    })
                    .then((res) => {
                        localStorage.setItem("token", res.data.accessToken);

                        withdrawRequest
                            .request(originalRequest)
                            .then((resWithdraw) => {
                                // console.log(resWithdraw.data);
                                dispatch(setWithdrawBalance(resWithdraw.data));
                                setSignature(resWithdraw.data.signature);
                            })
                            .catch((e) => {
                                setError(e.response?.data?.message);
                                console.log(e.response?.data?.message);
                            })
                            .finally(() => {
                                setLoadingWithdraw(false);
                                setDisableInput(false);
                                dispatch(setProcessPlay(false));
                            });
                    })
                    .catch((e) => {
                        console.log("You not authorize");
                    });
            } else {
                throw error;
            }
        }
    );

    useEffect(() => {
        const provider = window.localStorage.getItem("provider");
        if (provider) {
            activate(connectors[provider]);
        }
    }, []);

    const disconnect = () => {
        window.localStorage.setItem("provider", undefined);
        deactivate();
    };

    const connectWallet = () => {
        activate(connectors.injected);
        window.localStorage.setItem("provider", "injected");
    };

    const changeWithdraw = (e) => {
        const value = e.target.value;
        const split = value.split(".");

        if (
            (Number(value) || Number(value) === 0) &&
            split.length > 1 &&
            Number(value) <= user.balance &&
            Number(value) <= user.maxWithdraw
        ) {
            setWithdraw(value);
        } else if (
            (Number(value) || Number(value) === 0) &&
            Number(value) <= user.balance &&
            Number(value) <= user.maxWithdraw
        ) {
            setWithdraw(value);
        } else {
            setWithdraw("");
        }
    };

    const max = () => {
        if (user.balance <= user.maxWithdraw) {
            setWithdraw(user.balance);
        } else {
            setWithdraw(user.maxWithdraw);
        }
    };

    const count = new Date(user.lastTimeTransaction); // milliseconds
    const currentDate = new Date();
    let noFee = false;
    if (currentDate > count) {
        noFee = true;
    }

    const receive = noFee
        ? Math.round(withdraw * 100) / 100
        : Math.round(withdraw * 0.95 * 100) / 100;
    // const newBalance = user.balance - withdraw;

    // const { publicKey } = useWallet();

    const onWithdraw = async () => {
        setError("");

        if (withdraw < user.minWithdraw) {
            setError("The withdrawal amount is less than the minimum.");
            return;
        }
        if (withdraw > user.maxWithdraw) {
            setError("The withdrawal amount is more than the maximum.");
            return;
        }
        if (withdraw > user.balance) {
            setError(
                "The withdrawal amount is greater than the available balance."
            );
            return;
        }
        if (account.toLowerCase() !== user.userWalletAddress) {
            setError(
                "The connected wallet does not correspond to the wallet from which the deposit was made."
            );
            return;
        }

        setDisableInput(true);
        setLoadingWithdraw(true);

        dispatch(setProcessPlay(true));
        dispatch(setPlayAllowed(false));

        const data = {};
        data.withdrawAmountNoFee = withdraw;
        data.withdrawAmountFee = receive;
        data.email = user.userEmail;
        data.userAddress = account;

        withdrawRequest
            .post("/withdraw", { data })
            .then((res) => {
                if (res !== undefined) {
                    console.log(res);
                    dispatch(setWithdrawBalance(res.data));
                    setSignature(res.data.signature);
                    setLoadingWithdraw(false);
                    setDisableInput(false);
                    dispatch(setProcessPlay(false));
                }
            })
            .catch((e) => {
                setError(e.response?.data?.message);
                console.log(e.response?.data?.message);
                dispatch(setProcessPlay(false));
            });
    };

    return (
        <div className="pveWithdrawWrapper">
            {/* <div className="pveWithdrawLine"></div> */}
            <div className="pveBalanceDepositTitle">Withdraw</div>
            <div className="pveWithdrawListWrapper smoothBackground">
                <div className="pveWithdrawList">
                    <div className="pveWithdrawListItem">
                        1. WITHOUT COMMISSION withdrawal is possible not more
                        than once every 24 hours or not earlier than 24 hours
                        after the last deposit.{" "}
                        <span>In other cases the commission is 5%.</span>
                    </div>
                    <div className="pveWithdrawListItem">
                        2. Withdrawal is possible only to the same wallet from
                        which the deposit was made. If you lose access to your
                        wallet, you will not be able to withdraw funds.
                    </div>
                    <div className="pveWithdrawListItem">
                        3. At the moment the{" "}
                        <span>
                            withdrawal limit is {user.minWithdraw} -{" "}
                            {user.maxWithdraw} {user.tokenSymbol}
                        </span>
                    </div>
                </div>
            </div>

            {account ? (
                <>
                    <LoadingButton
                        className="pveBalanceBtnConnect pveBalanceBtnDisconnect"
                        variant="contained"
                        onClick={disconnect}
                    >
                        Disconnect
                    </LoadingButton>
                    <div className="pveBalanceAddress pveWithdrawAddress">
                        Connected: {formatAddress(account)}
                    </div>
                </>
            ) : (
                <LoadingButton
                    className="pveBalanceBtnConnect"
                    variant="contained"
                    onClick={connectWallet}
                >
                    Connect wallet
                </LoadingButton>
            )}

            <div className="pveWithdrawBalance">
                Your game balance: <span>{user.balance}</span>{" "}
                <span className="pveWithdrawBalanceTokenSymbol">
                    {user.tokenSymbol}
                </span>
            </div>

            {/* input and buttons */}
            <div className="pveBalanceDepositWrapper pveWithdrawFormWrapper">
                <FormControl
                    variant="standard"
                    className={
                        !disableInput
                            ? "pveBalanceAmountDeposit"
                            : "pveBalanceAmountDepositDisable"
                    }
                >
                    <Input
                        id="deposit-amount"
                        className={
                            !disableInput
                                ? "pveBalanceDepositInput"
                                : "pveBalanceDepositInputDisable"
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                {user.tokenSymbol}
                            </InputAdornment>
                        }
                        aria-describedby="deposit-amount-helper-text"
                        inputProps={{
                            "aria-label": "withdraw amount",
                        }}
                        type="text"
                        onChange={(e) => changeWithdraw(e)}
                        value={withdraw}
                        disabled={disableInput}
                    />
                    <FormHelperText id="deposit-amount-helper-text">
                        Withdraw amount
                    </FormHelperText>
                </FormControl>
                <LoadingButton
                    className="pveWithdrawMaxBtn"
                    variant="contained"
                    disabled={loadingWithdraw}
                    onClick={max}
                    color="gold"
                >
                    MAX
                </LoadingButton>
                <LoadingButton
                    className="pveBalanceBtnDeposit"
                    variant="contained"
                    disabled={!account || processPlay}
                    loading={loadingWithdraw}
                    onClick={onWithdraw}
                >
                    Withdraw
                </LoadingButton>
            </div>

            {error !== "" ? (
                <div className="pveBalanceErrorMessage">{error}</div>
            ) : null}
            {signature !== "" ? (
                <>
                    <div className="pveWithdrawNoFee">
                        The transaction was successful.
                    </div>
                    <a
                        href={`${process.env.REACT_APP_BLOCKCHAIN_EXPLORER}${signature}`}
                        target="_blank"
                        rel="noreferrer"
                        className="pveWithdrawLinkSolscan"
                    >
                        View in explorer
                    </a>
                </>
            ) : null}

            <div className="pveWithdrawSubtitle">You'll receive:</div>
            <div className="pveWithdrawReceive">
                {receive}{" "}
                <span className="pveWithdrawReceiveTokenSymbol">
                    {user.tokenSymbol}
                </span>
            </div>

            <div className="pveWithdrawSubtitle">No fee through:</div>
            {noFee ? (
                user.lastTimeTransaction !== 0 ? (
                    <div className="pveWithdrawNoFee">
                        Withdrawal without commission available!
                    </div>
                ) : (
                    <div className="pveWithdrawNoFee textRed">
                        The counter is activated after the deposit is made.
                    </div>
                )
            ) : (
                <ProgressCountdown
                    base={moment().toDate()}
                    hideBar={true}
                    faucet={false}
                    deadline={count} // milliseconds
                />
            )}
        </div>
    );
};

export default Withdraw;
