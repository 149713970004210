import "./Bet.scss";
import { useSelector, useDispatch } from "react-redux";

import { setBet } from "../../store/gameProcessSlice";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const Bet = (props) => {
    const { newUserCat, gameProcess } = props;

    const user = useSelector((state) => state.user.user);
    const newCatBtn = useSelector((state) => state.gameProcess.newCatBtn);
    const playBtn = useSelector((state) => state.gameProcess.playBtn);
    const bet = useSelector((state) => state.gameProcess.bet);
    const disableBet = useSelector((state) => state.gameProcess.disableBet);
    const processPlay = useSelector((state) => state.gameProcess.processPlay);

    const dispatch = useDispatch();

    const changeBet = (e) => {
        if (e.target.value < user.minBet) {
            dispatch(setBet(e.target.value.replace(/[^0-9]/g, "")));
        } else if (e.target.value >= user.maxBet) {
            dispatch(setBet(Number(user.maxBet)));
        } else {
            dispatch(setBet(Number(e.target.value)));
        }
    };

    const minusBet = () => {
        if (bet <= user.minBet) {
            dispatch(setBet(user.minBet));
            return;
        }
        dispatch(setBet(Number(bet) - 1));
    };
    const plusBet = () => {
        if (bet >= user.maxBet) {
            dispatch(setBet(user.maxBet));
            return;
        }
        dispatch(setBet(Number(bet) + 1));
    };
    const x2Bet = () => {
        const result = bet * 2;
        if (result > user.maxBet) {
            return;
        } else {
            dispatch(setBet(result));
        }
    };

    return (
        <div className="pveBetWrapper">
            <div className="pveBetBtnWrapper">
                <Button
                    className="pveBetBtn pveBetBtnNew"
                    variant="contained"
                    endIcon={<AutorenewIcon />}
                    onClick={newUserCat}
                    disabled={newCatBtn}
                >
                    New zombie
                </Button>
                <Button
                    className="pveBetBtn"
                    variant="contained"
                    color="success"
                    onClick={gameProcess}
                    disabled={playBtn || processPlay || !user.userPlayAllowed}
                >
                    Play!
                </Button>
            </div>

            <div className="pveBetTitle">
                Your bet, <span>{user.tokenSymbol}</span>
            </div>
            <div className="pveBetControlWrapper">
                <div
                    className={
                        !disableBet
                            ? "pveBetLimits"
                            : "pveBetLimits pveBetLimitsDisable"
                    }
                >
                    {user.minBet
                        ? `Limits: ${user.minBet}-${user.maxBet}`
                        : `Limits: error!`}
                </div>
                <Button
                    className="pveBetControlBtn"
                    variant="contained"
                    color="error"
                    onClick={minusBet}
                    disabled={disableBet}
                >
                    <RemoveIcon />
                </Button>
                {!disableBet ? (
                    <TextField
                        className="pveBetInput"
                        id="bet"
                        label="Your bet"
                        type="number"
                        onChange={(e) => changeBet(e)}
                        value={bet}
                    />
                ) : (
                    <TextField
                        className="pveBetInputDisabled"
                        id="disableBet"
                        label="Your bet"
                        type="number"
                        value={bet}
                        disabled={disableBet}
                    />
                )}
                <Button
                    className="pveBetControlBtn"
                    variant="contained"
                    color="success"
                    onClick={plusBet}
                    disabled={disableBet}
                >
                    <AddIcon />
                </Button>
                <Button
                    className="pveBetControlBtn pveBetControlX2"
                    variant="contained"
                    color="gold"
                    onClick={x2Bet}
                    disabled={disableBet}
                >
                    x2
                </Button>
            </div>
        </div>
    );
};

export default Bet;
