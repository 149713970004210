import "./Game.scss";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { baseUrl } from "../axios/axios";
import { useCountUp } from "react-countup";

import { setNewCat, setBattle } from "../../store/userSlice";
import {
    setNewCatBtn,
    setProcessPlay,
    setPlayBtn,
    setHideBalance,
    setDisableBet,
} from "../../store/gameProcessSlice";

import {
    commonIcon,
    uncommonIcon,
    rareIcon,
    epicIcon,
    legendaryIcon,
    uniqueIcon,
    strengthIcon,
    staminaIcon,
} from "../../assets/icons/rarityIcon";
import lightningImg from "../../assets/icons/lightning-white.png";
import Bet from "../bet/Bet";

import Container from "@mui/material/Container";
import Skeleton from "@mui/material/Skeleton";

const Game = () => {
    // discipline
    const [discipline, setDiscipline] = useState("");
    const [choosedDiscipline, setChoosedDiscipline] = useState("");
    const [loadingUserCat, setLoadingUserCat] = useState(false);
    const [enemyCat, setEnemyCat] = useState(false); // exist cat enemy
    const [urlUserCat, setUrlUserCat] = useState("none");
    const [urlEnemyCat, setUrlEnemyCat] = useState("none");
    const [balanceError, setBalanceError] = useState(false);
    const [errorBtlMsg, setErrorBtlMsg] = useState(""); // battle error message
    const [errorNewCatMsg, setErrorNewCatMsg] = useState(""); // new cat error message
    const [searchEnemy, setSearchEnemy] = useState(false);

    const dispatch = useDispatch();

    const user = useSelector((state) => state.user.user);
    const loading = useSelector((state) => state.authorization.loading);
    const bet = useSelector((state) => state.gameProcess.bet);
    const processPlay = useSelector((state) => state.gameProcess.processPlay);
    const hideBalance = useSelector((state) => state.gameProcess.hideBalance);

    const userBattleInfo = {};
    const userEmail = user.userEmail;

    // score battle
    const countUpRefUser = useRef(null);
    const countUpRefEnemy = useRef(null);

    // discipline img
    const strengthSmallIcon = "/img/star-slime-round.png";
    const staminaSmallIcon = "/img/heart-slime-round.png";
    const speedSmallIcon = "/img/lightning-slime-round.png";
    const strengthBigIcon = "/img/strength.png";
    const staminaBigIcon = "/img/stamina.png";
    const speedBigIcon = "/img/speed.png";
    // win/lose img
    const winImg = "/img/victory.png";
    const loseImg = "/img/defeat.png";
    const zombieHead = "/img/zombie-head.png";

    const zombieSilhouette = "/img/zombie-silhouette.png";

    // set axios request
    const gameRequest = axios.create({
        withCredentials: true,
        baseURL: baseUrl,
    });
    gameRequest.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
            "token"
        )}`;
        return config;
    });
    gameRequest.interceptors.response.use(
        (config) => {
            return config;
        },
        async (error) => {
            const originalRequest = error.config;

            if (
                error.response.status === 401 &&
                error.config &&
                !error.config._isRetry
            ) {
                originalRequest._isRetry = true;
                axios
                    .get(`${baseUrl}/refresh`, {
                        withCredentials: true,
                    })
                    .then((res) => {
                        localStorage.setItem("token", res.data.accessToken);

                        if (originalRequest.url === "/newCat") {
                            gameRequest
                                .request(originalRequest)
                                .then((resNewCat) => {
                                    dispatch(setNewCat(resNewCat.data));
                                })
                                .catch((e) => {
                                    console.log(e.response?.data?.message);
                                    setErrorNewCatMsg(
                                        e.response?.data?.message
                                    );
                                });
                        } else if (originalRequest.url === "/battle") {
                            dispatch(setPlayBtn(true));
                            dispatch(setProcessPlay(true));

                            gameRequest
                                .request(originalRequest)
                                .then((resBattle) => {
                                    // console.log(resBattle.data);
                                    dispatch(setBattle(resBattle.data));
                                    setEnemyCat(true);
                                })
                                .catch((e) => {
                                    console.log(e.response?.data?.message);
                                    setErrorBtlMsg(e.response?.data?.message);
                                    dispatch(setPlayBtn(false));
                                    setEnemyCat(false);
                                    setUrlEnemyCat("none");
                                    dispatch(setProcessPlay(false));
                                });
                        }
                    })
                    .catch((e) => {
                        console.log("You not authorize");
                    });
            } else {
                throw error;
            }
        }
    );

    useEffect(() => {
        if (user.cat) {
            setLoadingUserCat(true);

            setUrlUserCat(user.cat.image);

            setLoadingUserCat(false);

            // functional for download image from another site
            // fetch(user.cat.image)
            //     .then((res) => res.blob())
            //     .then((image) => {
            //         setUrlUserCat(URL.createObjectURL(image));
            //     })
            //     .catch((e) => {
            //         setUrlUserCat("none");
            //         console.log(e.response?.data?.message);
            //     })
            //     .finally(() => {
            //         setLoadingUserCat(false);
            //     });
        }

        if (processPlay) {
            if (user.enemy) {
                setSearchEnemy(true);

                setUrlEnemyCat(user.enemy.image);

                setSearchEnemy(false);
                animateBattle();

                // functional for download image from another site
                // fetch(user.enemy.image)
                //     .then((res) => res.blob())
                //     .then((image) => {
                //         setUrlEnemyCat(URL.createObjectURL(image));
                //     })
                //     .catch((e) => {
                //         setUrlEnemyCat("error");
                //         console.log(e.response?.data?.message);
                //     })
                //     .finally(() => {
                //         setSearchEnemy(false);
                //         animateBattle();
                //     });
            }
        }
    }, [user]);

    // calculate user cat score
    if (choosedDiscipline !== "") {
        userBattleInfo.discipline = choosedDiscipline;

        if (choosedDiscipline === "Strength") {
            userBattleInfo.sumScore =
                ((user.cat.strength +
                    user.cat.stamina * 0.5 +
                    user.cat.speed * 0.5) *
                    100) /
                100;
        } else if (choosedDiscipline === "Stamina") {
            userBattleInfo.sumScore =
                ((user.cat.strength * 0.25 +
                    user.cat.stamina +
                    user.cat.speed * 0.25) *
                    100) /
                100;
        } else if (choosedDiscipline === "Speed") {
            userBattleInfo.sumScore =
                ((user.cat.strength * 0.5 +
                    user.cat.stamina * 0.25 +
                    user.cat.speed) *
                    100) /
                100;
        }
    }

    const counterUser = useCountUp({
        ref: countUpRefUser,
        start: 0,
        end: userBattleInfo.sumScore,
        decimals: 2,
        duration: 3,
    });
    const counterEnemy = useCountUp({
        ref: countUpRefEnemy,
        start: 0,
        end: enemyCat ? user.enemyScore : 0,
        decimals: 2,
        duration: 3,
    });

    const newUserCat = async () => {
        resetEndBattle();
        dispatch(setNewCatBtn(true));
        setLoadingUserCat(true);
        setUrlUserCat("");
        setErrorBtlMsg("");
        setErrorNewCatMsg("");
        gameRequest
            .post("/newCat", { userEmail })
            .then((res) => {
                // console.log(res.data);
                if (res !== undefined) {
                    dispatch(setNewCat(res.data));
                }
            })
            .catch((e) => {
                console.log(e.response?.data?.message);
                setErrorNewCatMsg(e.response?.data?.message);
            });
    };

    // function for game (after press play button)
    const gameProcess = async () => {
        dispatch(setDisableBet(true));
        dispatch(setPlayBtn(true));
        setBalanceError(false);
        setSearchEnemy(true);
        dispatch(setProcessPlay(true));
        dispatch(setHideBalance(true));
        setErrorBtlMsg("");

        const balance = document.querySelector(".pveRaceBalanceText");
        balance.classList.remove("show");
        balance.classList.add("hide");

        if (bet > user.balance) {
            setBalanceError(true);
            dispatch(setPlayBtn(false));
            setSearchEnemy(false);
            return;
        }

        if (typeof bet !== "number") {
            setErrorBtlMsg(
                "The rate is entered incorrectly. Enter a value within the betting limit."
            );
            dispatch(setDisableBet(false));
            setSearchEnemy(false);
            dispatch(setPlayBtn(false));
            setEnemyCat(false);
            setUrlEnemyCat("none");
            dispatch(setProcessPlay(false));
            return;
        }

        const data = {};
        data.discipline = userBattleInfo.discipline;
        data.sumScore = userBattleInfo.sumScore;
        data.bet = bet;
        data.email = user.userEmail;
        data.userCatRarity = user.cat.rarity;

        if (data.discipline === "Strength") {
            data.value = user.cat.strength;
        } else if (data.discipline === "Stamina") {
            data.value = user.cat.stamina;
        } else if (data.discipline === "Speed") {
            data.value = user.cat.speed;
        }

        gameRequest
            .post("/battle", { data })
            .then((res) => {
                // console.log(res.data);
                if (res !== undefined) {
                    dispatch(setBattle(res.data));
                    setEnemyCat(true);
                }
            })
            .catch((e) => {
                console.log(e.response?.data?.message);
                setErrorBtlMsg(e.response?.data?.message);
                dispatch(setPlayBtn(false));
                setEnemyCat(false);
                setUrlEnemyCat("none");
                dispatch(setProcessPlay(false));
            });
    };

    // icon in progress bar
    const progressSmallIcon = () => {
        let icon = "/img/green-slime-round.png";
        // let icon = "/img/green-hexagon.png";

        if (discipline === "Strength" || choosedDiscipline !== "") {
            icon = strengthSmallIcon;
        } else if (discipline === "Stamina" || choosedDiscipline !== "") {
            icon = staminaSmallIcon;
        } else if (discipline === "Speed" || choosedDiscipline !== "") {
            icon = speedSmallIcon;
        }

        if (choosedDiscipline === "Strength") {
            icon = strengthSmallIcon;
        } else if (choosedDiscipline === "Stamina") {
            icon = staminaSmallIcon;
        } else if (choosedDiscipline === "Speed") {
            icon = speedSmallIcon;
        }

        return icon;
    };

    let ratio = 0;
    let reward = 0;
    let expectedBalance = 0;
    const catRarity = (rarity, userCat = false) => {
        let icon = null;
        let color = null;

        switch (rarity) {
            case "Common":
                icon = commonIcon;
                color = { color: "rgb(178, 183, 190)" };
                if (userCat) {
                    ratio = user.ratioCommon;
                }
                break;
            case "Uncommon":
                icon = uncommonIcon;
                color = { color: "rgb(129, 194, 89)" };
                if (userCat) {
                    ratio = user.ratioUncommon;
                }
                break;
            case "Rare":
                icon = rareIcon;
                color = { color: "rgb(71, 172, 243)" };
                if (userCat) {
                    ratio = user.ratioRare;
                }
                break;
            case "Epic":
                icon = epicIcon;
                color = { color: "rgb(130, 109, 212)" };
                if (userCat) {
                    ratio = user.ratioEpic;
                }
                break;
            case "Legendary":
                icon = legendaryIcon;
                color = { color: "rgb(255, 184, 47)" };
                if (userCat) {
                    ratio = user.ratioLegendary;
                }
                break;
            case "Unique":
                icon = uniqueIcon;
                color = { color: "rgb(241, 86, 76)" };
                if (userCat) {
                    ratio = user.ratioLegendary;
                }
                break;
            default:
                icon = null;
                color = { color: "rgb(178, 183, 190)" };
                break;
        }
        if (userCat) {
            reward = Math.round(bet * ratio * 100) / 100;
            expectedBalance =
                Math.round((user.balance + reward - bet) * 100) / 100;
        }

        return (
            <div className="pveRaceTitleValue pveRaceRarity">
                Rarity: {icon} <span style={color}>{rarity}</span>
            </div>
        );
    };

    // discipline
    const disciplineOver = (e) => {
        // not going play process
        if (!processPlay) {
            e.target.classList.remove("pveGrayscaleOn");
            e.target.classList.add("pveGrayscaleOff");

            const discipline = e.target.getAttribute("data-discipline");
            setDiscipline(discipline);
        }
    };
    const disciplineOut = (e) => {
        const currentDiscipline = e.target.getAttribute("data-discipline");
        // remove all on current element
        e.target.classList.remove("pveGrayscaleOff");
        e.target.classList.add("pveGrayscaleOn");
        setDiscipline("");
        // add classes on choosed element
        if (
            choosedDiscipline.length > 0 &&
            currentDiscipline === choosedDiscipline
        ) {
            e.target.classList.remove("pveGrayscaleOn");
            e.target.classList.add("pveGrayscaleOff");
        }
    };
    const chooseDiscipline = (e) => {
        // not going play process
        if (!processPlay) {
            // remove all classes on all elements
            const allDisciplines =
                document.querySelectorAll("[data-discipline]");
            allDisciplines.forEach((item) => {
                item.classList.remove("pveGrayscaleOff");
                item.classList.add("pveGrayscaleOn");
                setDiscipline("");
            });
            // set discipline in state
            const discipline = e.target.getAttribute("data-discipline");
            setChoosedDiscipline(discipline);
            // add classes for current element
            e.target.classList.remove("pveGrayscaleOn");
            e.target.classList.add("pveGrayscaleOff");

            if (user.balance > user.minBet) {
                dispatch(setPlayBtn(false));
            } else {
                return;
            }
        }
    };

    // animate battle
    const animateBattle = () => {
        setTimeout(() => {
            animateFunc(userBattleInfo.sumScore, user.enemyScore);
        }, 1200);

        setTimeout(() => {
            if (userBattleInfo.sumScore > user.enemyScore) {
                const userWin = document.querySelector(".pveRaceRewardText");
                userWin.classList.remove("hide");
                userWin.classList.add("show");
            } else {
                const userLose = document.querySelector(".pveRaceLoseText");
                userLose.classList.remove("hide");
                userLose.classList.add("show");
            }

            dispatch(setNewCatBtn(false));
            dispatch(setHideBalance(false));

            dispatch(setProcessPlay(false));
        }, 5000);
    };
    // animate progress
    const animateFunc = (userScore, enemyScore) => {
        let percentUser = 0;
        let percentEnemy = 0;

        if (userScore > enemyScore) {
            percentUser = 100;
            percentEnemy =
                Math.round((enemyScore / (userScore / 100)) * 100) / 100;
        } else {
            percentEnemy = 100;
            percentUser =
                Math.round((userScore / (enemyScore / 100)) * 100) / 100;
        }

        const lineUser = document.querySelector(".pveProgressOuterLineUser");
        lineUser.style.cssText = "width: 0";
        lineUser.animate(
            [
                { width: "0", easing: "ease" },
                { width: `${percentUser}%`, easing: "ease" },
            ],
            {
                duration: 3000,
                delay: 0,
                iterations: 1,
                direction: "normal",
                fill: "forwards",
            }
        );

        const lineEnemy = document.querySelector(".pveProgressOuterLineEnemy");
        lineEnemy.style.cssText = "width: 0";
        lineEnemy.animate(
            [
                { width: "0", easing: "ease" },
                { width: `${percentEnemy}%`, easing: "ease" },
            ],
            {
                duration: 3000,
                delay: 0,
                iterations: 1,
                direction: "normal",
                fill: "forwards",
            }
        );

        counterUser.start();
        counterEnemy.start();
    };
    // reset end battle
    const resetEndBattle = () => {
        // hide message win/lose
        const userWin = document.querySelector(".pveRaceRewardText");
        userWin.classList.remove("show");
        userWin.classList.add("hide");
        const userLose = document.querySelector(".pveRaceLoseText");
        userLose.classList.remove("show");
        userLose.classList.add("hide");

        // show balance
        const balance = document.querySelector(".pveRaceBalanceText");
        balance.classList.remove("hide");
        balance.classList.add("show");

        // reset progress lines
        const progressLineUser = document.querySelector(
            ".pveProgressOuterLineUser"
        );
        progressLineUser.style.cssText = "width: 0 !important";
        const progressLineEnemy = document.querySelector(
            ".pveProgressOuterLineEnemy"
        );
        progressLineEnemy.style.cssText = "width: 0 !important";

        // reset score
        counterUser.reset();
        counterEnemy.reset();

        // reset discipline
        setChoosedDiscipline("");
        setDiscipline("");
        const disciplineBigIcon = document.querySelectorAll(
            ".pveRaceDisciplineIcon"
        );
        disciplineBigIcon.forEach((item) => {
            item.classList.remove("pveGrayscaleOff");
            item.classList.add("pveGrayscaleOn");
        });

        // reset enemy
        setSearchEnemy(false);
        setUrlEnemyCat("none");
        setEnemyCat(false);

        // reset play process parameter
        dispatch(setProcessPlay(false));

        // on bet
        dispatch(setDisableBet(false));
    };

    // ghost cat (instead spinner)
    const ghostCatUserAnimate = (
        <div
            className="pveRaceGhostCat pveRaceGhostUser"
            style={{ animation: "pulsate 0.5s ease-in-out infinite both" }}
        >
            <img src={zombieSilhouette} alt="zombie-silhouette" />
        </div>
    );
    const ghostCatEnemyAnimate = (
        <div
            className="pveRaceGhostCat"
            style={{ animation: "pulsate 0.5s ease-in-out infinite both" }}
        >
            <img src={zombieSilhouette} alt="zombie-silhouette" />
        </div>
    );
    const ghostCatUser = (
        <div className="pveRaceGhostCat pveRaceGhostUser">
            <img src={zombieSilhouette} alt="zombie-silhouette" />
            <div className="pveRaceGhostError">Failed to load image</div>
        </div>
    );
    const ghostCatEnemy = (
        <div className="pveRaceGhostCat">
            <img
                style={{ width: "300px", height: "300px" }}
                src={zombieSilhouette}
                alt="zombie-silhouette"
            />
        </div>
    );
    const ghostCatEnemyImgError = (
        <div className="pveRaceGhostCat">
            <img src={zombieSilhouette} alt="zombie-silhouette" />
            <div className="pveRaceGhostError">Failed to load image</div>
        </div>
    );

    const skeleton = (width, height) => {
        return (
            <Skeleton className="pveSkeleton" width={width} height={height} />
        );
    };

    return (
        <Container>
            {/* progress info */}
            <div className="pveProgressWrapper">
                <img
                    src={progressSmallIcon()}
                    alt="small icon discipline"
                    className="pveProgressSmallIcon"
                />
                <div className="pveProgressBackgroundLine pveProgressBackgroundLineEnemy">
                    <div className="pveProgressOuterLine pveProgressOuterLineEnemy"></div>
                </div>
                <div className="pveProgressBackgroundLine pveProgressBackgroundLineUser">
                    <div className="pveProgressOuterLine pveProgressOuterLineUser"></div>
                </div>
                <div
                    className="pveProgressValue pveProgressValueUser"
                    ref={countUpRefUser}
                ></div>
                <div
                    className="pveProgressValue pveProgressValueEnemy"
                    ref={countUpRefEnemy}
                ></div>
                <div className="pveProgressDisciplineName">
                    {choosedDiscipline.length > 0
                        ? choosedDiscipline
                        : discipline}
                </div>
            </div>

            {/* race and cats info */}
            <div className="pveRaceWrapper">
                {/* user cat */}
                <div className="pveRaceCat">
                    <div className="pveRaceCatImgWrapper">
                        {loadingUserCat || loading ? (
                            ghostCatUserAnimate
                        ) : urlUserCat === "none" ? (
                            ghostCatUser
                        ) : (
                            <img
                                className="pveRaceCatImg"
                                src={urlUserCat}
                                alt="cathlete"
                            />
                        )}
                    </div>

                    <div className="pveRaceTitleValue pveRaceName">
                        Name:{" "}
                        <span>
                            {loadingUserCat || loading
                                ? skeleton(80)
                                : user.cat.name}
                        </span>
                    </div>
                    {loadingUserCat || loading
                        ? skeleton(200)
                        : catRarity(user.cat.rarity, true)}
                    <div className="pveRaceValue">
                        <div className="pveRaceValueStrength">
                            {strengthIcon}
                        </div>
                        <div className="pveRaceValueWrapper">
                            <div className="pveRaceValueName">Strength</div>
                            <div className="pveRaceValueNumStrength">
                                {loadingUserCat || loading
                                    ? skeleton(50)
                                    : user.cat.strength}
                            </div>
                        </div>
                    </div>
                    <div className="pveRaceValue">
                        <div className="pveRaceValueStamina">{staminaIcon}</div>
                        <div className="pveRaceValueWrapper">
                            <div className="pveRaceValueName">Stamina</div>
                            <div className="pveRaceValueNumStamina">
                                {loadingUserCat || loading
                                    ? skeleton(50)
                                    : user.cat.stamina}
                            </div>
                        </div>
                    </div>
                    <div className="pveRaceValue">
                        <div className="pveRaceValueSpeed">
                            <img
                                className="pveRaceIconLightningImg"
                                src={lightningImg}
                                alt="lightning"
                            />
                        </div>
                        <div className="pveRaceValueWrapper">
                            <div className="pveRaceValueName">Speed</div>
                            <div className="pveRaceValueNumSpeed">
                                {loadingUserCat || loading
                                    ? skeleton(50)
                                    : user.cat.speed}
                            </div>
                        </div>
                    </div>
                    <div className="pveRaceTitleValue pveRaceName pveRaceRatio">
                        Ratio:
                    </div>
                    <div className="pveRaceTitleValue pveRaceName pveRaceRatioColor">
                        {ratio}
                    </div>
                </div>

                {/* progress and discipline */}
                <div className="pveRaceDisciplineWrapper">
                    <div className="pveRaceDisciplineTitle">
                        Choose type of battle
                    </div>
                    <div className="pveRaceDisciplineIconWrapper">
                        <img
                            src={strengthBigIcon}
                            alt="discipline icon"
                            className="pveRaceDisciplineIcon pveGrayscaleOn"
                            data-discipline="Strength"
                            onMouseOver={(e) => disciplineOver(e)}
                            onMouseOut={(e) => disciplineOut(e)}
                            onClick={(e) => chooseDiscipline(e)}
                        />
                        <img
                            src={staminaBigIcon}
                            alt="discipline icon"
                            className="pveRaceDisciplineIcon pveGrayscaleOn"
                            data-discipline="Stamina"
                            onMouseOver={(e) => disciplineOver(e)}
                            onMouseOut={(e) => disciplineOut(e)}
                            onClick={(e) => chooseDiscipline(e)}
                        />
                        <img
                            src={speedBigIcon}
                            alt="discipline icon"
                            className="pveRaceDisciplineIcon pveGrayscaleOn"
                            data-discipline="Speed"
                            onMouseOver={(e) => disciplineOver(e)}
                            onMouseOut={(e) => disciplineOut(e)}
                            onClick={(e) => chooseDiscipline(e)}
                        />
                    </div>

                    <div className="pveBalanceTitle pveRaceBalanceText show">
                        <div className="pveRaceBalance">Balance:</div>
                        <div className="pveBalanceValue pveRaceBalanceValue">
                            <span>
                                {hideBalance
                                    ? skeleton(70)
                                    : Math.round(user.balance * user.decimal) /
                                      user.decimal}
                            </span>{" "}
                            <span className="pveRaceBalanceTokenSymbol">
                                {user.tokenSymbol}
                            </span>
                        </div>

                        <div className="pveBetRewardTitle">
                            Reward,{" "}
                            <span className="pveRaceBalanceTokenSymbol">
                                {user.tokenSymbol}
                            </span>
                        </div>
                        <div className="pveBetReward pveBetRewardVictory">
                            Victory: {`${reward} (${expectedBalance})`}
                        </div>
                    </div>
                    <div className="pveRaceRewardText hide">
                        <div className="pveRaceWinText">
                            You win! Your reward:
                        </div>
                        <div className="pveRaceWinText pveRaceWinReward">
                            {!hideBalance ? reward : 0}
                        </div>
                        <img
                            src={winImg}
                            alt="victory"
                            className="pveRaceWinImg"
                        />
                        <div className="pveRaceBattleId pveRaceBattleIdWin">
                            Battle ID: {user.battleId}
                        </div>
                    </div>
                    <div className="pveRaceLoseText hide">
                        <img
                            src={loseImg}
                            alt="defeated"
                            className="pveRaceLoseImg"
                        />
                        <div className="pveRaceLoseContentText1">You lose.</div>
                        <img
                            src={zombieHead}
                            alt="zombie head"
                            className="pveRaceLoseImgHead"
                        />
                        <div className="pveRaceLoseContentText2">
                            Don't feel bad, try again!
                        </div>
                        <div className="pveRaceBattleId pveRaceBattleIdLose">
                            Battle ID: {user.battleId}
                        </div>
                    </div>
                    <div
                        className={
                            balanceError
                                ? "pveRaceBalanceError show"
                                : "pveRaceBalanceError hide"
                        }
                    >
                        <div className="pveRaceBalanceErrorText">
                            Error! Your balance is less than your bet. Reload
                            the page and refill your balance or reduce your bet.
                        </div>
                    </div>
                    <div
                        className={
                            errorBtlMsg !== ""
                                ? "pveRaceBalanceError show"
                                : "pveRaceBalanceError hide"
                        }
                    >
                        <div className="pveRaceBalanceErrorText">
                            {errorBtlMsg !== "" && errorBtlMsg !== undefined
                                ? errorBtlMsg
                                : "Unknown error... Try again later."}
                        </div>
                    </div>
                    <div
                        className={
                            errorNewCatMsg !== ""
                                ? "pveRaceBalanceError show"
                                : "pveRaceBalanceError hide"
                        }
                    >
                        <div className="pveRaceBalanceErrorText pveRaceBalanceErrorTextCat">
                            {errorNewCatMsg}
                        </div>
                    </div>
                </div>

                {/* enemy cat */}
                <div className="pveRaceCat">
                    <div className="pveRaceCatImgWrapper">
                        {searchEnemy ? (
                            ghostCatEnemyAnimate
                        ) : urlEnemyCat === "none" ? (
                            ghostCatEnemy
                        ) : urlEnemyCat === "error" ? (
                            ghostCatEnemyImgError
                        ) : (
                            <img
                                className="pveRaceCatImg pveRaceCatImgEnemy"
                                src={urlEnemyCat}
                                alt="cathlete"
                            />
                        )}
                    </div>

                    <div className="pveRaceTitleValue pveRaceName">
                        Name:{" "}
                        <span>
                            {searchEnemy
                                ? "-"
                                : enemyCat
                                ? user.enemy.name
                                : "-"}
                        </span>
                    </div>

                    {searchEnemy
                        ? catRarity("-")
                        : enemyCat
                        ? catRarity(user.enemy.rarity)
                        : catRarity("-")}

                    <div className="pveRaceValue">
                        <div className="pveRaceValueStrength">
                            {strengthIcon}
                        </div>
                        <div className="pveRaceValueWrapper">
                            <div className="pveRaceValueName">Strength</div>
                            <div className="pveRaceValueNumStrength">
                                {searchEnemy
                                    ? "-"
                                    : enemyCat
                                    ? user.enemy.strength
                                    : "-"}
                            </div>
                        </div>
                    </div>
                    <div className="pveRaceValue">
                        <div className="pveRaceValueStamina">{staminaIcon}</div>
                        <div className="pveRaceValueWrapper">
                            <div className="pveRaceValueName">Stamina</div>
                            <div className="pveRaceValueNumStamina">
                                {searchEnemy
                                    ? "-"
                                    : enemyCat
                                    ? user.enemy.stamina
                                    : "-"}
                            </div>
                        </div>
                    </div>
                    <div className="pveRaceValue">
                        <div className="pveRaceValueSpeed">
                            <img
                                className="pveRaceIconLightningImg"
                                src={lightningImg}
                                alt="lightning"
                            />
                        </div>
                        <div className="pveRaceValueWrapper">
                            <div className="pveRaceValueName">Speed</div>
                            <div className="pveRaceValueNumSpeed">
                                {searchEnemy
                                    ? "-"
                                    : enemyCat
                                    ? user.enemy.speed
                                    : "-"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Bet newUserCat={newUserCat} gameProcess={gameProcess} />
        </Container>
    );
};

export default Game;
