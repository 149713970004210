import "./Lottery.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../axios/axios";
import { updateTime } from "../utils/updateTime";

import { useSelector } from "react-redux";

import Skeleton from "@mui/material/Skeleton";
import Container from "@mui/material/Container";

import { Link } from "react-router-dom";

const Lottery = () => {
    const user = useSelector((state) => state.user.user);

    const [lotteryData, setLotteryData] = useState();
    const [loadingLotteryData, setLoadingLotteryData] = useState(true);
    const [errorLotteryData, setErrorLotteryData] = useState(false);

    // set axios request
    const lotteryRequest = axios.create({
        withCredentials: true,
        baseURL: baseUrl,
    });
    lotteryRequest.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
        return config;
    });
    lotteryRequest.interceptors.response.use(
        (config) => {
            return config;
        },
        async (error) => {
            const originalRequest = error.config;

            if (error.response.status === 401 && error.config && !error.config._isRetry) {
                originalRequest._isRetry = true;
                axios
                    .get(`${baseUrl}/refresh`, {
                        withCredentials: true,
                    })
                    .then((res) => {
                        localStorage.setItem("token", res.data.accessToken);

                        lotteryRequest
                            .request(originalRequest)
                            .then((resLottery) => {
                                // console.log(resLottery.data);
                                setLotteryData(resLottery.data);
                            })
                            .catch((e) => {
                                console.log(e.response?.data?.message);
                                setErrorLotteryData(true);
                            })
                            .finally(() => {
                                setLoadingLotteryData(false);
                            });
                    })
                    .catch((e) => {
                        console.log("You not authorize");
                    });
            } else {
                throw error;
            }
        }
    );

    const getLotteryData = async () => {
        setLoadingLotteryData(true);
        setErrorLotteryData(false);
        lotteryRequest
            .post("/usersLotteryData")
            .then((res) => {
                if (res !== undefined) {
                    setLotteryData(res.data);
                    // console.log(res.data);
                    setLoadingLotteryData(false);
                }
            })
            .catch((e) => {
                console.log(e.response?.data?.message);
                setErrorLotteryData(true);
                setLoadingLotteryData(false);
            });
    };

    useEffect(() => {
        getLotteryData();

        const timerIdLotteryData = setInterval(getLotteryData, 1800000); // 30 min (1800000)

        return () => {
            clearInterval(timerIdLotteryData);
        };
    }, []);

    // current lottery
    let colorNumberOfTickets = false;
    if (!loadingLotteryData) {
        if (lotteryData.currentLottery.tickets >= lotteryData.currentLottery.minTickets) {
            colorNumberOfTickets = true;
        }
        if (lotteryData.currentLottery.tickets === 0 && lotteryData.currentLottery.minTickets === 0) {
            colorNumberOfTickets = false;
        }
    }

    let colorNumberOfParticipants = false;
    if (!loadingLotteryData) {
        if (lotteryData.currentLottery.participants >= lotteryData.currentLottery.minParticipants) {
            colorNumberOfParticipants = true;
        }
        if (lotteryData.currentLottery.participants === 0 && lotteryData.currentLottery.minParticipants === 0) {
            colorNumberOfParticipants = false;
        }
    }

    // lottery history
    let colorNumberOfTicketsHistory = false;
    if (!loadingLotteryData) {
        if (lotteryData.lotteryHistory.tickets >= lotteryData.lotteryHistory.minTickets) {
            colorNumberOfTicketsHistory = true;
        }
        if (lotteryData.lotteryHistory.tickets === 0 && lotteryData.lotteryHistory.minTickets === 0) {
            colorNumberOfTicketsHistory = false;
        }
    }

    let colorNumberOfParticipantsHistory = false;
    if (!loadingLotteryData) {
        if (lotteryData.lotteryHistory.participants >= lotteryData.lotteryHistory.minParticipants) {
            colorNumberOfParticipantsHistory = true;
        }
        if (lotteryData.lotteryHistory.participants === 0 && lotteryData.lotteryHistory.minParticipants === 0) {
            colorNumberOfParticipantsHistory = false;
        }
    }

    let colorLotteryHistoryRaffled = false;
    if (!loadingLotteryData) {
        if (lotteryData.lotteryHistory.raffled) {
            colorLotteryHistoryRaffled = true;
        }
    }

    // count user's tickets
    let currentTurnover = "-";
    let nextTicket = "-";
    let linePercent = 0;
    if (user && lotteryData) {
        currentTurnover = user.turnover;
        nextTicket = (Math.floor(currentTurnover / lotteryData.turnoverForTicket) + 1) * lotteryData.turnoverForTicket;

        const resPercentLine = currentTurnover / lotteryData.turnoverForTicket;
        const arr = resPercentLine.toFixed(2).toString().split(".");

        if (Number(arr[1]) === 0) {
            linePercent = 0;
        } else if (
            arr[1] === "01" ||
            arr[1] === "02" ||
            arr[1] === "03" ||
            arr[1] === "04" ||
            arr[1] === "05" ||
            arr[1] === "06" ||
            arr[1] === "07" ||
            arr[1] === "08" ||
            arr[1] === "09"
        ) {
            linePercent = 1;
        } else {
            linePercent = Number(arr[1]);
        }
    }

    const skeleton = (width, height) => {
        return <Skeleton className="pveSkeleton" width={width} height={height} />;
    };

    return (
        <Container>
            <div className="pveLotteryWrapper">
                <div className="pveWithdrawLine"></div>
                <div className="pveBalanceDepositTitle">Lottery</div>
                <div className="pveLotteryDescriptionWrapper smoothBackground">
                    <div className="pveLotteryDescription">
                        Enter the weekly raffle and win! Tickets for the lottery are accumulated automatically depending
                        on your game. Read more about the terms and conditions{" "}
                        <Link className="pveLotteryRulesLink" to="/docs/lotteryRules">
                            here
                        </Link>
                        .
                    </div>
                </div>

                <div className="pveLotteryTitle">Current lottery draw</div>

                <div className="pveLotteryUpdate">
                    Latest update on tickets and participants:{" "}
                    {loadingLotteryData
                        ? skeleton(130)
                        : errorLotteryData
                        ? "error!"
                        : updateTime(lotteryData.currentLottery.lastUpdate)}
                </div>

                {/* current lottery */}
                <div className="pveLotteryCurrentWrapper">
                    <div className="pveLotteryCurrentItem">
                        <div className="pveLotteryCurrentTitle">#</div>
                        <div className="pveLotteryCurrentContent">
                            {loadingLotteryData
                                ? skeleton(30)
                                : errorLotteryData
                                ? "error!"
                                : lotteryData.currentLottery.numberOfLottery}
                        </div>
                    </div>
                    <div className="pveLotteryCurrentItem">
                        <div className="pveLotteryCurrentTitle">Start, UTC</div>
                        <div className="pveLotteryCurrentContent">
                            {loadingLotteryData
                                ? skeleton(110)
                                : errorLotteryData
                                ? "error!"
                                : updateTime(lotteryData.currentLottery.dateStart).slice(0, 16)}
                        </div>
                    </div>
                    <div className="pveLotteryCurrentItem">
                        <div className="pveLotteryCurrentTitle">Finish, UTC</div>
                        <div className="pveLotteryCurrentContent">
                            {loadingLotteryData
                                ? skeleton(110)
                                : errorLotteryData
                                ? "error!"
                                : updateTime(lotteryData.currentLottery.dateFinish).slice(0, 16)}
                        </div>
                    </div>
                    <div className="pveLotteryCurrentItem">
                        <div className="pveLotteryCurrentTitle">Prize pool</div>
                        <div className="pveLotteryCurrentContent">
                            <span className="pveLotteryCurrentPrize">
                                {loadingLotteryData ? (
                                    skeleton(70)
                                ) : errorLotteryData ? (
                                    "error!"
                                ) : lotteryData.currentLottery.sumReward !== 0 ? (
                                    <>
                                        <span>{lotteryData.currentLottery.sumReward}</span>{" "}
                                        <span className="pveLotteryCurrentPrizePoolTokenSymbol">
                                            {user.tokenSymbol}
                                        </span>
                                    </>
                                ) : (
                                    "soon..."
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="pveLotteryCurrentItem">
                        <div className="pveLotteryCurrentTitle">Turnover per ticket</div>
                        <div className="pveLotteryCurrentContent">
                            <span className="pveLotteryCurrentPrize">
                                {loadingLotteryData
                                    ? skeleton(30)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryData.turnoverForTicket}{" "}
                                <span className="pveLotteryCurrentPrizePoolTokenSymbol">{user.tokenSymbol}</span>
                            </span>
                        </div>
                    </div>
                    <div className="pveLotteryCurrentItem">
                        <div className="pveLotteryCurrentTitle">Number of tickets</div>
                        <div className="pveLotteryCurrentContent">
                            <span className={colorNumberOfTickets ? "textGreen" : "textRed"}>
                                {loadingLotteryData
                                    ? skeleton(60)
                                    : errorLotteryData
                                    ? "error!"
                                    : `${lotteryData.currentLottery.tickets} / ${lotteryData.currentLottery.minTickets}`}
                            </span>{" "}
                            <br /> current / minimum
                        </div>
                    </div>
                    <div className="pveLotteryCurrentItem">
                        <div className="pveLotteryCurrentTitle">Number of participants</div>
                        <div className="pveLotteryCurrentContent">
                            <span className={colorNumberOfParticipants ? "textGreen" : "textRed"}>
                                {loadingLotteryData
                                    ? skeleton(60)
                                    : errorLotteryData
                                    ? "error!"
                                    : `${lotteryData.currentLottery.participants} / ${lotteryData.currentLottery.minParticipants}`}
                            </span>{" "}
                            <br /> current / minimum
                        </div>
                    </div>
                </div>

                {/* place for current lottery */}
                <div className="pveLotteryPlaceWrapper">
                    <div className="pveLotteryPlace">
                        1st place -{" "}
                        <span>
                            {loadingLotteryData ? (
                                skeleton(60)
                            ) : errorLotteryData ? (
                                "error!"
                            ) : lotteryData.currentLottery.place1Reward !== 0 ? (
                                <>
                                    <span>{lotteryData.currentLottery.place1Reward}</span>{" "}
                                    <span className="pveLotteryPlaceTokenSymbol">{user.tokenSymbol}</span>
                                </>
                            ) : (
                                "soon..."
                            )}
                        </span>
                    </div>
                    <div className="pveLotteryPlace">
                        2nd place -{" "}
                        <span>
                            {loadingLotteryData ? (
                                skeleton(60)
                            ) : errorLotteryData ? (
                                "error!"
                            ) : lotteryData.currentLottery.place2Reward !== 0 ? (
                                <>
                                    <span>{lotteryData.currentLottery.place2Reward}</span>{" "}
                                    <span className="pveLotteryPlaceTokenSymbol">{user.tokenSymbol}</span>
                                </>
                            ) : (
                                "soon..."
                            )}
                        </span>
                    </div>
                    <div className="pveLotteryPlace">
                        3rd place -{" "}
                        <span>
                            {loadingLotteryData ? (
                                skeleton(60)
                            ) : errorLotteryData ? (
                                "error!"
                            ) : lotteryData.currentLottery.place3Reward !== 0 ? (
                                <>
                                    <span>{lotteryData.currentLottery.place3Reward}</span>{" "}
                                    <span className="pveLotteryPlaceTokenSymbol">{user.tokenSymbol}</span>
                                </>
                            ) : (
                                "soon..."
                            )}
                        </span>
                    </div>
                </div>

                {/* count user's tickets */}
                <div className="pveLotteryMyTicketsWrapper">
                    <div className="pveLotteryMyTickets">
                        My tickets: <span>{user.tickets ? user.tickets : "-"}</span>
                    </div>
                    <div className="pveLotteryNextTicket">
                        <div className="pveLotteryNextTicketTitle">Until the next ticket, ZOMB:</div>
                        <div className="pveLotteryUserTurnover">{currentTurnover}</div>
                        <div className="pveLotteryProgressBarLine">
                            <div
                                className="pveLotteryProgressBarOuter"
                                style={{
                                    width: `${200 * (linePercent / 100)}px`,
                                }}
                            ></div>
                        </div>
                        <div className="pveLotteryUserBeforeTicket">{Math.floor(nextTicket)}</div>
                    </div>
                </div>

                <div className="pveLotteryTitle pveLotteryTitlePast">Past lottery drawing</div>

                {/* lottery history */}
                <div className="pveLotteryCurrentWrapper">
                    <div className="pveLotteryCurrentItem">
                        <div className="pveLotteryCurrentTitle">#</div>
                        <div className="pveLotteryCurrentContent">
                            {loadingLotteryData
                                ? skeleton(30)
                                : errorLotteryData
                                ? "error!"
                                : lotteryData.lotteryHistory.numberOfLottery}
                        </div>
                    </div>
                    <div className="pveLotteryCurrentItem">
                        <div className="pveLotteryCurrentTitle">Start, UTC</div>
                        <div className="pveLotteryCurrentContent">
                            {loadingLotteryData
                                ? skeleton(110)
                                : errorLotteryData
                                ? "error!"
                                : updateTime(lotteryData.lotteryHistory.dateStart).slice(0, 16)}
                        </div>
                    </div>
                    <div className="pveLotteryCurrentItem">
                        <div className="pveLotteryCurrentTitle">Finish, UTC</div>
                        <div className="pveLotteryCurrentContent">
                            {loadingLotteryData
                                ? skeleton(110)
                                : errorLotteryData
                                ? "error!"
                                : updateTime(lotteryData.lotteryHistory.dateFinish).slice(0, 16)}
                        </div>
                    </div>
                    <div className="pveLotteryCurrentItem">
                        <div className="pveLotteryCurrentTitle">Prize pool</div>
                        <div className="pveLotteryCurrentContent">
                            <span className="pveLotteryCurrentPrize">
                                {loadingLotteryData ? (
                                    skeleton(70)
                                ) : errorLotteryData ? (
                                    "error!"
                                ) : (
                                    <>
                                        <span>{lotteryData.lotteryHistory.sumReward}</span>{" "}
                                        <span className="pveLotteryCurrentPrizePoolTokenSymbol">
                                            {user.tokenSymbol}
                                        </span>
                                    </>
                                )}{" "}
                            </span>
                        </div>
                    </div>
                    <div className="pveLotteryCurrentItem">
                        <div className="pveLotteryCurrentTitle">Turnover per ticket</div>
                        <div className="pveLotteryCurrentContent">
                            <span className="pveLotteryCurrentPrize">
                                {loadingLotteryData ? (
                                    skeleton(30)
                                ) : errorLotteryData ? (
                                    "error!"
                                ) : (
                                    <>
                                        <span>{lotteryData.lotteryHistory.turnoverForTicket}</span>{" "}
                                        <span className="pveLotteryCurrentPrizePoolTokenSymbol">
                                            {user.tokenSymbol}
                                        </span>
                                    </>
                                )}{" "}
                            </span>
                        </div>
                    </div>
                    <div className="pveLotteryCurrentItem">
                        <div className="pveLotteryCurrentTitle">Number of tickets</div>
                        <div className="pveLotteryCurrentContent">
                            <span className={colorNumberOfTicketsHistory ? "textGreen" : "textRed"}>
                                {loadingLotteryData
                                    ? skeleton(60)
                                    : errorLotteryData
                                    ? "error!"
                                    : `${lotteryData.lotteryHistory.tickets} / ${lotteryData.lotteryHistory.minTickets}`}
                            </span>{" "}
                            <br /> all / minimum
                        </div>
                    </div>
                    <div className="pveLotteryCurrentItem">
                        <div className="pveLotteryCurrentTitle">Number of participants</div>
                        <div className="pveLotteryCurrentContent">
                            <span className={colorNumberOfParticipantsHistory ? "textGreen" : "textRed"}>
                                {loadingLotteryData
                                    ? skeleton(60)
                                    : errorLotteryData
                                    ? "error!"
                                    : `${lotteryData.lotteryHistory.participants} / ${lotteryData.lotteryHistory.minParticipants}`}
                            </span>{" "}
                            <br /> all / minimum
                        </div>
                    </div>
                    <div className="pveLotteryCurrentItem">
                        <div className="pveLotteryCurrentTitle">Raffled?</div>
                        <div
                            className={
                                colorLotteryHistoryRaffled
                                    ? "pveLotteryCurrentContent textGreen"
                                    : "pveLotteryCurrentContent textRed"
                            }
                        >
                            {loadingLotteryData
                                ? skeleton(30)
                                : errorLotteryData
                                ? "error!"
                                : lotteryData.lotteryHistory.raffled
                                ? "Yes"
                                : "No"}
                        </div>
                    </div>
                </div>

                {lotteryData && lotteryData.lotteryHistory.raffled ? (
                    <>
                        <div className="pveLotteryPlacePastTitleWrapper">
                            <div className="pveLotteryPlaceTitleItem">Place</div>
                            <div className="pveLotteryPlaceTitleItem">Prize</div>
                            <div className="pveLotteryPlaceTitleItem">Email</div>
                            <div className="pveLotteryPlaceTitleItem">Wallet</div>
                            <div className="pveLotteryPlaceTitleItem">Tickets</div>
                        </div>

                        {/* place for lottery history */}
                        <div className="pveLotteryPlacePastTitleWrapper pveLotteryPlacePastTitleWrapperItem">
                            <div className="pveLotteryPlaceItem">1</div>
                            <div className="pveLotteryPlaceItem pveLotteryPlaceItemWlkn">
                                {loadingLotteryData ? (
                                    skeleton(60)
                                ) : errorLotteryData ? (
                                    "error!"
                                ) : (
                                    <>
                                        <span>{lotteryData.lotteryHistory.place1Reward}</span>{" "}
                                        <span className="pveLotteryPlaceTokenSymbol">{user.tokenSymbol}</span>
                                    </>
                                )}
                            </div>
                            <div className="pveLotteryPlaceItem">
                                {loadingLotteryData
                                    ? skeleton(120)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryData.lotteryHistory.place1Email}
                            </div>
                            <div className="pveLotteryPlaceItem">
                                {loadingLotteryData
                                    ? skeleton(100)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryData.lotteryHistory.place1Address}
                            </div>
                            <div className="pveLotteryPlaceItem">
                                {loadingLotteryData
                                    ? skeleton(30)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryData.lotteryHistory.place1Tickets}
                            </div>
                        </div>
                        <div className="pveLotteryPlacePastTitleWrapper pveLotteryPlacePastTitleWrapperItem">
                            <div className="pveLotteryPlaceItem">2</div>
                            <div className="pveLotteryPlaceItem pveLotteryPlaceItemWlkn">
                                {loadingLotteryData ? (
                                    skeleton(60)
                                ) : errorLotteryData ? (
                                    "error!"
                                ) : (
                                    <>
                                        <span>{lotteryData.lotteryHistory.place1Reward}</span>{" "}
                                        <span className="pveLotteryPlaceTokenSymbol">{user.tokenSymbol}</span>
                                    </>
                                )}
                            </div>
                            <div className="pveLotteryPlaceItem">
                                {loadingLotteryData
                                    ? skeleton(120)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryData.lotteryHistory.place2Email}
                            </div>
                            <div className="pveLotteryPlaceItem">
                                {loadingLotteryData
                                    ? skeleton(100)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryData.lotteryHistory.place2Address}
                            </div>
                            <div className="pveLotteryPlaceItem">
                                {loadingLotteryData
                                    ? skeleton(30)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryData.lotteryHistory.place2Tickets}
                            </div>
                        </div>
                        <div className="pveLotteryPlacePastTitleWrapper pveLotteryPlacePastTitleWrapperItem">
                            <div className="pveLotteryPlaceItem">3</div>
                            <div className="pveLotteryPlaceItem pveLotteryPlaceItemWlkn">
                                {loadingLotteryData ? (
                                    skeleton(60)
                                ) : errorLotteryData ? (
                                    "error!"
                                ) : (
                                    <>
                                        <span>{lotteryData.lotteryHistory.place1Reward}</span>{" "}
                                        <span className="pveLotteryPlaceTokenSymbol">{user.tokenSymbol}</span>
                                    </>
                                )}
                            </div>
                            <div className="pveLotteryPlaceItem">
                                {loadingLotteryData
                                    ? skeleton(120)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryData.lotteryHistory.place3Email}
                            </div>
                            <div className="pveLotteryPlaceItem">
                                {loadingLotteryData
                                    ? skeleton(100)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryData.lotteryHistory.place3Address}
                            </div>
                            <div className="pveLotteryPlaceItem">
                                {loadingLotteryData
                                    ? skeleton(30)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryData.lotteryHistory.place3Tickets}
                            </div>
                        </div>
                    </>
                ) : null}

                <div className="pveLotteryLinksWrapper">
                    <div className="pveLotteryTitleLink">
                        Lottery rules:{" "}
                        <Link className="pveLotteryRulesLink pveLotteryLinkBottom" to="/docs/lotteryRules">
                            here
                        </Link>
                    </div>
                    <div className="pveLotteryTitleLink">
                        History of lotteries:{" "}
                        <Link className="pveLotteryRulesLink pveLotteryLinkBottom" to="/docs/lotteryHistory">
                            here
                        </Link>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Lottery;
