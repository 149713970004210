import { createSlice } from "@reduxjs/toolkit";

const gameProcessSlice = createSlice({
    name: "gameProcess",
    initialState: {
        newCatBtn: true,
        processPlay: false, // going play process
        bet: 1,
        disableBet: false, // disable bet button while go game
        playBtn: true,
        hideBalance: false, // hide balance while go game
    },
    reducers: {
        setNewCatBtn(state, action) {
            state.newCatBtn = action.payload;
        },
        setProcessPlay(state, action) {
            state.processPlay = action.payload;
        },
        setBet(state, action) {
            state.bet = action.payload;
        },
        setDisableBet(state, action) {
            state.disableBet = action.payload;
        },
        setPlayBtn(state, action) {
            state.playBtn = action.payload;
        },
        setHideBalance(state, action) {
            state.hideBalance = action.payload;
        },
    },
});

export const {
    setNewCatBtn,
    setProcessPlay,
    setBet,
    setDisableBet,
    setPlayBtn,
    setHideBalance,
} = gameProcessSlice.actions;

export default gameProcessSlice.reducer;
