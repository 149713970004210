import "./StatsContent.scss";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { baseUrl } from "../axios/axios";
import CountUp from "react-countup";

import { setStats } from "../../store/gameStatsSlice";

import Container from "@mui/material/Container";
import Skeleton from "@mui/material/Skeleton";

const StatsContent = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [animate, setAnimate] = useState(false);

    const dispatch = useDispatch();

    const stats = useSelector((state) => state.gameStats.stats);

    // set axios request
    const gameStatsRequest = axios.create({
        withCredentials: true,
        baseURL: baseUrl,
    });

    const getStatsData = async () => {
        setLoading(true);
        setError(false);
        gameStatsRequest
            .post("/gameStats")
            .then((res) => {
                // console.log(res.data);
                dispatch(setStats(res.data));
            })
            .catch((e) => {
                console.log(e.response?.data?.message);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
                startAnimate();
            });
    };

    useEffect(() => {
        getStatsData();
    }, []);

    const startAnimate = () => {
        setAnimate(true);
    };
    const stopAnimate = () => {
        setAnimate(false);
    };
    const containerProps = {
        "aria-busy": animate,
    };

    const skeleton = (width, height) => {
        return <Skeleton className="pveSkeleton" width={width} height={height} />;
    };

    return (
        <Container>
            <div className="mainTileWrapper">
                <div className="mainTile">
                    <div className="mainTileTitle">Total players</div>
                    {loading ? (
                        skeleton(100, 40)
                    ) : !error ? (
                        <CountUp
                            className="mainTileValue"
                            start={0}
                            end={stats.users}
                            duration="3"
                            onStart={startAnimate}
                            onEnd={stopAnimate}
                            containerProps={containerProps}
                        />
                    ) : (
                        <div className="mainTileValue">Error!</div>
                    )}
                </div>
                <div className="mainTile">
                    <div className="mainTileTitle">ZOMB paid out</div>
                    {loading ? (
                        skeleton(100, 40)
                    ) : !error ? (
                        <CountUp
                            className="mainTileValue"
                            start={0}
                            end={stats.allWithdraws}
                            duration="3"
                            onStart={startAnimate}
                            onEnd={stopAnimate}
                            containerProps={containerProps}
                        />
                    ) : (
                        <div className="mainTileValue">Error!</div>
                    )}
                </div>
                <div className="mainTile">
                    <div className="mainTileTitle">Total PvE games</div>
                    {loading ? (
                        skeleton(100, 40)
                    ) : !error ? (
                        <CountUp
                            className="mainTileValue"
                            start={0}
                            end={stats.allGames}
                            duration="3"
                            onStart={startAnimate}
                            onEnd={stopAnimate}
                            containerProps={containerProps}
                        />
                    ) : (
                        <div className="mainTileValue">Error!</div>
                    )}
                </div>
                <div className="mainTile">
                    <div className="mainTileTitle">ZOMB burnt</div>
                    {loading ? (
                        skeleton(100, 40)
                    ) : !error ? (
                        <CountUp
                            className="mainTileValue"
                            start={0}
                            end={stats.burned}
                            duration="3"
                            onStart={startAnimate}
                            onEnd={stopAnimate}
                            containerProps={containerProps}
                        />
                    ) : (
                        <div className="mainTileValue">Error!</div>
                    )}
                </div>
                <div className="mainTile">
                    <div className="mainTileTitle">Passed farms</div>
                    {loading ? (
                        skeleton(100, 40)
                    ) : !error ? (
                        <CountUp
                            className="mainTileValue"
                            start={0}
                            end={stats.cemeteries}
                            duration="3"
                            onStart={startAnimate}
                            onEnd={stopAnimate}
                            containerProps={containerProps}
                        />
                    ) : (
                        <div className="mainTileValue">Error!</div>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default StatsContent;
