/* global BigInt */
import "./BrainFarm.scss";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";
import { baseUrl } from "../axios/axios";
import { updateTime } from "../utils/updateTime";
import useSaveData from "./hooks/useSaveData";

import { Link } from "react-router-dom";

import {
    setNewGameFactories,
    setSaveDate,
    setSaveLoaded,
    setFactory_1_display,
    setFactory_2_display,
    setFactory_3_display,
    setFactory_4_display,
} from "../../store/brainFarmSlice";
import {
    setNewGameTasks,
    setBronzeRewardShare,
    setSilverRewardShare,
    setGoldRewardShare,
    setTasksFactory,
    setTasksManager,
    setTasksTurnover,
} from "../../store/brainFarmRewardsTasksSlice";

import Factory from "./components/Factory";
import Lab from "./components/Lab";
import Port from "./components/Port";
import ShipPath from "./components/ShipPath";
import ModalTasks from "./components/ModalTasks";
import ModalComplete from "./components/ModalComplete";

import { openModal, closeModal, abbreviationNumbers } from "./functions";

import Container from "@mui/material/Container";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";

const BrainFarm = () => {
    const dispatch = useDispatch();

    const saveIntervalRef = useRef(0);

    const [showModalTasks, setShowModalTasks] = useState(false);
    const [showModalComplete, setShowModalComplete] = useState(false);

    const [newGameLoading, setNewGameLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [saveIndicator, setSaveIndicator] = useState(0);
    const [loadSaveFromServer, setLoadSaveFromServer] = useState(false);
    // const [buyManagerLoading, setBuyManagerLoading] = useState(false);

    const [errorMsg, setErrorMsg] = useState("");
    const [errorMsgSave, setErrorMsgSave] = useState("");
    const [errorLoadingSave, setErrorLoadingSave] = useState(false);

    const handleCloseModalTasks = () => {
        setShowModalTasks(false);
        closeModal();
    };
    const handleShowModalTasks = () => {
        setShowModalTasks(true);
        openModal();
    };

    const handleCloseModalComplete = () => {
        setShowModalComplete(false);
        closeModal();
    };
    const handleShowModalComplete = () => {
        setShowModalComplete(true);
        openModal();
    };

    // set axios request
    const brainFarmRequest = axios.create({
        withCredentials: true,
        baseURL: baseUrl,
    });
    brainFarmRequest.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
        return config;
    });
    brainFarmRequest.interceptors.response.use(
        (config) => {
            return config;
        },
        async (error) => {
            const originalRequest = error.config;

            if (error.response.status === 401 && error.config && !error.config._isRetry) {
                originalRequest._isRetry = true;
                axios
                    .get(`${baseUrl}/refresh`, {
                        withCredentials: true,
                    })
                    .then((res) => {
                        localStorage.setItem("token", res.data.accessToken);

                        if (originalRequest.url === "/brainFarmNewGame") {
                            brainFarmRequest
                                .request(originalRequest)
                                .then((resNewGameData) => {
                                    dispatch(setNewGameFactories(resNewGameData.data));
                                    dispatch(setNewGameTasks(resNewGameData.data));

                                    setNewGameLoading(false);
                                })
                                .catch((e) => {
                                    console.log(e.response?.data?.message);
                                    setErrorMsg(e.response?.data?.message);

                                    setNewGameLoading(false);
                                });
                        } else if (originalRequest.url === "/brainFarmNewSave") {
                            brainFarmRequest
                                .request(originalRequest)
                                .then((resNewSaveData) => {
                                    dispatch(setSaveDate(resNewSaveData.data));
                                    setSaveLoading(false);
                                })
                                .catch((e) => {
                                    console.log(e.response?.data?.message);
                                    setErrorMsgSave(e.response?.data?.message);
                                    setSaveLoading(false);
                                });
                        } else if (originalRequest.url === "brainFarmContinue") {
                            brainFarmRequest
                                .request(originalRequest)
                                .then((resSaveData) => {
                                    if (resSaveData.data.gameRunning) {
                                        dispatch(setNewGameFactories(resSaveData.data));
                                        dispatch(setNewGameTasks(resSaveData.data));
                                        dispatch(setSaveDate(resSaveData.data.saveDate));
                                        dispatch(setSaveLoaded());
                                    }
                                    setLoadSaveFromServer(false);
                                })
                                .catch((e) => {
                                    console.log(e.response?.data?.message);
                                    setErrorMsg(e.response?.data?.message);
                                    setErrorLoadingSave(true);
                                    setLoadSaveFromServer(false);
                                });
                        }
                    })
                    .catch((e) => {
                        console.log("You not authorize");
                    });
            } else {
                throw error;
            }
        }
    );

    const user = useSelector((state) => state.user.user);
    const hideBalance = useSelector((state) => state.gameProcess.hideBalance);

    // const requestLoading = useSelector((state) => state.brainFarm.requestLoading);
    const gameRunning = useSelector((state) => state.brainFarm.gameRunning);
    const saveDate = useSelector((state) => state.brainFarm.saveDate);

    const warehouse_main = useSelector((state) => state.brainFarm.warehouse_main);
    const warehouse_factories = useSelector((state) => state.brainFarm.warehouse_factories);
    const warehouse_port = useSelector((state) => state.brainFarm.warehouse_port);

    const factory_1_init_price = useSelector((state) => state.brainFarm.factory_1_init_price);
    const factory_2_init_price = useSelector((state) => state.brainFarm.factory_2_init_price);
    const factory_3_init_price = useSelector((state) => state.brainFarm.factory_3_init_price);
    const factory_4_init_price = useSelector((state) => state.brainFarm.factory_4_init_price);
    const factory_1_display = useSelector((state) => state.brainFarm.factory_1_display);
    const factory_2_display = useSelector((state) => state.brainFarm.factory_2_display);
    const factory_3_display = useSelector((state) => state.brainFarm.factory_3_display);
    const factory_4_display = useSelector((state) => state.brainFarm.factory_4_display);

    const tasksFactory = useSelector((state) => state.brainFarmRewardsTasks.tasksFactory);
    const tasksManager = useSelector((state) => state.brainFarmRewardsTasks.tasksManager);
    const tasksTurnover = useSelector((state) => state.brainFarmRewardsTasks.tasksTurnover);

    const factory_0_lvl = useSelector((state) => state.brainFarm.factory_0_lvl);
    const factory_1_lvl = useSelector((state) => state.brainFarm.factory_1_lvl);
    const factory_2_lvl = useSelector((state) => state.brainFarm.factory_2_lvl);
    const factory_3_lvl = useSelector((state) => state.brainFarm.factory_3_lvl);
    const factory_4_lvl = useSelector((state) => state.brainFarm.factory_4_lvl);
    const port_lvl = useSelector((state) => state.brainFarm.port_lvl);
    const lab_lvl = useSelector((state) => state.brainFarm.lab_lvl);

    const factory_0_manager_lvl = useSelector((state) => state.brainFarm.factory_0_manager_lvl);
    const factory_1_manager_lvl = useSelector((state) => state.brainFarm.factory_1_manager_lvl);
    const factory_2_manager_lvl = useSelector((state) => state.brainFarm.factory_2_manager_lvl);
    const factory_3_manager_lvl = useSelector((state) => state.brainFarm.factory_3_manager_lvl);
    const factory_4_manager_lvl = useSelector((state) => state.brainFarm.factory_4_manager_lvl);
    const port_manager_lvl = useSelector((state) => state.brainFarm.port_manager_lvl);
    const lab_manager_lvl = useSelector((state) => state.brainFarm.lab_manager_lvl);

    const userEmail = user.userEmail;
    const saveData = useSaveData();

    if (BigInt(warehouse_main) >= BigInt(factory_1_init_price) / BigInt(2)) {
        dispatch(setFactory_1_display(true));
    }
    if (BigInt(warehouse_main) >= BigInt(factory_2_init_price) / BigInt(2)) {
        dispatch(setFactory_2_display(true));
    }
    if (BigInt(warehouse_main) >= BigInt(factory_3_init_price) / BigInt(2)) {
        dispatch(setFactory_3_display(true));
    }
    if (BigInt(warehouse_main) >= BigInt(factory_4_init_price) / BigInt(2)) {
        dispatch(setFactory_4_display(true));
    }

    const skeleton = (width, height) => {
        return <Skeleton className="pveSkeleton" width={width} height={height} />;
    };

    // function for check complete task and set reward task in store
    const checkTask = (tasksArr, factoryId, factoryLvl, storeFunction) => {
        const factory0Tasks = tasksArr.filter((task) => {
            return task.factoryId === factoryId && !task.completed;
        });

        if (factory0Tasks.length > 0) {
            factory0Tasks.forEach((task) => {
                if (factoryLvl === task.maxLevel) {
                    if (task.typeReward === "bronze") {
                        dispatch(setBronzeRewardShare(task.rewardValue));
                    } else if (task.typeReward === "silver") {
                        dispatch(setSilverRewardShare(task.rewardValue));
                    } else if (task.typeReward === "gold") {
                        dispatch(setGoldRewardShare(task.rewardValue));
                    }

                    const completedTask = Object.assign({}, task);
                    completedTask.completed = true;

                    const newTasksArray = tasksArr.filter((item) => {
                        return item.taskId !== task.taskId;
                    });

                    newTasksArray.push(completedTask);

                    newTasksArray.sort((a, b) => {
                        return a.taskId - b.taskId;
                    });

                    dispatch(storeFunction(newTasksArray));
                }
            });
        }
    };

    // new game
    const newGame = async () => {
        setNewGameLoading(true);
        setErrorMsg("");

        brainFarmRequest
            .post("/brainFarmNewGame", { userEmail })
            .then((res) => {
                // console.log(res.data);
                if (res !== undefined) {
                    // console.log(res.data);
                    dispatch(setNewGameFactories(res.data));
                    dispatch(setNewGameTasks(res.data));

                    setNewGameLoading(false);
                }
            })
            .catch((e) => {
                console.log(e.response?.data?.message);
                setErrorMsg(e.response?.data?.message);

                setNewGameLoading(false);
            });
    };

    // calculate reward percents
    useEffect(() => {
        checkTask(tasksFactory, 0, factory_0_lvl, setTasksFactory);
    }, [factory_0_lvl]);
    useEffect(() => {
        checkTask(tasksFactory, 1, factory_1_lvl, setTasksFactory);
    }, [factory_1_lvl]);
    useEffect(() => {
        checkTask(tasksFactory, 2, factory_2_lvl, setTasksFactory);
    }, [factory_2_lvl]);
    useEffect(() => {
        checkTask(tasksFactory, 3, factory_3_lvl, setTasksFactory);
    }, [factory_3_lvl]);
    useEffect(() => {
        checkTask(tasksFactory, 4, factory_4_lvl, setTasksFactory);
    }, [factory_4_lvl]);
    useEffect(() => {
        checkTask(tasksFactory, 100, port_lvl, setTasksFactory);
    }, [port_lvl]);
    useEffect(() => {
        checkTask(tasksFactory, 200, lab_lvl, setTasksFactory);
    }, [lab_lvl]);

    useEffect(() => {
        checkTask(tasksManager, 0, factory_0_manager_lvl, setTasksManager);
    }, [factory_0_manager_lvl]);
    useEffect(() => {
        checkTask(tasksManager, 1, factory_1_manager_lvl, setTasksManager);
    }, [factory_1_manager_lvl]);
    useEffect(() => {
        checkTask(tasksManager, 2, factory_2_manager_lvl, setTasksManager);
    }, [factory_2_manager_lvl]);
    useEffect(() => {
        checkTask(tasksManager, 3, factory_3_manager_lvl, setTasksManager);
    }, [factory_3_manager_lvl]);
    useEffect(() => {
        checkTask(tasksManager, 4, factory_4_manager_lvl, setTasksManager);
    }, [factory_4_manager_lvl]);
    useEffect(() => {
        checkTask(tasksManager, 100, port_manager_lvl, setTasksManager);
    }, [port_manager_lvl]);
    useEffect(() => {
        checkTask(tasksManager, 200, lab_manager_lvl, setTasksManager);
    }, [lab_manager_lvl]);

    useEffect(() => {
        const factory0Tasks = tasksTurnover.filter((task) => {
            return task.factoryId === 9000 && !task.completed;
        });

        if (factory0Tasks.length > 0) {
            factory0Tasks.forEach((task) => {
                if (user.turnover >= task.maxLevel) {
                    if (task.typeReward === "bronze") {
                        dispatch(setBronzeRewardShare(task.rewardValue));
                    } else if (task.typeReward === "silver") {
                        dispatch(setSilverRewardShare(task.rewardValue));
                    } else if (task.typeReward === "gold") {
                        dispatch(setGoldRewardShare(task.rewardValue));
                    }

                    const completedTask = Object.assign({}, task);
                    completedTask.completed = true;

                    const newTasksArray = tasksTurnover.filter((item) => {
                        return item.taskId !== task.taskId;
                    });

                    newTasksArray.push(completedTask);

                    newTasksArray.sort((a, b) => {
                        return a.taskId - b.taskId;
                    });

                    dispatch(setTasksTurnover(newTasksArray));
                }
            });
        }
    }, [user]);

    const saveInterval = () => {
        let idSaveInterval = setInterval(() => {
            setSaveIndicator((indicator) => indicator + 1);
        }, 60000);
        saveIntervalRef.current = idSaveInterval;
        // console.log(saveIntervalRef.current);
    };

    // save timer
    useEffect(() => {
        saveInterval();

        return () => {
            // console.log(saveIntervalRef.current);
            clearInterval(saveIntervalRef.current);
        };
    }, []);

    useEffect(() => {
        if (saveIndicator > 0) {
            if (gameRunning) {
                setSaveLoading(true);
                brainFarmRequest
                    .post("/brainFarmNewSave", { saveData })
                    .then((res) => {
                        // console.log(res.data);
                        if (res !== undefined) {
                            dispatch(setSaveDate(res.data));
                            setSaveLoading(false);
                        }
                    })
                    .catch((e) => {
                        console.log(e.response?.data?.message);
                        setErrorMsgSave(e.response?.data?.message);
                        setSaveLoading(false);
                    });
            }
        }
    }, [saveIndicator]);

    // load save
    useEffect(() => {
        setLoadSaveFromServer(true);
        setErrorLoadingSave(false);

        brainFarmRequest
            .post("/brainFarmContinue", { userEmail })
            .then((res) => {
                // console.log(res.data);
                if (res !== undefined) {
                    // console.log(res.data);
                    if (res.data.gameRunning) {
                        dispatch(setNewGameFactories(res.data));
                        dispatch(setNewGameTasks(res.data));
                        dispatch(setSaveDate(res.data.saveDate));
                        dispatch(setSaveLoaded());
                    }
                    setLoadSaveFromServer(false);
                }
            })
            .catch((e) => {
                console.log(e.response?.data?.message);
                setErrorMsg(e.response?.data?.message);
                setErrorLoadingSave(true);
                setLoadSaveFromServer(false);
            });
    }, []);

    return (
        <Container>
            <ModalTasks showModalTasks={showModalTasks} handleCloseModalTasks={handleCloseModalTasks} />
            <ModalComplete showModalComplete={showModalComplete} handleCloseModalComplete={handleCloseModalComplete} />

            <div className="brainWrapper">
                <div className="pveWithdrawLine"></div>
                <div className="pveBalanceDepositTitle">Brain farm</div>
                <div className="pveLotteryDescriptionWrapper smoothBackground">
                    <div className="pveLotteryDescription">
                        Collect brains (lots and lots of brains!), pass graveyards and get ZOMB. You can find out how to
                        play{" "}
                        <Link className="pveLotteryRulesLink" to="/docs/instructionBrainFarm">
                            here
                        </Link>
                        .
                    </div>
                </div>

                <div className="pveBalanceTitle brainBalanceTitle">Your balance</div>
                <div className="pveBalanceValue">
                    <span>{hideBalance ? skeleton(70) : Math.round(user.balance * user.decimal) / user.decimal}</span>{" "}
                    <span className="pveBalanceTokenSymbol">{user.tokenSymbol}</span>
                </div>

                <div className="pveFaucetHorizontalAd">
                    <div className="pveFaucetAdsTestModeH">Advertising</div>
                </div>

                <div className={gameRunning ? "brainGameWrapper" : "brainGameWrapper brainGameWrapperDark"}>
                    {gameRunning ? (
                        <>
                            {/* main warehouse */}
                            <div className="brainWarehouseWrapper brainWarehouseMainWrapper">
                                <img src="/img/farm/brainIcon.png" alt="brain" className="brainWarehouseMainImg" />
                                <div className="brainWarehouseTitle">{abbreviationNumbers(warehouse_main)}</div>
                            </div>

                            {/* main user's balance */}
                            <div className="brainWarehouseWrapper brainBalanceWrapper">
                                <div className="brainBalanceUserTitle">Z</div>
                                <div className="brainWarehouseTitle brainBalanceUserValue">
                                    {user.balance.toLocaleString("en-US", {
                                        notation: "compact",
                                    })}
                                </div>
                            </div>

                            <div className="brainBuyBtnWrapper">
                                <div className="brainBuyTextWrapper">
                                    <div className="brainBuyText">
                                        buy <span>Z</span>
                                    </div>
                                </div>
                                <Button className="brainBuyBtn" variant="contained" color="farmBlack">
                                    <a
                                        className="brainBuyBtnA"
                                        href="https://pyreswap.finance/swap?inputCurrency=ETH&outputCurrency=0x1e6B8866Ff62De4894C92eF454799d299Abd1F2D&chainId=250"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src="/img/farm/firePyreswap.png"
                                            alt="fire pyreswap"
                                            className="brainBuyBtnIcon"
                                        />
                                        PyreSwap
                                    </a>
                                </Button>
                            </div>

                            <div className="brainTasksBtnWrapper">
                                <Button
                                    className="brainTasksBtn"
                                    variant="contained"
                                    color="farmMain"
                                    onClick={handleShowModalTasks}
                                >
                                    Tasks
                                </Button>
                            </div>

                            {/* factory warehouse */}
                            <div className="brainWarehouseWrapper brainWarehouseFactoryWrapper">
                                <div className="brainWarehouseTitleValue">
                                    {abbreviationNumbers(warehouse_factories)}
                                </div>
                            </div>

                            {/* port warehouse */}
                            <div className="brainWarehouseWrapper brainWarehousePortWrapper">
                                <div className="brainWarehouseTitleValue">{abbreviationNumbers(warehouse_port)}</div>
                            </div>

                            <Lab
                                factoryId={200}
                                saveIntervalId={saveIntervalRef.current}
                                saveInterval={saveInterval}
                                circularAnimationName={"circular-progress-200"}
                                shakeAnimationName={"shake-vertical-animation-200"}
                                positionClass={"brainFactory200Wrapper"}
                            />

                            <Port
                                factoryId={100}
                                saveIntervalId={saveIntervalRef.current}
                                saveInterval={saveInterval}
                                circularAnimationName={"circular-progress-100"}
                                shakeAnimationName={"shake-vertical-animation-100"}
                                positionClass={"brainFactory100Wrapper"}
                            />

                            <ShipPath />

                            <Factory
                                factoryId={0}
                                saveIntervalId={saveIntervalRef.current}
                                saveInterval={saveInterval}
                                circularAnimationName={"circular-progress-0"}
                                shakeAnimationName={"shake-vertical-animation-0"}
                                positionClass={"brainFactory0Wrapper"}
                            />
                            {factory_1_display ? (
                                <Factory
                                    factoryId={1}
                                    saveIntervalId={saveIntervalRef.current}
                                    saveInterval={saveInterval}
                                    circularAnimationName={"circular-progress-1"}
                                    shakeAnimationName={"shake-vertical-animation-1"}
                                    positionClass={"brainFactory1Wrapper"}
                                />
                            ) : null}

                            {factory_2_display ? (
                                <Factory
                                    factoryId={2}
                                    saveIntervalId={saveIntervalRef.current}
                                    saveInterval={saveInterval}
                                    circularAnimationName={"circular-progress-2"}
                                    shakeAnimationName={"shake-vertical-animation-2"}
                                    positionClass={"brainFactory2Wrapper"}
                                />
                            ) : null}

                            {factory_3_display ? (
                                <Factory
                                    factoryId={3}
                                    saveIntervalId={saveIntervalRef.current}
                                    saveInterval={saveInterval}
                                    circularAnimationName={"circular-progress-3"}
                                    shakeAnimationName={"shake-vertical-animation-3"}
                                    positionClass={"brainFactory3Wrapper"}
                                />
                            ) : null}

                            {factory_4_display ? (
                                <Factory
                                    factoryId={4}
                                    saveIntervalId={saveIntervalRef.current}
                                    saveInterval={saveInterval}
                                    circularAnimationName={"circular-progress-4"}
                                    shakeAnimationName={"shake-vertical-animation-4"}
                                    positionClass={"brainFactory4Wrapper"}
                                />
                            ) : null}

                            <div className="brainBottomBtnWrapper">
                                <div className="brainBottomBtnTitle">Next cemetery</div>
                                <Button
                                    className="brainBottomBtn"
                                    variant="contained"
                                    color="farmMain"
                                    onClick={handleShowModalComplete}
                                >
                                    Continue
                                </Button>
                            </div>
                        </>
                    ) : null}
                </div>

                {!gameRunning ? (
                    <div className="brainStartBtnWrapper">
                        <LoadingButton
                            className="brainStartBtn"
                            variant="contained"
                            color="farmMain"
                            loading={newGameLoading || loadSaveFromServer}
                            disabled={errorLoadingSave}
                            onClick={newGame}
                            sx={{
                                "&.MuiLoadingButton-loading": {
                                    color: "transparent !important",
                                },
                                "&.Mui-disabled": {
                                    color: "#c0c0c0 !important",
                                },
                            }}
                        >
                            Start new game
                        </LoadingButton>

                        {errorMsg !== "" ? <div className="brainStartBtnError">{errorMsg}</div> : null}
                    </div>
                ) : null}

                <div className="brainLastSaveWrapper">
                    <div className="brainLastSaveText">
                        Last save: {saveDate !== 0 ? updateTime(saveDate) : "???"}{" "}
                        {saveLoading ? (
                            <div className="brainLastSaveLoadingWrapper">
                                <CircularProgress color="farmMain" className="brainLastSaveLoading" />
                            </div>
                        ) : null}
                        <div className="brainLastSaveError">{errorMsgSave === "" ? "" : errorMsgSave}</div>
                    </div>
                </div>

                <div className="pveFaucetHorizontalAd brainFooterAd">
                    <div className="pveFaucetAdsTestModeH">Advertising</div>
                </div>
            </div>
        </Container>
    );
};

export default BrainFarm;
