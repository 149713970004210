/* global BigInt */
import "./ModalManager.scss";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setWarehouseFactories, setSpent, setSaveDate } from "../../../store/brainFarmSlice";
import { setBalanceForFarm } from "../../../store/userSlice";

import axios from "axios";
import { baseUrl } from "../../axios/axios";
import useSaveData from "../hooks/useSaveData";

import {
    stopPlayAnimation,
    disableFactoryCursorPointer,
    displayLvlPercent,
    calcPortLabValueLvl,
    calcFactoryValueLvl,
} from "../functions";

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import LoadingButton from "@mui/lab/LoadingButton";
import WarningIcon from "@mui/icons-material/Warning";

const ModalManager = (props) => {
    const {
        open,
        close,
        type,
        factoryManager,
        factoryManagerPrice,
        factoryBought,
        factoryManagerValue,
        factoryProdReady,
        shakeAnimationTimer,
        factoryValue,
        setFactoryBaseValue,
        factoryInitBaseValue,
        setFactoryProdReady,
        circularAnimationName,
        factoryId,
        setFactoryWork,
        setFactoryManagerLvlUp,
        setFactoryValue,
        handProductionTimer,
        autoCollectTimer,
        factorySpeed,
        factoryInitSpeed,
        setFactorySpeed,
        factoryPlusProduct,
        portTransferProcess,
        factoryLvl,
        managerImg,
        managerIcon,
        saveIntervalId,
        saveInterval,
    } = props;

    const dispatch = useDispatch();

    const user = useSelector((state) => state.user.user);

    const portInitSpeed = useSelector((state) => state.brainFarm.port_init_speed);
    const labInitSpeed = useSelector((state) => state.brainFarm.lab_init_speed);

    const [buyManagerLoading, setBuyManagerLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    // set axios request
    const brainFarmManagerRequest = axios.create({
        withCredentials: true,
        baseURL: baseUrl,
    });
    brainFarmManagerRequest.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
        return config;
    });
    brainFarmManagerRequest.interceptors.response.use(
        (config) => {
            return config;
        },
        async (error) => {
            const originalRequest = error.config;

            if (error.response.status === 401 && error.config && !error.config._isRetry) {
                originalRequest._isRetry = true;

                setBuyManagerLoading(true);
                setErrorMsg("");

                axios
                    .get(`${baseUrl}/refresh`, {
                        withCredentials: true,
                    })
                    .then((res) => {
                        localStorage.setItem("token", res.data.accessToken);
                        clearInterval(saveIntervalId);

                        brainFarmManagerRequest
                            .request(originalRequest)
                            .then((buyData) => {
                                dispatch(setSaveDate(buyData.data.saveDate));
                                dispatch(setBalanceForFarm(buyData.data.newBalance));
                                dispatch(setSpent(buyData.data.spent));
                                dispatch(setFactoryManagerLvlUp(buyData.data.newLvl));

                                setBuyManagerLoading(false);
                                saveInterval();
                            })
                            .catch((e) => {
                                console.log(e.response?.data?.message);
                                setErrorMsg(e.response?.data?.message);

                                setBuyManagerLoading(false);
                                saveInterval();
                            });
                    })
                    .catch((e) => {
                        console.log("You not authorize");

                        setBuyManagerLoading(false);
                        clearInterval(saveIntervalId);
                    });
            } else {
                throw error;
            }
        }
    );

    const saveData = useSaveData();

    let title = "";
    let quote = "";
    if (factoryId === 0) {
        title = "Grave digging factory";
        quote = "“If I want to, I'll dig, if I don't want to, I won't dig. Leave me alone!”";
    } else if (factoryId === 1) {
        title = "Mine factory";
        quote = "“The best brains are at the back of the ages!”";
    } else if (factoryId === 2) {
        title = "Temple";
        quote = "“Let us pray for the glory of having brains in this world!”";
    } else if (factoryId === 3) {
        title = "Canning factory";
        quote = "“Mastering conservation is an important step for the entire zombie world!”";
    } else if (factoryId === 4) {
        title = "Bank";
        quote = "“Taking the brains out of the percentages! Can you do that?”";
    } else if (factoryId === 100) {
        title = "Port";
        quote = "“Anchor my ass! Why do you need so many brains?”";
    } else if (factoryId === 200) {
        title = "Laboratory";
        quote = "“Why do I need so many brains? It's obvious! To understand how Pyreswap works!”";
    }

    const currentPercentValue = (type) => {
        let result = 0;

        if (type === "speed") {
            if (factoryManager === 0 || factoryManager === 1 || factoryManager === 2) {
                result = 0;
            } else if (factoryManager === 3) {
                result = displayLvlPercent(3, factoryManagerValue);
            } else if (factoryManager === 4) {
                result = displayLvlPercent(4, factoryManagerValue);
            } else if (factoryManager === 5) {
                result = displayLvlPercent(4, factoryManagerValue);
            }
        } else if (type === "boxes") {
            if (factoryManager === 0) {
                result = 0;
            } else if (factoryManager === 1) {
                result = displayLvlPercent(1, factoryManagerValue);
            } else if (factoryManager === 2 || factoryManager === 3 || factoryManager === 4) {
                result = displayLvlPercent(2, factoryManagerValue);
            } else if (factoryManager === 5) {
                result = displayLvlPercent(5, factoryManagerValue);
            }
        }

        return result;
    };

    const priceUpdateManager = () => {
        let result = 0;

        if (factoryManager === 0) {
            result = factoryManagerPrice.lvl_1;
        } else if (factoryManager === 1) {
            result = factoryManagerPrice.lvl_2;
        } else if (factoryManager === 2) {
            result = factoryManagerPrice.lvl_3;
        } else if (factoryManager === 3) {
            result = factoryManagerPrice.lvl_4;
        } else if (factoryManager === 4) {
            result = factoryManagerPrice.lvl_5;
        } else {
            result = "MAX";
        }

        return result;
    };

    // buy manager
    const buyManager = async (factoryId, newLvl, managerPrice, upgradeValue) => {
        if (managerPrice > user.balance) {
            setErrorMsg("Insufficient funds. Refill the balance.");
            return;
        }

        setBuyManagerLoading(true);
        clearInterval(saveIntervalId);
        setErrorMsg("");

        const buyData = {
            factoryId: factoryId,
            newLvl: newLvl,
            managerPrice: managerPrice,
            upgradeValue: upgradeValue,
        };

        brainFarmManagerRequest
            .post("/brainFarmBuyManager", { saveData, buyData })
            .then((res) => {
                // console.log(res.data);
                if (res !== undefined) {
                    // console.log(res.data);

                    dispatch(setSaveDate(res.data.saveDate));
                    dispatch(setBalanceForFarm(res.data.newBalance));
                    dispatch(setSpent(res.data.spent));
                    dispatch(setFactoryManagerLvlUp(res.data.newLvl));

                    setBuyManagerLoading(false);
                    saveInterval();
                }
            })
            .catch((e) => {
                console.log(e.response?.data?.message);
                setErrorMsg(e.response?.data?.message);

                setBuyManagerLoading(false);
                saveInterval();
            });
    };

    const updateManager = async () => {
        setErrorMsg("");

        // first buy manager
        if (factoryManager === 0) {
            if (user.balance - factoryManagerPrice.lvl_1 < 0) {
                setErrorMsg("Not enough ZOMB to buy!");
                return;
            }

            if (type === "factory") {
                // wait hand collect
                if (factoryProdReady) {
                    clearInterval(shakeAnimationTimer);
                } else {
                    clearInterval(shakeAnimationTimer);
                    clearTimeout(handProductionTimer);
                }

                const upgradeValue = {
                    value: (
                        (factoryValue * BigInt(Math.round(factoryManagerValue.lvl_1 * 100))) /
                        BigInt(100)
                    ).toString(),
                    baseValue: (
                        (factoryInitBaseValue * BigInt(Math.round(factoryManagerValue.lvl_1 * 100))) /
                        BigInt(100)
                    ).toString(),
                    speed: 0,
                };

                dispatch(setWarehouseFactories(upgradeValue.value));
                dispatch(setFactoryValue(upgradeValue.value));
                dispatch(setFactoryBaseValue(upgradeValue.baseValue));
                dispatch(setFactoryProdReady(false));

                const circularProgress = document.querySelectorAll(".brainFactoryCircularProgressWrapper");
                if (circularProgress) {
                    circularProgress.forEach((elem) => {
                        const elemId = elem.getAttribute("data-factory-id");

                        if (elemId == factoryId) {
                            elem.style.animation = `${circularAnimationName} ${factorySpeed}ms infinite linear`;
                        }
                    });

                    stopPlayAnimation(circularAnimationName);
                }

                disableFactoryCursorPointer(factoryId);

                dispatch(setFactoryWork(false));

                await buyManager(factoryId, 1, factoryManagerPrice.lvl_1, upgradeValue);

                // port and lab
            } else {
                // wait hand collect
                if (factoryProdReady) {
                    clearInterval(shakeAnimationTimer);
                } else {
                    clearInterval(shakeAnimationTimer);
                    clearTimeout(handProductionTimer);
                }

                const upgradeValue = {
                    value: (
                        (factoryValue * BigInt(Math.round(factoryManagerValue.lvl_1 * 100))) /
                        BigInt(100)
                    ).toString(),
                    baseValue: (
                        (factoryInitBaseValue * BigInt(Math.round(factoryManagerValue.lvl_1 * 100))) /
                        BigInt(100)
                    ).toString(),
                    speed: 0,
                };

                dispatch(setFactoryValue(upgradeValue.value));
                dispatch(setFactoryBaseValue(upgradeValue.baseValue));
                dispatch(setFactoryProdReady(false));

                factoryPlusProduct();

                disableFactoryCursorPointer(factoryId);

                dispatch(setFactoryWork(false));

                await buyManager(factoryId, 1, factoryManagerPrice.lvl_1, upgradeValue);
            }
        } else if (factoryManager === 1) {
            if (user.balance - factoryManagerPrice.lvl_2 < 0) {
                setErrorMsg("Not enough ZOMB to buy!");
                return;
            }

            if (type === "factory") {
                clearInterval(autoCollectTimer);

                const newBaseValue =
                    (factoryInitBaseValue * BigInt(Math.round(factoryManagerValue.lvl_2 * 100))) / BigInt(100);

                const upgradeValue = {
                    value: calcFactoryValueLvl(factoryLvl + 1, newBaseValue),
                    baseValue: (
                        (factoryInitBaseValue * BigInt(Math.round(factoryManagerValue.lvl_2 * 100))) /
                        BigInt(100)
                    ).toString(),
                    speed: 0,
                };

                dispatch(setFactoryValue(upgradeValue.value));
                dispatch(setFactoryBaseValue(upgradeValue.baseValue));

                stopPlayAnimation(circularAnimationName);

                dispatch(setFactoryWork(false));

                await buyManager(factoryId, 2, factoryManagerPrice.lvl_2, upgradeValue);

                // port and lab
            } else {
                const newBaseValue =
                    (factoryInitBaseValue * BigInt(Math.round(factoryManagerValue.lvl_2 * 100))) / BigInt(100);

                const upgradeValue = {
                    value: calcPortLabValueLvl(factoryLvl + 1, newBaseValue),
                    baseValue: (
                        (factoryInitBaseValue * BigInt(Math.round(factoryManagerValue.lvl_2 * 100))) /
                        BigInt(100)
                    ).toString(),
                    speed: 0,
                };

                dispatch(setFactoryValue(upgradeValue.value));
                dispatch(setFactoryBaseValue(upgradeValue.baseValue));

                dispatch(setFactoryWork(false));

                await buyManager(factoryId, 2, factoryManagerPrice.lvl_2, upgradeValue);
            }
        } else if (factoryManager === 2) {
            if (user.balance - factoryManagerPrice.lvl_3 < 0) {
                setErrorMsg("Not enough ZOMB to buy!");
                return;
            }

            if (type === "factory") {
                clearInterval(autoCollectTimer);

                const upgradeValue = {
                    value: 0,
                    baseValue: 0,
                    speed: Math.round(factoryInitSpeed / factoryManagerValue.lvl_3),
                };

                // console.log(`modal manager speed: ${upgradeValue.speed}`);

                dispatch(setFactorySpeed(upgradeValue.speed));

                const circularProgress = document.querySelectorAll(".brainFactoryCircularProgressWrapper");
                if (circularProgress) {
                    circularProgress.forEach((elem) => {
                        const elemId = elem.getAttribute("data-factory-id");

                        if (elemId == factoryId) {
                            elem.style.animation = `${circularAnimationName} ${upgradeValue.speed}ms infinite linear`;
                        }
                    });

                    stopPlayAnimation(circularAnimationName);
                }

                dispatch(setFactoryWork(false));

                await buyManager(factoryId, 3, factoryManagerPrice.lvl_3, upgradeValue);

                // port and lab
            } else {
                let newSpeed = 0;
                if (type === "port") {
                    newSpeed = Math.round(portInitSpeed / factoryManagerValue.lvl_3);
                } else {
                    newSpeed = Math.round(labInitSpeed / factoryManagerValue.lvl_3);
                }

                const upgradeValue = {
                    value: 0,
                    baseValue: 0,
                    speed: newSpeed,
                };

                // console.log(`modal manager speed: ${upgradeValue.speed}`);

                dispatch(setFactorySpeed(upgradeValue.speed));

                dispatch(setFactoryWork(false));

                await buyManager(factoryId, 3, factoryManagerPrice.lvl_3, upgradeValue);
            }
        } else if (factoryManager === 3) {
            if (user.balance - factoryManagerPrice.lvl_4 < 0) {
                setErrorMsg("Not enough ZOMB to buy!");
                return;
            }

            if (type === "factory") {
                clearInterval(autoCollectTimer);

                const upgradeValue = {
                    value: 0,
                    baseValue: 0,
                    speed: Math.round(factoryInitSpeed / factoryManagerValue.lvl_4),
                };

                // console.log(`modal manager speed: ${upgradeValue.speed}`);

                dispatch(setFactorySpeed(upgradeValue.speed));

                const circularProgress = document.querySelectorAll(".brainFactoryCircularProgressWrapper");
                if (circularProgress) {
                    circularProgress.forEach((elem) => {
                        const elemId = elem.getAttribute("data-factory-id");

                        if (elemId == factoryId) {
                            elem.style.animation = `${circularAnimationName} ${upgradeValue.speed}ms infinite linear`;
                        }
                    });

                    stopPlayAnimation(circularAnimationName);
                }

                dispatch(setFactoryWork(false));

                await buyManager(factoryId, 4, factoryManagerPrice.lvl_4, upgradeValue);

                // port and lab
            } else {
                let newSpeed = 0;
                if (type === "port") {
                    newSpeed = Math.round(portInitSpeed / factoryManagerValue.lvl_4);
                } else {
                    newSpeed = Math.round(labInitSpeed / factoryManagerValue.lvl_4);
                }

                const upgradeValue = {
                    value: 0,
                    baseValue: 0,
                    speed: newSpeed,
                };

                // console.log(`modal manager speed: ${upgradeValue.speed}`);

                dispatch(setFactorySpeed(upgradeValue.speed));

                dispatch(setFactoryWork(false));

                await buyManager(factoryId, 4, factoryManagerPrice.lvl_4, upgradeValue);
            }
        } else if (factoryManager === 4) {
            if (user.balance - factoryManagerPrice.lvl_5 < 0) {
                setErrorMsg("Not enough ZOMB to buy!");
                return;
            }

            if (type === "factory") {
                clearInterval(autoCollectTimer);

                const newBaseValue =
                    (factoryInitBaseValue * BigInt(Math.round(factoryManagerValue.lvl_5 * 100))) / BigInt(100);

                const upgradeValue = {
                    value: calcFactoryValueLvl(factoryLvl + 1, newBaseValue),
                    baseValue: (
                        (factoryInitBaseValue * BigInt(Math.round(factoryManagerValue.lvl_5 * 100))) /
                        BigInt(100)
                    ).toString(),
                    speed: 0,
                };

                dispatch(setFactoryValue(upgradeValue.value));
                dispatch(setFactoryBaseValue(upgradeValue.baseValue));

                stopPlayAnimation(circularAnimationName);

                dispatch(setFactoryWork(false));

                await buyManager(factoryId, 5, factoryManagerPrice.lvl_5, upgradeValue);

                // port and lab
            } else {
                const newBaseValue =
                    (factoryInitBaseValue * BigInt(Math.round(factoryManagerValue.lvl_5 * 100))) / BigInt(100);

                const upgradeValue = {
                    value: calcPortLabValueLvl(factoryLvl + 1, newBaseValue),
                    baseValue: (
                        (factoryInitBaseValue * BigInt(Math.round(factoryManagerValue.lvl_5 * 100))) /
                        BigInt(100)
                    ).toString(),
                    speed: 0,
                };

                dispatch(setFactoryValue(upgradeValue.value));
                dispatch(setFactoryBaseValue(upgradeValue.baseValue));

                dispatch(setFactoryWork(false));

                await buyManager(factoryId, 5, factoryManagerPrice.lvl_5, upgradeValue);
            }
        }
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={close}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                disableAutoFocus={true}
            >
                <Fade in={open}>
                    <div className="brainModalManagerWrapper">
                        <div className="brainModalManagerWrapperInternal">
                            <div className="brainModalManagerCloseImg" onClick={close}></div>

                            <div className="brainModalManagerTitle">{title}</div>

                            <div className="brainModalManagerInfoWrapper">
                                <div className="brainModalManagerInfoWrapperImg">
                                    {factoryManager === 0 ? (
                                        <div className="brainModalManagerInfoQuestionWrapper">
                                            <div className="brainModalManagerInfoQuestion">?</div>
                                        </div>
                                    ) : (
                                        <img src={managerImg} alt="manager" className="brainModalManagerInfoImg" />
                                    )}

                                    <div className="brainModalManagerInfoIconWrapper">
                                        <img src={managerIcon} alt="manager" className="brainModalManagerInfoIcon" />
                                    </div>
                                </div>

                                <div className="brainModalManagerInfoWrapperText">
                                    <div className="brainModalManagerInfoTitle">{title + " manager"}</div>
                                    {factoryManager === 0 ? (
                                        <div className="brainModalManagerInfoText">
                                            Hire a manager to automate and boost the production of this building
                                        </div>
                                    ) : (
                                        <div className="brainModalManagerInfoText">
                                            You can boost the efficiency of your factory by upgrading your manager
                                        </div>
                                    )}
                                </div>
                            </div>

                            {factoryManager !== 0 ? (
                                <div className="brainModalManagerQuote">
                                    <i>
                                        <b>{quote}</b>
                                    </i>{" "}
                                    - quotes from great managers
                                </div>
                            ) : null}

                            <div className="brainModalManagerLine brainModalManagerLine1"></div>

                            <div className="brainModalManagerValueWrapper">
                                <img src="/img/farm/clock.png" alt="clock" className="brainModalManagerValueClockImg" />
                                <div className="brainModalManagerValueText">Production speed bonus</div>
                                <div className="brainModalManagerValueText brainModalManagerValueValue">
                                    + {currentPercentValue("speed")}%
                                </div>
                            </div>

                            <div className="brainModalManagerLine brainModalManagerLine2"></div>

                            <div className="brainModalManagerValueWrapper brainModalManagerValueWrapperBoxes">
                                <img src="/img/farm/boxes.png" alt="clock" className="brainModalManagerValueClockImg" />
                                <div className="brainModalManagerValueText">Factory output bonus</div>
                                <div className="brainModalManagerValueText brainModalManagerValueValue brainModalManagerValueValueBoxes">
                                    + {currentPercentValue("boxes")}%
                                </div>
                            </div>

                            <div className="brainModalManagerLine brainModalManagerLine3"></div>

                            <div className="brainModalManagerProgressWrapper">
                                <div className="brainModalManagerProgressLvl">
                                    <div
                                        className={
                                            factoryManager >= 5
                                                ? "brainModalManagerProgressLvlItem opacity1"
                                                : "brainModalManagerProgressLvlItem opacity05"
                                        }
                                    >
                                        5 lvl
                                    </div>
                                    <div
                                        className={
                                            factoryManager >= 4
                                                ? "brainModalManagerProgressLvlItem opacity1"
                                                : "brainModalManagerProgressLvlItem opacity05"
                                        }
                                    >
                                        4 lvl
                                    </div>
                                    <div
                                        className={
                                            factoryManager >= 3
                                                ? "brainModalManagerProgressLvlItem opacity1"
                                                : "brainModalManagerProgressLvlItem opacity05"
                                        }
                                    >
                                        3 lvl
                                    </div>
                                    <div
                                        className={
                                            factoryManager >= 2
                                                ? "brainModalManagerProgressLvlItem opacity1"
                                                : "brainModalManagerProgressLvlItem opacity05"
                                        }
                                    >
                                        2 lvl
                                    </div>
                                    <div
                                        className={
                                            factoryManager >= 1
                                                ? "brainModalManagerProgressLvlItem opacity1"
                                                : "brainModalManagerProgressLvlItem opacity05"
                                        }
                                    >
                                        1 lvl
                                    </div>
                                </div>
                                <img
                                    src={
                                        factoryManager === 0
                                            ? "/img/farm/managerProgress0.png"
                                            : factoryManager === 1
                                            ? "/img/farm/managerProgress1.png"
                                            : factoryManager === 2
                                            ? "/img/farm/managerProgress2.png"
                                            : factoryManager === 3
                                            ? "/img/farm/managerProgress3.png"
                                            : factoryManager === 4
                                            ? "/img/farm/managerProgress4.png"
                                            : "/img/farm/managerProgress5.png"
                                    }
                                    alt="progress"
                                    className="brainModalManagerProgressImg"
                                />
                                <div className="brainModalManagerProgressValue">
                                    <div
                                        className={
                                            factoryManager >= 5
                                                ? "brainModalManagerProgressValueItem opacity1"
                                                : "brainModalManagerProgressValueItem opacity05"
                                        }
                                    >
                                        + {displayLvlPercent(5, factoryManagerValue)}%
                                    </div>
                                    <div
                                        className={
                                            factoryManager >= 4
                                                ? "brainModalManagerProgressValueItem opacity1"
                                                : "brainModalManagerProgressValueItem opacity05"
                                        }
                                    >
                                        + {displayLvlPercent(4, factoryManagerValue)}%
                                    </div>
                                    <div
                                        className={
                                            factoryManager >= 3
                                                ? "brainModalManagerProgressValueItem opacity1"
                                                : "brainModalManagerProgressValueItem opacity05"
                                        }
                                    >
                                        + {displayLvlPercent(3, factoryManagerValue)}%
                                    </div>
                                    <div
                                        className={
                                            factoryManager >= 2
                                                ? "brainModalManagerProgressValueItem opacity1"
                                                : "brainModalManagerProgressValueItem opacity05"
                                        }
                                    >
                                        + {displayLvlPercent(2, factoryManagerValue)}%
                                    </div>
                                    <div
                                        className={
                                            factoryManager >= 1
                                                ? "brainModalManagerProgressValueItem opacity1"
                                                : "brainModalManagerProgressValueItem opacity05"
                                        }
                                    >
                                        + {displayLvlPercent(1, factoryManagerValue)}%
                                    </div>
                                </div>
                            </div>

                            {errorMsg !== "" ? (
                                <div className="brainModalManagerErrorMsgWrapper">
                                    <WarningIcon className="brainModalManagerErrorMsgIcon" />
                                    <div className="brainModalManagerErrorMsg">{errorMsg}</div>
                                </div>
                            ) : null}

                            <div className="brainModalManagerBtnText">
                                {factoryManager === 0 ? "Hire manager" : "Upgrade to next level"}
                            </div>

                            {type === "factory" ? (
                                <LoadingButton
                                    className="brainModalManagerBtn"
                                    variant="contained"
                                    color="farmMain"
                                    onClick={updateManager}
                                    loading={buyManagerLoading}
                                    disabled={!factoryBought || factoryManager >= 5}
                                    startIcon={
                                        !buyManagerLoading ? <div className="brainModalManagerBtnIcon">Z</div> : null
                                    }
                                    sx={{
                                        "&.MuiLoadingButton-loading": {
                                            color: "transparent !important",
                                        },
                                    }}
                                >
                                    {priceUpdateManager()}
                                </LoadingButton>
                            ) : (type === "port" || type === "lab") && factoryManager === 0 ? (
                                <LoadingButton
                                    className="brainModalManagerBtn"
                                    variant="contained"
                                    color="farmMain"
                                    onClick={updateManager}
                                    loading={buyManagerLoading}
                                    disabled={portTransferProcess}
                                    startIcon={
                                        !buyManagerLoading ? <div className="brainModalManagerBtnIcon">Z</div> : null
                                    }
                                    sx={{
                                        "&.MuiLoadingButton-loading": {
                                            color: "transparent !important",
                                        },
                                    }}
                                >
                                    {priceUpdateManager()}
                                </LoadingButton>
                            ) : (type === "port" || type === "lab") && factoryManager > 0 ? (
                                <LoadingButton
                                    className="brainModalManagerBtn"
                                    variant="contained"
                                    color="farmMain"
                                    onClick={updateManager}
                                    loading={buyManagerLoading}
                                    disabled={!factoryBought || factoryManager >= 5}
                                    startIcon={
                                        !buyManagerLoading ? <div className="brainModalManagerBtnIcon">Z</div> : null
                                    }
                                    sx={{
                                        "&.MuiLoadingButton-loading": {
                                            color: "transparent !important",
                                        },
                                    }}
                                >
                                    {priceUpdateManager()}
                                </LoadingButton>
                            ) : null}
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

export default ModalManager;
