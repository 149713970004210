import "./Docs.scss";
import { Link } from "react-router-dom";

import Container from "@mui/material/Container";

const Docs = () => {
    return (
        <Container className="pveDocsWrapper">
            <div className="pveBalanceDepositTitle pveDocsTitle">Documentation</div>

            <div className="pveDocsLinksWrapper">
                <Link className="pveDocsLink" to="/docs/instruction">
                    <div className="pveDocsLinkContent">
                        <img className="pveDocsImg" src="/img/user-guide.png" alt="user guide" />
                        <div className="pveDocsLinkTitle">Instruction</div>
                    </div>
                </Link>
                <Link className="pveDocsLink" to="/docs/instructionPve">
                    <div className="pveDocsLinkContent">
                        <img className="pveDocsImg" src="/img/pve-mode-doc.png" alt="pve mode guide" />
                        <div className="pveDocsLinkTitle">PvE mode guide</div>
                    </div>
                </Link>
                <Link className="pveDocsLink" to="/docs/instructionBrainFarm">
                    <div className="pveDocsLinkContent">
                        <img className="pveDocsImg" src="/img/brain-farm-doc.png" alt="brain farm guide" />
                        <div className="pveDocsLinkTitle">Brain farm guide</div>
                    </div>
                </Link>

                <div className="pveDocsBreak"></div>

                <Link className="pveDocsLink" to="/docs/rules">
                    <div className="pveDocsLinkContent">
                        <img className="pveDocsImg" src="/img/book.png" alt="book" />
                        <div className="pveDocsLinkTitle">Rules of use</div>
                    </div>
                </Link>
                <Link className="pveDocsLink" to="/docs/faq">
                    <div className="pveDocsLinkContent">
                        <img className="pveDocsImg" src="/img/faq.png" alt="faq" />
                        <div className="pveDocsLinkTitle">FAQ</div>
                    </div>
                </Link>
                <Link className="pveDocsLink" to="/docs/lotteryRules">
                    <div className="pveDocsLinkContent">
                        <img className="pveDocsImg" src="/img/lottery.png" alt="lottery" />
                        <div className="pveDocsLinkTitle">Lottery rules</div>
                    </div>
                </Link>

                <div className="pveDocsBreak"></div>

                <Link className="pveDocsLink" to="/docs/lotteryHistory">
                    <div className="pveDocsLinkContent">
                        <img className="pveDocsImg" src="/img/ticket.png" alt="tickets" />
                        <div className="pveDocsLinkTitle">Lottery history</div>
                    </div>
                </Link>
                <Link className="pveDocsLink" to="/docs/faucet">
                    <div className="pveDocsLinkContent">
                        <img className="pveDocsImg" src="/img/faucet.png" alt="faucet" />
                        <div className="pveDocsLinkTitle">Faucet rules</div>
                    </div>
                </Link>
                <Link className="pveDocsLink" to="/docs/referralProgram">
                    <div className="pveDocsLinkContent">
                        <img className="pveDocsImg" src="/img/referral.png" alt="referral" />
                        <div className="pveDocsLinkTitle">Referral program rules</div>
                    </div>
                </Link>
            </div>
        </Container>
    );
};

export default Docs;
