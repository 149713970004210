import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        isAuth: false,
        loading: false,
        errorLoginForm: false,
    },
    reducers: {
        setIsAuth(state, action) {
            state.isAuth = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setErrorLoginForm(state, action) {
            state.errorLoginForm = action.payload;
        },
    },
});

export const { setIsAuth, setLoading, setErrorLoginForm } = authSlice.actions;

export default authSlice.reducer;
