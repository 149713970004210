import axios from "axios";
import https from "https";

const httpsAgent = new https.Agent({ rejectUnauthorized: false });

// const baseUrl = process.env.REACT_APP_BASEURL_TEST;
const baseUrl = process.env.REACT_APP_BASEURL;

const myServer = axios.create({
    baseURL: baseUrl,
    headers: { Authorization: "Bearer " + process.env.REACT_APP_AUTH },
    httpsAgent: httpsAgent,
});

export { myServer, baseUrl };
