import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
                variant="determinate"
                {...props}
                sx={{ width: "100px !important", height: "100px !important" }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="primary"
                    sx={{ fontSize: "1.75rem" }}
                >
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

const ProgressCircularPercent = () => {
    const [progress, setProgress] = useState(1);

    useEffect(() => {
        const currentDate = new Date();

        const m = currentDate.getUTCMinutes();
        const s = currentDate.getUTCSeconds();

        const currentPercent = Math.round(((m * 60 + s) * 1000) / 36000);

        setProgress(currentPercent);

        const timer = setInterval(() => {
            setProgress((prevProgress) =>
                prevProgress >= 100 ? 0 : prevProgress + 1
            );
        }, 36000); // 100% it is 1 hour
        return () => {
            clearInterval(timer);
        };
    }, []);

    return <CircularProgressWithLabel value={progress} />;
};

export default ProgressCircularPercent;
