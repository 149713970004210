import "./ProgressCountdown.scss";

import React from "react";
import Countdown from "react-countdown";

const ProgressCountdown = ({ base, deadline, hideBar, faucet }) => {
    const percentage =
        Date.now() >= deadline.getTime()
            ? 100
            : ((Date.now() - base.getTime()) /
                  (deadline.getTime() - base.getTime())) *
              100;

    const countdownRenderer = (countdownProps) => {
        const { days, hours, minutes, seconds } = countdownProps;
        const h = String(days * 24 + hours);
        const m = String(minutes);
        const s = String(seconds);
        return faucet ? (
            <div className="pveCountdown">
                {m.padStart(2, "0")}:{s.padStart(2, "0")}
            </div>
        ) : (
            <div className="pveCountdown">
                {h.padStart(2, "0")}:{m.padStart(2, "0")}:{s.padStart(2, "0")}
            </div>
        );
    };
    return (
        <div className="pveCardContentInner">
            <Countdown
                key={new Date().getTime()}
                date={deadline}
                renderer={countdownRenderer}
            />
            {hideBar ? (
                ""
            ) : (
                <div className="pveProgressOuter">
                    <div className="pveProgress" progress={percentage}></div>
                </div>
            )}
        </div>
    );
};

export default ProgressCountdown;
