import { useSelector } from "react-redux";

import LoginRegister from "../loginRegister/LoginRegister";
import BrainFarm from "../brainFarm/BrainFarm";
import MobileMessage from "../mobileMessage/MobileMessage";

import Container from "@mui/material/Container";

const BrainFarmPage = () => {
    const isAuth = useSelector((state) => state.authorization.isAuth);

    return (
        <>
            <Container>
                {!isAuth ? <LoginRegister /> : <BrainFarm />}
                <MobileMessage />
            </Container>
        </>
    );
};

export default BrainFarmPage;
