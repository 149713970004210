/* global BigInt */
import "./ModalComplete.scss";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";
import { baseUrl } from "../../axios/axios";

import { setBalanceForFarm } from "../../../store/userSlice";
import { setGameRunning } from "../../../store/brainFarmSlice";

import { levelLinePercentTasks, abbreviationNumbers } from "../functions";

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import WarningIcon from "@mui/icons-material/Warning";

const ModalComplete = (props) => {
    const { showModalComplete, handleCloseModalComplete } = props;

    const [completeLoading, setCompleteLoading] = useState(false);
    const [typeReward, setTypeReward] = useState("");
    const [reward, setReward] = useState(0);
    const [gameId, setGameId] = useState("");
    const [endInfo, setEndInfo] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const dispatch = useDispatch();

    const user = useSelector((state) => state.user.user);
    const userEmail = user.userEmail;

    const warehouse_main = useSelector((state) => state.brainFarm.warehouse_main);

    const spent = useSelector((state) => state.brainFarm.spent);

    const brainsForComplete = useSelector((state) => state.brainFarmRewardsTasks.brainsForComplete);
    const turnoverForComplete = useSelector((state) => state.brainFarmRewardsTasks.turnoverForComplete);

    const bronzeRewardShare = useSelector((state) => state.brainFarmRewardsTasks.bronzeRewardShare);
    const silverRewardShare = useSelector((state) => state.brainFarmRewardsTasks.silverRewardShare);
    const goldRewardShare = useSelector((state) => state.brainFarmRewardsTasks.goldRewardShare);
    const bronzeRewardC = useSelector((state) => state.brainFarmRewardsTasks.bronzeRewardC);
    const silverRewardC = useSelector((state) => state.brainFarmRewardsTasks.silverRewardC);
    const goldRewardC = useSelector((state) => state.brainFarmRewardsTasks.goldRewardC);

    const bronzePercent = Math.round(bronzeRewardShare * 100 * 100) / 100;
    const silverPercent = Math.round(silverRewardShare * 100 * 100) / 100;
    const goldPercent = Math.round(goldRewardShare * 100 * 100) / 100;
    const nothingPercent = Math.round((100 - (bronzePercent + silverPercent + goldPercent)) * 100) / 100;

    const bronzeTokenReward = Math.round(spent * bronzeRewardC);
    const silverTokenReward = Math.round(spent * silverRewardC);
    const goldTokenReward = Math.round(spent * goldRewardC);

    let urlEndWindowImg = "";
    let classColorTextEndWindow = "brainModalCompleteEndWindowRewardValue";
    if (typeReward === "bronze") {
        urlEndWindowImg = "/img/farm/coinBronze.png";
        classColorTextEndWindow = "brainModalCompleteEndWindowRewardValue brainModalTasksAwardValueTextBronze";
    } else if (typeReward === "silver") {
        urlEndWindowImg = "/img/farm/coinSilver.png";
        classColorTextEndWindow = "brainModalCompleteEndWindowRewardValue brainModalTasksAwardValueTextSilver";
    } else if (typeReward === "gold") {
        urlEndWindowImg = "/img/farm/coinGold.png";
        classColorTextEndWindow = "brainModalCompleteEndWindowRewardValue brainModalTasksAwardValueTextGold";
    } else if (typeReward === "nothing") {
        urlEndWindowImg = "/img/farm/skull100.png";
        classColorTextEndWindow = "brainModalCompleteEndWindowRewardValue brainModalCompleteEndWindowNothingTextColor";
    }

    // set axios request
    const brainFarmCompleteGame = axios.create({
        withCredentials: true,
        baseURL: baseUrl,
    });
    brainFarmCompleteGame.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
        return config;
    });
    brainFarmCompleteGame.interceptors.response.use(
        (config) => {
            return config;
        },
        async (error) => {
            const originalRequest = error.config;

            if (error.response.status === 401 && error.config && !error.config._isRetry) {
                originalRequest._isRetry = true;

                setCompleteLoading(true);
                setErrorMsg("");

                axios
                    .get(`${baseUrl}/refresh`, {
                        withCredentials: true,
                    })
                    .then((res) => {
                        localStorage.setItem("token", res.data.accessToken);

                        brainFarmCompleteGame
                            .request(originalRequest)
                            .then((res2) => {
                                setTypeReward(res2.data.rewardType);
                                setReward(res2.data.reward);
                                setGameId(res2.data.gameId);
                                dispatch(setBalanceForFarm(res2.data.newBalance));
                                dispatch(setGameRunning(false));

                                setCompleteLoading(false);
                                setEndInfo(true);
                            })
                            .catch((e) => {
                                console.log(e.response?.data?.message);
                                setErrorMsg(e.response?.data?.message);

                                setCompleteLoading(false);
                            });
                    })
                    .catch((e) => {
                        console.log("You not authorize");

                        setCompleteLoading(false);
                    });
            } else {
                throw error;
            }
        }
    );

    let currentBrains,
        currentTurnover = 0;

    if (BigInt(warehouse_main) >= BigInt(brainsForComplete)) {
        currentBrains = BigInt(brainsForComplete);
    } else {
        currentBrains = BigInt(warehouse_main);
    }

    // console.log(currentBrains);
    // console.log(brainsForComplete);

    if (user.turnover >= turnoverForComplete) {
        currentTurnover = turnoverForComplete;
    } else {
        currentTurnover = user.turnover;
    }

    const completeGame = () => {
        setErrorMsg("");
        setCompleteLoading(true);

        if (BigInt(warehouse_main) < BigInt(brainsForComplete)) {
            setErrorMsg("Not enough brains");
            setCompleteLoading(false);
            setTimeout(() => {
                setErrorMsg("");
            }, 5000);
            return;
        }
        if (user.turnover <= turnoverForComplete) {
            setErrorMsg("Not enough turnover PvE");
            setCompleteLoading(false);
            setTimeout(() => {
                setErrorMsg("");
            }, 5000);
            return;
        }

        const endData = {
            warehouseMain: warehouse_main,
            turnover: user.turnover,
            spent: spent,
            email: userEmail,
            rewards: {
                bronzeRewardShare: bronzeRewardShare,
                silverRewardShare: silverRewardShare,
                goldRewardShare: goldRewardShare,
            },
        };

        brainFarmCompleteGame
            .post("/brainFarmEndGame", { endData })
            .then((res) => {
                // console.log(res.data);
                if (res !== undefined) {
                    // console.log(res.data);

                    setTypeReward(res.data.rewardType);
                    setReward(res.data.reward);
                    setGameId(res.data.gameId);
                    dispatch(setBalanceForFarm(res.data.newBalance));
                    dispatch(setGameRunning(false));

                    setCompleteLoading(false);
                    setEndInfo(true);
                }
            })
            .catch((e) => {
                console.log(e.response?.data?.message);
                setErrorMsg(e.response?.data?.message);

                setCompleteLoading(false);
            });
    };

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showModalComplete}
                onClose={handleCloseModalComplete}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                disableAutoFocus={true}
                sx={{
                    width: "calc(100% - 15px)",
                }}
            >
                <Fade in={showModalComplete}>
                    <div className="brainModalCompleteWrapper">
                        <div className="brainModalCompleteWrapperInternal">
                            <div className="brainModalManagerCloseImg" onClick={handleCloseModalComplete}></div>

                            <div className="brainModalManagerTitle">Complete the cemetery</div>

                            {!completeLoading && !endInfo ? (
                                <>
                                    <div className="brainModalCompleteRequiresWrapper">
                                        <div className="brainModalCompleteRequiresTitle">Completion requires</div>

                                        <div className="brainModalCompleteRequiresItem">
                                            <div className="brainModalCompleteRequiresItemCheckImgWrapper">
                                                {currentBrains >= BigInt(brainsForComplete) ? (
                                                    <img
                                                        src="/img/farm/check.png"
                                                        alt="check"
                                                        className="brainModalCompleteRequiresItemCheckImg"
                                                    />
                                                ) : (
                                                    <img
                                                        src="/img/farm/notCheck.png"
                                                        alt="not check"
                                                        className="brainModalCompleteRequiresItemNoCheckImg"
                                                    />
                                                )}
                                            </div>
                                            <img
                                                src="/img/farm/brainIcon.png"
                                                alt="brain"
                                                className="brainModalCompleteRequiresItemNameIcon"
                                            />
                                            <div className="brainModalCompleteRequiresItemName">Brains</div>
                                            <div className="brainFactoryProgressBarLine brainModalCompleteRequiresItemProgressBarLine brainModalCompleteRequiresItemProgressBarLineBrains">
                                                <div
                                                    className="brainFactoryProgressBarOuter"
                                                    style={{
                                                        width: `${
                                                            140 *
                                                            levelLinePercentTasks(
                                                                currentBrains,
                                                                BigInt(brainsForComplete)
                                                            )
                                                        }px`,
                                                    }}
                                                ></div>
                                                <div className="brainModalTasksItemProgressText brainModalCompleteRequiresItemProgressText">{`${abbreviationNumbers(
                                                    currentBrains
                                                )} / ${abbreviationNumbers(brainsForComplete)}`}</div>
                                            </div>
                                        </div>

                                        <div className="brainModalCompleteRequiresItem">
                                            <div className="brainModalCompleteRequiresItemCheckImgWrapper">
                                                {currentTurnover >= turnoverForComplete ? (
                                                    <img
                                                        src="/img/farm/check.png"
                                                        alt="check"
                                                        className="brainModalCompleteRequiresItemCheckImg"
                                                    />
                                                ) : (
                                                    <img
                                                        src="/img/farm/notCheck.png"
                                                        alt="not check"
                                                        className="brainModalCompleteRequiresItemNoCheckImg"
                                                    />
                                                )}
                                            </div>
                                            <div className="brainModalCompleteRequiresItemNameIconText">Z</div>
                                            <div className="brainModalCompleteRequiresItemName">Turnover</div>
                                            <div className="brainFactoryProgressBarLine brainModalCompleteRequiresItemProgressBarLine">
                                                <div
                                                    className="brainFactoryProgressBarOuter"
                                                    style={{
                                                        width: `${
                                                            140 *
                                                            levelLinePercentTasks(currentTurnover, turnoverForComplete)
                                                        }px`,
                                                    }}
                                                ></div>
                                                <div className="brainModalTasksItemProgressText brainModalCompleteRequiresItemProgressText">{`${currentTurnover} / ${turnoverForComplete}`}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="brainModalCompleteLine"></div>

                                    <div className="brainModalCompleteRewardTitle">Possible reward</div>
                                    <div className="brainModalCompleteRewardSubtitle">
                                        Complete tasks to increase your chances of getting a reward
                                    </div>

                                    <div className="brainModalCompleteRewardValueWrapper">
                                        <div className="brainModalCompleteRewardValueItem">
                                            <div className="brainModalCompleteRewardValueItemRowWrapper">
                                                <img
                                                    src="/img/farm/skull100.png"
                                                    alt="skull"
                                                    className="brainModalCompleteRewardValueImg"
                                                />
                                                <div
                                                    className="brainModalCompleteRewardValuePercent"
                                                    style={{ color: "#4d4d4d" }}
                                                >
                                                    {nothingPercent}%
                                                </div>
                                            </div>
                                            <div
                                                className="brainModalCompleteRewardValueZ"
                                                style={{ color: "#4d4d4d" }}
                                            >
                                                nothing :(
                                            </div>
                                        </div>
                                        <div className="brainModalCompleteRewardValueItem">
                                            <div className="brainModalCompleteRewardValueItemRowWrapper">
                                                <img
                                                    src="/img/farm/coinBronze.png"
                                                    alt="z coin bronze"
                                                    className="brainModalCompleteRewardValueImg"
                                                />
                                                <div className="brainModalCompleteRewardValuePercent brainModalTasksAwardValueTextBronze">
                                                    {bronzePercent}%
                                                </div>
                                            </div>
                                            <div className="brainModalCompleteRewardValueZ brainModalTasksAwardValueTextBronze">
                                                {bronzeTokenReward} <span>ZOMB</span>
                                            </div>
                                        </div>
                                        <div className="brainModalCompleteRewardValueItem">
                                            <div className="brainModalCompleteRewardValueItemRowWrapper">
                                                <img
                                                    src="/img/farm/coinSilver.png"
                                                    alt="z coin silver"
                                                    className="brainModalCompleteRewardValueImg"
                                                />
                                                <div className="brainModalCompleteRewardValuePercent brainModalTasksAwardValueTextSilver">
                                                    {silverPercent}%
                                                </div>
                                            </div>
                                            <div className="brainModalCompleteRewardValueZ brainModalTasksAwardValueTextSilver">
                                                {silverTokenReward} <span>ZOMB</span>
                                            </div>
                                        </div>
                                        <div className="brainModalCompleteRewardValueItem">
                                            <div className="brainModalCompleteRewardValueItemRowWrapper">
                                                <img
                                                    src="/img/farm/coinGold.png"
                                                    alt="z coin gold"
                                                    className="brainModalCompleteRewardValueImg"
                                                />
                                                <div className="brainModalCompleteRewardValuePercent brainModalTasksAwardValueTextGold">
                                                    {goldPercent}%
                                                </div>
                                            </div>
                                            <div className="brainModalCompleteRewardValueZ brainModalTasksAwardValueTextGold">
                                                {goldTokenReward} <span>ZOMB</span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : !completeLoading && endInfo ? (
                                <div className="brainModalCompleteEndWindowWrapper">
                                    <div className="brainModalCompleteEndWindowTitle">Your reward</div>
                                    <img
                                        src={urlEndWindowImg}
                                        alt="z coin"
                                        className="brainModalCompleteEndWindowImg"
                                    />
                                    <div className={classColorTextEndWindow}>
                                        {reward} <span>ZOMB</span>
                                    </div>
                                    <div className="brainModalCompleteEndWindowId">Game ID: {gameId}</div>
                                </div>
                            ) : completeLoading ? (
                                <div className="brainModalCompleteSpinnerWrapper">
                                    <CircularProgress color="secondary" className="brainModalCompleteSpinner" />
                                </div>
                            ) : null}

                            {errorMsg !== "" ? (
                                <div className="brainModalManagerErrorMsgWrapper">
                                    <WarningIcon className="brainModalManagerErrorMsgIcon" />
                                    <div className="brainModalManagerErrorMsg">{errorMsg}</div>
                                </div>
                            ) : null}

                            <LoadingButton
                                className="brainModalManagerBtn brainModalCompleteBtn"
                                variant="contained"
                                color="farmMain"
                                loading={completeLoading}
                                disabled={endInfo}
                                onClick={completeGame}
                                sx={{
                                    "&.MuiLoadingButton-loading": {
                                        color: "transparent !important",
                                    },
                                }}
                            >
                                Complete
                            </LoadingButton>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export default ModalComplete;
