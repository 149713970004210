import { Container } from "@mui/material";

const ReferralRules = () => {
    return (
        <Container>
            <div className="pveRulesTitle">Referral rules</div>
            <div className="pveRulesWrapper">
                <div className="pveRulesItem">
                    1. Any account can participate in the referral program.
                </div>
                <div className="pveRulesItem">
                    2. When registering a new account, enter your referral code.
                    If you have not entered your referral code when registering,
                    you can contact support to have a referral assigned to you.
                    If you have already entered your referral code when
                    registering, you cannot change it.
                </div>
                <div className="pveRulesItem">
                    3. The standard terms of the referral program are 3%. This
                    means that you will receive 3% of all bets of your invited
                    person. <br />
                    Example: your invited person made 3 bets, 10 ZOMB, 20 ZOMB,
                    50 ZOMB. It does not matter whether the player won or lost.
                    You as the one who invited him get 10 * 0.03 + 20 * 0.03 +
                    50 * 0.03 = 0.3 + 0.6 + 1.5 = 2.4 ZOMB. If you have any
                    social networks with many subscribers individual % on the
                    referral program is possible. In this case, please contact
                    support on this issue.
                </div>
                <div className="pveRulesItem">
                    4. Funds received under the referral program are credited to
                    a separate account. You can transfer them to the main
                    account at any time, except for the time when referral
                    rewards are calculated.
                </div>
                <div className="pveRulesItem">
                    5. Referral rewards are calculated daily between 01:00 UTC
                    and 02:00 UTC for the previous day. At this time the history
                    of your rewards is also calculated.
                </div>
                <div className="pveRulesItem">
                    6. If the referral program is abused in any way, it may be
                    disabled for a particular account without explanation from
                    the administration.
                </div>
            </div>
        </Container>
    );
};

export default ReferralRules;
