/* global BigInt */
import "./Factory.scss";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    setDecreaseWarehouseMain,
    setTransportWarehouseFactories,
    setWarehousePort,
    setPort_value,
    setPort_base_value,
    setPort_lvl,
    setPort_price_lvl_up,
    setPort_work,
    setPort_manager_lvl_up,
    setPort_prod_ready,
    setPort_transfer_process,
    setPort_current_transport,
    setPort_speed,
    setPort_path_point,
} from "../../../store/brainFarmSlice";

import ModalManager from "./ModalManager";
import {
    stopPlayAnimation,
    disableFactoryCursorPointer,
    multipleAnimation,
    circularAnimation1Time,
    levelLinePercentPortLab,
    cursorPointer,
    openModal,
    closeModal,
    abbreviationNumbers,
} from "../functions";

import Button from "@mui/material/Button";

const Port = (props) => {
    const { factoryId, saveIntervalId, saveInterval, circularAnimationName, shakeAnimationName, positionClass } = props;

    const dispatch = useDispatch();

    const intervalHandProdRef = useRef(0);
    const intervalShakeRef = useRef(0);
    const intervalAutoProdRef = useRef(0);
    const intervalTransfer1 = useRef(0);
    const intervalTransfer2 = useRef(0);

    const [showModalManager, setShowModalManager] = useState(false);
    const [factoryMultipleLvl, setFactoryMultipleLvl] = useState(2);

    const handleCloseModalManager = () => {
        setShowModalManager(false);
        closeModal();
    };
    const handleShowModalManager = () => {
        setShowModalManager(true);
        openModal();
    };

    const warehouse_main = useSelector((state) => state.brainFarm.warehouse_main);
    const warehouseFactories = useSelector((state) => state.brainFarm.warehouse_factories);

    const saveLoaded = useSelector((state) => state.brainFarm.saveLoaded);

    const port_c_lvl_up = useSelector((state) => state.brainFarm.port_c_lvl_up);

    const port_value = useSelector((state) => state.brainFarm.port_value);
    const port_base_value = useSelector((state) => state.brainFarm.port_base_value);
    const port_init_base_value = useSelector((state) => state.brainFarm.port_init_base_value);
    const port_lvl = useSelector((state) => state.brainFarm.port_lvl);
    const port_speed = useSelector((state) => state.brainFarm.port_speed);
    const port_price_lvl_up = useSelector((state) => state.brainFarm.port_price_lvl_up);
    const port_work = useSelector((state) => state.brainFarm.port_work);
    const port_manager_lvl = useSelector((state) => state.brainFarm.port_manager_lvl);
    const port_manager_price = useSelector((state) => state.brainFarm.port_manager_price);
    const port_manager_value = useSelector((state) => state.brainFarm.port_manager_value);
    const port_bought = useSelector((state) => state.brainFarm.port_bought);
    const port_prod_ready = useSelector((state) => state.brainFarm.port_prod_ready);

    const portTransferProcess = useSelector((state) => state.brainFarm.port_transfer_process);
    const portCurrentTransport = useSelector((state) => state.brainFarm.port_current_transport);
    const portPathPoint = useSelector((state) => state.brainFarm.port_path_point);

    let factoryValue,
        factoryBaseValue,
        factoryInitBaseValue,
        factoryLvl,
        factorySpeed,
        factoryPriceLvlUp,
        factoryWork,
        factoryManager,
        factoryManagerPrice,
        factoryManagerValue,
        factoryBought,
        factoryProdReady,
        setFactoryWork,
        setFactoryLvl,
        setFactoryValue,
        setFactoryBaseValue,
        setFactoryPriceLvlUp,
        setFactoryManagerLvlUp,
        setFactoryProdReady,
        setFactorySpeed = 0;

    if (factoryId === 100) {
        factoryValue = BigInt(port_value);
        factoryBaseValue = BigInt(port_base_value);
        factoryInitBaseValue = BigInt(port_init_base_value);
        factoryLvl = port_lvl;
        factorySpeed = port_speed;
        factoryPriceLvlUp = BigInt(port_price_lvl_up);
        factoryWork = port_work;
        factoryManager = port_manager_lvl;
        factoryManagerPrice = port_manager_price;
        factoryManagerValue = port_manager_value;
        factoryBought = port_bought;
        factoryProdReady = port_prod_ready;

        setFactoryWork = (action) => {
            return setPort_work(action);
        };
        setFactoryLvl = (action) => {
            return setPort_lvl(action);
        };
        setFactoryValue = (action) => {
            return setPort_value(action);
        };
        setFactoryBaseValue = (action) => {
            return setPort_base_value(action);
        };
        setFactoryPriceLvlUp = (action) => {
            return setPort_price_lvl_up(action);
        };
        setFactoryManagerLvlUp = (action) => {
            return setPort_manager_lvl_up(action);
        };
        setFactoryProdReady = (action) => {
            return setPort_prod_ready(action);
        };
        setFactorySpeed = (action) => {
            return setPort_speed(action);
        };
    }

    const managerImg = "/img/farm/portManager200.png";
    const mainIcon = "/img/farm/creepyShip100.png";

    const factoryBuy = () => {
        // shake animation for hand collection
        const timerProdId = setTimeout(() => {
            // launch shake animation after hand collection
            stopPlayAnimation(shakeAnimationName);

            dispatch(setFactoryProdReady(true));

            const circleWrapper = document.querySelectorAll(".brainFactoryCircleWrapper");
            if (circleWrapper) {
                circleWrapper.forEach((elem) => {
                    const elemId = elem.getAttribute("data-factory-id");

                    if (elemId == factoryId) {
                        elem.style.animation = `${shakeAnimationName} 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both`;
                        elem.style.cursor = "pointer";

                        clearInterval(intervalShakeRef.current);

                        const timerIdShakeAnim = setInterval(() => {
                            stopPlayAnimation(shakeAnimationName);
                        }, 4000);

                        intervalShakeRef.current = timerIdShakeAnim;
                    }
                });
            }

            circularAnimation1Time(factoryId, circularAnimationName, 2, stopPlayAnimation);

            dispatch(setPort_transfer_process(false));
        }, factorySpeed);
        intervalHandProdRef.current = timerProdId;
    };

    const factoryHandCollect = () => {
        if (factoryProdReady && !factoryWork) {
            clearInterval(intervalShakeRef.current);

            dispatch(setFactoryProdReady(false));

            circularAnimation1Time(factoryId, circularAnimationName, factorySpeed, stopPlayAnimation);

            // dot animation 1 time
            const dotPath = document.querySelector(".brainShipPathDot");
            if (dotPath) {
                dotPath.style.animation = `ship-path ${factorySpeed}ms linear forwards`;

                stopPlayAnimation("ship-path");
            }

            disableFactoryCursorPointer(factoryId);

            dispatch(setPort_transfer_process(true));

            transportGoods();

            factoryBuy();
        }

        // first button press
        if (!factoryProdReady && !factoryWork && !portTransferProcess && factoryManager === 0) {
            circularAnimation1Time(factoryId, circularAnimationName, factorySpeed, stopPlayAnimation);

            // dot animation 1 time
            const dotPath = document.querySelector(".brainShipPathDot");
            if (dotPath) {
                dotPath.style.animation = `ship-path ${factorySpeed}ms linear forwards`;

                stopPlayAnimation("ship-path");
            }

            disableFactoryCursorPointer(factoryId);

            dispatch(setPort_transfer_process(true));

            transportGoods();

            factoryBuy();
        }
    };

    const transportGoods = () => {
        const timerTransferId = setTimeout(() => {
            dispatch(setPort_path_point("port point end"));

            const timerTransferId2 = setTimeout(() => {
                dispatch(setPort_path_point("port point start"));
            }, factorySpeed / 2);

            intervalTransfer2.current = timerTransferId2;
        }, factorySpeed / 2);

        intervalTransfer1.current = timerTransferId;
    };

    useEffect(() => {
        if (portPathPoint === "port point end") {
            const delta = BigInt(warehouseFactories) - factoryValue;
            let newWarehouseFactories = BigInt(0);

            if (delta >= BigInt(0)) {
                dispatch(setPort_current_transport(factoryValue.toString()));
                newWarehouseFactories = delta;
            } else if (delta < BigInt(0)) {
                dispatch(setPort_current_transport(warehouseFactories));
            } else if (BigInt(warehouseFactories) === BigInt(0)) {
                dispatch(setPort_current_transport("0"));
            }

            dispatch(setTransportWarehouseFactories(newWarehouseFactories.toString()));
        } else if (portPathPoint === "port point start") {
            dispatch(setWarehousePort(portCurrentTransport));
            dispatch(setPort_current_transport("0"));
        }
    }, [portPathPoint]);

    const factoryPlusProduct = () => {
        circularAnimation1Time(factoryId, circularAnimationName, factorySpeed, stopPlayAnimation);

        // dot animation 1 time
        const dotPath = document.querySelector(".brainShipPathDot");
        if (dotPath) {
            dotPath.style.animation = `ship-path ${factorySpeed}ms linear forwards`;

            stopPlayAnimation("ship-path");
        }

        dispatch(setPort_transfer_process(true));

        transportGoods();
    };

    // buy managers
    useEffect(() => {
        if (!factoryWork && factoryManager > 0) {
            const timerIdFactory = setInterval(factoryPlusProduct, factorySpeed);
            intervalAutoProdRef.current = timerIdFactory;

            dispatch(setPort_path_point("port point start"));
            factoryPlusProduct();

            dispatch(setFactoryWork(true));
        }

        return () => {
            clearInterval(intervalAutoProdRef.current);
            clearInterval(intervalTransfer1.current);
            clearInterval(intervalTransfer2.current);
        };
    }, [factoryManager]);

    const factoryPlusLvl = () => {
        dispatch(setFactoryLvl(1));

        if (factoryLvl < 9) {
            dispatch(setFactoryValue((factoryValue + factoryBaseValue).toString()));
        } else if (factoryLvl === 9) {
            dispatch(setFactoryValue(((factoryValue + factoryBaseValue) * BigInt(2)).toString()));
            setFactoryMultipleLvl(2);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 9 && factoryLvl < 24) {
            dispatch(setFactoryValue((factoryValue + factoryBaseValue * BigInt(2)).toString()));
        } else if (factoryLvl === 24) {
            dispatch(setFactoryValue(((factoryValue + factoryBaseValue * BigInt(2)) * BigInt(4)).toString()));
            setFactoryMultipleLvl(4);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 24 && factoryLvl < 49) {
            dispatch(setFactoryValue((factoryValue + factoryBaseValue * BigInt(2) * BigInt(4)).toString()));
        } else if (factoryLvl === 49) {
            dispatch(
                setFactoryValue(((factoryValue + factoryBaseValue * BigInt(2) * BigInt(4)) * BigInt(8)).toString())
            );
            setFactoryMultipleLvl(8);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 49 && factoryLvl < 99) {
            dispatch(setFactoryValue((factoryValue + factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8)).toString()));
        } else if (factoryLvl === 99) {
            dispatch(
                setFactoryValue(
                    ((factoryValue + factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8)) * BigInt(16)).toString()
                )
            );
            setFactoryMultipleLvl(16);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 99 && factoryLvl < 149) {
            dispatch(
                setFactoryValue(
                    (factoryValue + factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16)).toString()
                )
            );
        } else if (factoryLvl === 149) {
            dispatch(
                setFactoryValue(
                    (
                        (factoryValue + factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16)) *
                        BigInt(32)
                    ).toString()
                )
            );
            setFactoryMultipleLvl(32);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 149 && factoryLvl < 199) {
            dispatch(
                setFactoryValue(
                    (
                        factoryValue +
                        factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16) * BigInt(32)
                    ).toString()
                )
            );
        } else if (factoryLvl === 199) {
            dispatch(
                setFactoryValue(
                    (
                        (factoryValue +
                            factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16) * BigInt(32)) *
                        BigInt(64)
                    ).toString()
                )
            );
            setFactoryMultipleLvl(64);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 199 && factoryLvl < 249) {
            dispatch(
                setFactoryValue(
                    (
                        factoryValue +
                        factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16) * BigInt(32) * BigInt(64)
                    ).toString()
                )
            );
        } else if (factoryLvl === 249) {
            dispatch(
                setFactoryValue(
                    (
                        (factoryValue +
                            factoryBaseValue *
                                BigInt(2) *
                                BigInt(4) *
                                BigInt(8) *
                                BigInt(16) *
                                BigInt(32) *
                                BigInt(64)) *
                        BigInt(4)
                    ).toString()
                )
            );
            setFactoryMultipleLvl(4);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 249 && factoryLvl < 299) {
            dispatch(
                setFactoryValue(
                    (
                        factoryValue +
                        factoryBaseValue *
                            BigInt(2) *
                            BigInt(4) *
                            BigInt(8) *
                            BigInt(16) *
                            BigInt(32) *
                            BigInt(64) *
                            BigInt(4)
                    ).toString()
                )
            );
        } else if (factoryLvl === 299) {
            dispatch(
                setFactoryValue(
                    (
                        (factoryValue +
                            factoryBaseValue *
                                BigInt(2) *
                                BigInt(4) *
                                BigInt(8) *
                                BigInt(16) *
                                BigInt(32) *
                                BigInt(64) *
                                BigInt(4)) *
                        BigInt(4)
                    ).toString()
                )
            );
            setFactoryMultipleLvl(4);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 299) {
            dispatch(
                setFactoryValue(
                    (
                        factoryValue +
                        factoryBaseValue *
                            BigInt(2) *
                            BigInt(4) *
                            BigInt(8) *
                            BigInt(16) *
                            BigInt(32) *
                            BigInt(64) *
                            BigInt(4) *
                            BigInt(4)
                    ).toString()
                )
            );
        }

        dispatch(
            setFactoryPriceLvlUp(
                ((factoryPriceLvlUp * BigInt(Math.round(port_c_lvl_up * 100))) / BigInt(100)).toString()
            )
        );
        dispatch(setDecreaseWarehouseMain(factoryPriceLvlUp.toString()));
    };

    // if switch between page on site
    useEffect(() => {
        // ready hand collect
        if (factoryProdReady) {
            const circleWrapper = document.querySelectorAll(".brainFactoryCircleWrapper");
            if (circleWrapper) {
                circleWrapper.forEach((elem) => {
                    const elemId = elem.getAttribute("data-factory-id");

                    if (elemId == factoryId) {
                        elem.style.animation = `${shakeAnimationName} 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both`;
                        elem.style.cursor = "pointer";

                        clearInterval(intervalShakeRef.current);

                        const timerIdShakeAnim = setInterval(() => {
                            stopPlayAnimation(shakeAnimationName);
                        }, 4000);

                        intervalShakeRef.current = timerIdShakeAnim;
                    }
                });
            }

            circularAnimation1Time(factoryId, circularAnimationName, 2, stopPlayAnimation);
        }

        if (!factoryWork && factoryManager === 0) {
            cursorPointer(factoryId);
        }

        return () => {
            clearInterval(intervalHandProdRef.current);
            clearInterval(intervalShakeRef.current);
            clearInterval(intervalTransfer1.current);
            clearInterval(intervalTransfer2.current);
            clearInterval(intervalAutoProdRef.current);
            dispatch(setFactoryWork(false));
        };
    }, []);

    // save loaded
    useEffect(() => {
        if (saveLoaded) {
            if (factoryManager > 0) {
                factoryPlusProduct();
            }
        }
    }, [saveLoaded]);

    return (
        <div className={positionClass}>
            <ModalManager
                open={showModalManager}
                close={handleCloseModalManager}
                type={"port"}
                factoryManager={factoryManager}
                factoryManagerPrice={factoryManagerPrice}
                factoryBought={factoryBought}
                factoryManagerValue={factoryManagerValue}
                factoryProdReady={factoryProdReady}
                shakeAnimationTimer={intervalShakeRef.current}
                factoryValue={factoryValue}
                setFactoryBaseValue={setFactoryBaseValue}
                factoryInitBaseValue={factoryInitBaseValue}
                setFactoryProdReady={setFactoryProdReady}
                circularAnimationName={circularAnimationName}
                factoryId={factoryId}
                setFactoryWork={setFactoryWork}
                setFactoryManagerLvlUp={setFactoryManagerLvlUp}
                setFactoryValue={setFactoryValue}
                handProductionTimer={intervalHandProdRef.current}
                autoCollectTimer={intervalAutoProdRef.current}
                factorySpeed={factorySpeed}
                setFactorySpeed={setFactorySpeed}
                factoryPlusProduct={factoryPlusProduct}
                portTransferProcess={portTransferProcess}
                factoryLvl={factoryLvl}
                managerImg={managerImg}
                managerIcon={mainIcon}
                saveIntervalId={saveIntervalId}
                saveInterval={saveInterval}
            />

            <div className="brainFactoryWrapper">
                {/* manager icon */}
                <div
                    className="brainFactoryManagerWrapper brainFactoryManagerWrapperPort"
                    onClick={handleShowModalManager}
                >
                    {factoryManager > 0 ? (
                        <img src={managerImg} alt="manager icon" className="brainFactoryManagerImg" />
                    ) : (
                        <div className="brainFactoryManagerNoImg">?</div>
                    )}

                    <img src="/img/farm/farmArrow.png" alt="green arrow" className="brainFactoryManagerArrow" />
                </div>

                <div className="brainFactoryCircleWrapper" data-factory-id={factoryId} onClick={factoryHandCollect}>
                    <img src="/img/farm/factoryBaseCircle.png" alt="factory circle" className="brainFactoryCircleImg" />
                    <div className="brainFactoryCircle">
                        <img src={mainIcon} alt="factory icon" className="brainFactoryMainIcon100" />
                        <div className="brainFactoryCircleValue">{abbreviationNumbers(factoryValue)}</div>
                        <div className="brainFactoryMultiple" data-factory-id={factoryId}>
                            x {factoryMultipleLvl}
                        </div>
                    </div>

                    <div className="brainFactoryCircular">
                        <div className="brainFactoryCircularProgressWrapper" data-factory-id={factoryId}>
                            <progress className="brainFactoryCircularProgress " min="0" max="100"></progress>
                        </div>
                    </div>
                </div>

                <div className="brainFactoryRectangle">
                    <>
                        <img src="/img/farm/factoryBaseRectangle.png" alt="factory circle" />
                        <div className="brainFactoryLevel">{factoryLvl}</div>
                        <div className="brainFactoryProgressBarLine">
                            <div
                                className="brainFactoryProgressBarOuter"
                                style={{
                                    width: `${70 * levelLinePercentPortLab(factoryLvl)}px`,
                                }}
                            ></div>
                        </div>
                        <Button
                            className="brainFactoryBtnLvl"
                            variant="contained"
                            color="farmMain"
                            onClick={factoryPlusLvl}
                            disabled={BigInt(warehouse_main) < factoryPriceLvlUp}
                            startIcon={
                                <img
                                    src="/img/farm/brainIcon.png"
                                    alt="brain"
                                    className={
                                        BigInt(warehouse_main) < factoryPriceLvlUp
                                            ? "brainFactoryBtnImg brainFactoryBtnImgDisabled"
                                            : "brainFactoryBtnImg"
                                    }
                                />
                            }
                            sx={{
                                "&.Mui-disabled": {
                                    background: "#818181",
                                    color: "#c0c0c0",
                                    border: "2px solid #706363 !important",
                                },
                            }}
                        >
                            {abbreviationNumbers(factoryPriceLvlUp)}
                        </Button>
                    </>
                </div>
            </div>
        </div>
    );
};

export default Port;
