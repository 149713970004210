// function for forming string update time UTC "01.01.2023 16:18 UTC"
export const updateTime = (dateString) => {
    const date = new Date(dateString);
    let minutes = "";
    let hour = "";
    let day = "";
    let month = "";

    if (String(date.getUTCMinutes()).length === 1) {
        minutes = `0${date.getUTCMinutes()}`;
    } else {
        minutes = date.getUTCMinutes();
    }

    if (String(date.getUTCHours()).length === 1) {
        hour = `0${date.getUTCHours()}`;
    } else {
        hour = date.getUTCHours();
    }

    if (String(date.getUTCDate()).length === 1) {
        day = `0${date.getUTCDate()}`;
    } else {
        day = date.getUTCDate();
    }

    if (String(date.getUTCMonth() + 1).length === 1) {
        month = `0${date.getUTCMonth() + 1}`;
    } else {
        month = date.getUTCMonth() + 1;
    }

    return `${day}.${month}.${date.getUTCFullYear()} ${hour}:${minutes} UTC`;
};
