import "./LoginRegister.scss";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SmartCaptcha } from "@yandex/smart-captcha";
import axios from "axios";
import { baseUrl } from "../axios/axios";
import { useNavigate } from "react-router-dom";

import {
    setIsAuth,
    setLoading,
    setErrorLoginForm,
} from "../../store/authSlice";
import { setUser } from "../../store/userSlice";
import { setNewCatBtn, setProcessPlay } from "../../store/gameProcessSlice";

import MobileMessage from "../mobileMessage/MobileMessage";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";

const LoginRegister = () => {
    const [tokenCaptcha, setTokenCaptcha] = useState("");
    const [isLogin, setIsLogin] = useState(true);
    const [isRegister, setIsRegister] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [refCode, setRefCode] = useState("");
    const [errorMsg, setErrorMsg] = useState(""); // error login/register form

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isAuth = useSelector((state) => state.authorization.isAuth);
    const loading = useSelector((state) => state.authorization.loading);
    const errorLoginForm = useSelector(
        (state) => state.authorization.errorLoginForm
    );

    // set axios request
    const authRequest = axios.create({
        withCredentials: true,
        baseURL: baseUrl,
    });
    authRequest.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
            "token"
        )}`;
        return config;
    });

    // inputs control
    const changeEmail = (e) => {
        setEmail(e.target.value);
    };
    const changePassword = (e) => {
        setPassword(e.target.value);
    };
    const changeRefCode = (e) => {
        setRefCode(e.target.value);
    };

    // login register button
    const isLoginForm = () => {
        dispatch(setErrorLoginForm(false));
        setIsRegister(false);
        setIsLogin(true);
    };
    const isRegisterForm = () => {
        dispatch(setErrorLoginForm(false));
        setIsLogin(false);
        setIsRegister(true);
    };

    // battle was the current cat?
    const battleCurrentUserCatOver = (battle) => {
        if (battle) {
            dispatch(setNewCatBtn(false));
            dispatch(setProcessPlay(true));
        } else {
            dispatch(setNewCatBtn(true));
            dispatch(setProcessPlay(false));
        }
    };

    const login = () => {
        if (email === "") {
            dispatch(setErrorLoginForm(true));
            setErrorMsg("Enter your email");
            return;
        } else if (email !== "" && password === "") {
            dispatch(setErrorLoginForm(true));
            setErrorMsg("Enter your password");
            return;
        }

        dispatch(setErrorLoginForm(false));
        dispatch(setLoading(true));

        authRequest
            .post("/login", { email, password })
            .then((res) => {
                // console.log(res);
                localStorage.setItem("token", res.data.accessToken);
                dispatch(setIsAuth(true));
                dispatch(setUser(res.data.user));
                battleCurrentUserCatOver(res.data.user.battleOver);
                setEmail("");
                setPassword("");
                setTokenCaptcha("");
                navigate("/game");
            })
            .catch((e) => {
                dispatch(setErrorLoginForm(true));
                setEmail("");
                setPassword("");
                setTokenCaptcha("");
                setErrorMsg(e.response?.data?.message);
                console.log(errorMsg);
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    };

    const registration = () => {
        if (email === "") {
            dispatch(setErrorLoginForm(true));
            setErrorMsg("Enter your email");
            return;
        } else if (email !== "" && password === "") {
            dispatch(setErrorLoginForm(true));
            setErrorMsg("Enter your password");
            return;
        }

        dispatch(setErrorLoginForm(false));
        dispatch(setLoading(true));

        authRequest
            .post("/registration", { email, password, invite: refCode })
            .then((res) => {
                // console.log(res);
                localStorage.setItem("token", res.data.accessToken);
                dispatch(setIsAuth(true));
                dispatch(setUser(res.data.user));
                battleCurrentUserCatOver(res.data.user.battleOver);
                setEmail("");
                setPassword("");
                setTokenCaptcha("");
                navigate("/game");
            })
            .catch((e) => {
                dispatch(setErrorLoginForm(true));
                if (e.response?.data?.message === "validation error") {
                    setErrorMsg(
                        "Check that the email is correct. The password must be between 6 and 25 characters"
                    );
                } else {
                    setErrorMsg(e.response?.data?.message);
                }
                setEmail("");
                setPassword("");
                setTokenCaptcha("");
                console.log(e.response?.data?.message);
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    };

    // show/hide password in input
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const loginForm =
        !isAuth && isLogin ? (
            <div className="pveForm">
                <div className="pveFormInputWrapper">
                    <TextField
                        className="pveFormEmail"
                        id="email"
                        label="Email"
                        variant="outlined"
                        type="email"
                        onChange={(e) => changeEmail(e)}
                    />
                    <FormControl variant="outlined" className="pveFormPassword">
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <OutlinedInput
                            id="password"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                            onChange={(e) => changePassword(e)}
                        />
                    </FormControl>
                </div>

                <div className="pveCaptcha">
                    <SmartCaptcha
                        sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                        onSuccess={setTokenCaptcha}
                        language="en"
                        // test={true}
                    />
                </div>

                <div className="pveFormBtnWrapper">
                    <Button
                        className="pveFormBtn"
                        variant="contained"
                        onClick={login}
                        disabled={
                            tokenCaptcha.length > 0 &&
                            email.length > 0 &&
                            password.length > 0
                                ? false
                                : true
                        }
                    >
                        Login
                    </Button>
                </div>

                <img
                    className="pveFormLoginImg"
                    src="/img/bloody-hand.png"
                    alt="bloody hand"
                />
            </div>
        ) : null;

    const registerForm =
        !isAuth && isRegister ? (
            <div className="pveForm">
                <div className="pveFormInputWrapper">
                    <TextField
                        className="pveFormEmail"
                        id="email"
                        label="Email *"
                        variant="outlined"
                        type="email"
                        onChange={(e) => changeEmail(e)}
                    />

                    <FormControl variant="outlined" className="pveFormPassword">
                        <InputLabel htmlFor="password">Password *</InputLabel>
                        <OutlinedInput
                            id="password"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                            onChange={(e) => changePassword(e)}
                        />
                    </FormControl>

                    <TextField
                        className="pveFormEmail pveFormPassword"
                        id="refCode"
                        label="Referral code"
                        variant="outlined"
                        type="text"
                        onChange={(e) => changeRefCode(e)}
                    />
                </div>

                <div className="pveCaptcha">
                    <SmartCaptcha
                        sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                        onSuccess={setTokenCaptcha}
                        language="en"
                        // test={true}
                    />
                </div>

                <div className="pveFormBtnWrapper">
                    <Button
                        className="pveFormBtn"
                        variant="contained"
                        onClick={registration}
                        disabled={tokenCaptcha.length > 0 ? false : true}
                        // disabled={true}
                    >
                        Register
                    </Button>
                </div>

                <img
                    className="pveFormRegisterImg"
                    src="/img/bloody-hand.png"
                    alt="bloody hand"
                />
            </div>
        ) : null;

    return (
        <Container>
            {!isAuth ? (
                !loading ? (
                    <>
                        <div className="pveFormTitle">
                            <div
                                className="pveFormLoginRegisterTitle"
                                onClick={isLoginForm}
                            >
                                Login
                            </div>
                            <div className="pveFormLoginRegisterBetweenTitle">
                                or
                            </div>
                            <div
                                className="pveFormLoginRegisterTitle"
                                onClick={isRegisterForm}
                            >
                                Register
                            </div>
                        </div>

                        {errorLoginForm ? (
                            <div className="pveErrorLoginMsg">{errorMsg}</div>
                        ) : null}

                        {isLogin ? (
                            <div className="pveFormBg">
                                {!loading ? loginForm : null}
                            </div>
                        ) : null}

                        {isRegister ? (
                            <div className="pveFormBg pveFormBgRegister">
                                {!loading ? registerForm : null}
                            </div>
                        ) : null}
                    </>
                ) : (
                    <CircularProgress
                        color="primary"
                        className="pveFormSpinner"
                    />
                )
            ) : null}

            <MobileMessage />
        </Container>
    );
};

export default LoginRegister;
