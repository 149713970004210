import "./LotteryHistory.scss";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../axios/axios";

import { Container } from "@mui/material";

import LotteryHistoryItem from "./LotteryHistoryItem";

const LotteryHistory = () => {
    const [lotteryData, setLotteryData] = useState();
    const [tokenSymbol, setTokenSymbol] = useState();
    const [loadingLotteryData, setLoadingLotteryData] = useState(true);
    const [errorLotteryData, setErrorLotteryData] = useState(false);

    const { pathname } = useLocation();

    // set axios request
    const gameRequest = axios.create({
        withCredentials: true,
        baseURL: baseUrl,
    });

    const getLotteryData = async () => {
        setLoadingLotteryData(true);
        setErrorLotteryData(false);
        gameRequest
            .post("/lotteryHistory")
            .then((res) => {
                setLotteryData(res.data.history.reverse());
                setTokenSymbol(res.data.tokenSymbol);
            })
            .catch((e) => {
                console.log(e.response?.data?.message);
                setErrorLotteryData(true);
            })
            .finally(() => {
                setLoadingLotteryData(false);
            });
    };

    useEffect(() => {
        getLotteryData();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Container>
            <div className="pveRulesTitle">Lottery history</div>
            <div className="pveRulesWrapper">
                {lotteryData
                    ? lotteryData.map((item) => {
                          return (
                              <LotteryHistoryItem
                                  key={item.numberOfLottery}
                                  lotteryHistory={item}
                                  tokenSymbol={tokenSymbol}
                                  loadingLotteryData={loadingLotteryData}
                                  errorLotteryData={errorLotteryData}
                              />
                          );
                      })
                    : null}
            </div>
        </Container>
    );
};

export default LotteryHistory;
