import { Container } from "@mui/material";

const FaucetRules = () => {
    return (
        <Container>
            <div className="pveRulesTitle">Faucet rules</div>
            <div className="pveRulesWrapper">
                <div className="pveRulesItem">
                    1. You can get a reward in the faucet every hour. The reward
                    is credited to your balance. All received funds you can use
                    in the game without restrictions.
                </div>
                <div className="pveRulesItem">
                    2. When you press the "Roll" button, a random number will be
                    drawn. Depending on the number you get the corresponding
                    reward.
                </div>
                <div className="pveRulesItem">
                    3. It is forbidden to collect faucet rewards with the help
                    of programs (bots). When collecting rewards you must confirm
                    that you are not a robot. If you do not see the checkbox "I
                    am not a robot" - use VPN.
                </div>
                <div className="pveRulesItem">
                    4. You must disable your ad blocker to receive the reward.
                </div>
                <div className="pveRulesItem">
                    5. Reward in the faucet is paid by viewing ads. The reward
                    can vary both upwards and downwards. The reward depends on
                    the price of ZOMB and the cost of viewed ads.
                </div>
            </div>
        </Container>
    );
};

export default FaucetRules;
