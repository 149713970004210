import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import userReducer from "./userSlice";
import gameProcessReducer from "./gameProcessSlice";
import gameStatsReducer from "./gameStatsSlice";
import brainFarmReducer from "./brainFarmSlice";
import brainFarmRewardsTasksReducer from "./brainFarmRewardsTasksSlice";

export default configureStore({
    reducer: {
        authorization: authReducer,
        user: userReducer,
        gameProcess: gameProcessReducer,
        gameStats: gameStatsReducer,
        brainFarm: brainFarmReducer,
        brainFarmRewardsTasks: brainFarmRewardsTasksReducer,
    },
});
