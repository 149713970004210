/* global BigInt */

// function for stop and play animation
export const stopPlayAnimation = (animation) => {
    document.getAnimations().forEach((anim) => {
        if (anim.animationName === animation) {
            anim.cancel();
            anim.play();
        }
    });
};

// active cursor pointer
export const cursorPointer = (factoryId) => {
    const circleWrapper = document.querySelectorAll(".brainFactoryCircleWrapper");
    if (circleWrapper) {
        circleWrapper.forEach((elem) => {
            const elemId = elem.getAttribute("data-factory-id");

            if (elemId == factoryId) {
                elem.style.cursor = "pointer";
            }
        });
    }
};

// disable cursor pointer for factory
export const disableFactoryCursorPointer = (factoryId) => {
    const circleWrapper = document.querySelectorAll(".brainFactoryCircleWrapper");
    if (circleWrapper) {
        circleWrapper.forEach((elem) => {
            const elemId = elem.getAttribute("data-factory-id");

            if (elemId == factoryId) {
                elem.style.cursor = "auto";
            }
        });
    }
};

// circular animation 1 time
export const circularAnimation1Time = (factoryId, circularAnimationName, factorySpeed, stopPlayAnimationFunc) => {
    const circularProgress = document.querySelectorAll(".brainFactoryCircularProgressWrapper");
    if (circularProgress) {
        circularProgress.forEach((elem) => {
            const elemId = elem.getAttribute("data-factory-id");

            if (elemId == factoryId) {
                elem.style.animation = `${circularAnimationName} ${factorySpeed}ms 1 linear forwards`;

                stopPlayAnimationFunc(circularAnimationName);
            }
        });
    }
};

// multiple animation for all factories, port, lab
export const multipleAnimation = (factoryId) => {
    const textElem = document.querySelectorAll(".brainFactoryMultiple");

    if (textElem) {
        textElem.forEach((elem) => {
            const elemId = elem.getAttribute("data-factory-id");

            if (elemId == factoryId) {
                elem.classList.add("brainFactoryMultipleAnimation");

                const timerMultAnimId = setTimeout(() => {
                    elem.classList.remove("brainFactoryMultipleAnimation");
                }, 4500);
            }
        });
    }
};

// display level percent on manager modal
export const displayLvlPercent = (lvl, factoryManager) => {
    let result = 0;

    if (lvl === 1) {
        result = (factoryManager.lvl_1 - 1) * 100;
    } else if (lvl === 2) {
        result = (factoryManager.lvl_2 - factoryManager.lvl_1) * 100;
    } else if (lvl === 3) {
        result = (factoryManager.lvl_3 - 1) * 100;
    } else if (lvl === 4) {
        result = (factoryManager.lvl_4 - 1) * 100;
    } else if (lvl === 5) {
        result = (factoryManager.lvl_5 - factoryManager.lvl_2) * 100;
    }

    return Math.round(result);
};

// calculate port or lab value for level
export const calcPortLabValueLvl = (lvl, newBaseValue) => {
    let result = "0";

    const lvl_10_value = newBaseValue * BigInt(10) * BigInt(2);
    const lvl_25_value = (lvl_10_value + newBaseValue * BigInt(15) * BigInt(2)) * BigInt(4);
    const lvl_50_value = (lvl_10_value + lvl_25_value + newBaseValue * BigInt(25) * BigInt(2) * BigInt(4)) * BigInt(8);
    const lvl_100_value =
        (lvl_10_value + lvl_25_value + lvl_50_value + newBaseValue * BigInt(50) * BigInt(2) * BigInt(4) * BigInt(8)) *
        BigInt(16);
    const lvl_150_value =
        (lvl_10_value +
            lvl_25_value +
            lvl_50_value +
            lvl_100_value +
            newBaseValue * BigInt(50) * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16)) *
        BigInt(32);
    const lvl_200_value =
        (lvl_10_value +
            lvl_25_value +
            lvl_50_value +
            lvl_100_value +
            lvl_150_value +
            newBaseValue * BigInt(50) * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16) * BigInt(32)) *
        BigInt(64);
    const lvl_250_value =
        (lvl_10_value +
            lvl_25_value +
            lvl_50_value +
            lvl_100_value +
            lvl_150_value +
            lvl_200_value +
            newBaseValue * BigInt(50) * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16) * BigInt(32) * BigInt(64)) *
        BigInt(4);
    const lvl_300_value =
        (lvl_10_value +
            lvl_25_value +
            lvl_50_value +
            lvl_100_value +
            lvl_150_value +
            lvl_200_value +
            lvl_250_value +
            newBaseValue *
                BigInt(50) *
                BigInt(2) *
                BigInt(4) *
                BigInt(8) *
                BigInt(16) *
                BigInt(32) *
                BigInt(64) *
                BigInt(4)) *
        BigInt(4);

    if (lvl >= 1 && lvl < 10) {
        if (lvl === 1) {
            result = newBaseValue * BigInt(lvl);
        } else {
            result = newBaseValue * (BigInt(lvl) - BigInt(1));
        }
    } else if (lvl >= 10 && lvl < 25) {
        if (lvl === 10) {
            result = lvl_10_value;
        } else {
            const newLvl = BigInt(lvl) - BigInt(10);
            result = lvl_10_value + newBaseValue * BigInt(2) * newLvl;
        }
    } else if (lvl >= 25 && lvl < 50) {
        if (lvl === 25) {
            result = lvl_25_value;
        } else {
            const newLvl = BigInt(lvl) - BigInt(25);
            result = lvl_25_value + newBaseValue * BigInt(2) * BigInt(4) * newLvl;
        }
    } else if (lvl >= 50 && lvl < 100) {
        if (lvl === 50) {
            result = lvl_50_value;
        } else {
            const newLvl = BigInt(lvl) - BigInt(50);
            result = lvl_50_value + newBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * newLvl;
        }
    } else if (lvl >= 100 && lvl < 150) {
        if (lvl === 100) {
            result = lvl_100_value;
        } else {
            const newLvl = BigInt(lvl) - BigInt(100);
            result = lvl_100_value + newBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16) * newLvl;
        }
    } else if (lvl >= 150 && lvl < 200) {
        if (lvl === 150) {
            result = lvl_150_value;
        } else {
            const newLvl = BigInt(lvl) - BigInt(150);
            result =
                lvl_150_value + newBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16) * BigInt(32) * newLvl;
        }
    } else if (lvl >= 200 && lvl < 250) {
        if (lvl === 200) {
            result = lvl_200_value;
        } else {
            const newLvl = BigInt(lvl) - BigInt(200);
            result =
                lvl_200_value +
                newBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16) * BigInt(32) * BigInt(64) * newLvl;
        }
    } else if (lvl >= 250 && lvl < 300) {
        if (lvl === 250) {
            result = lvl_250_value;
        } else {
            const newLvl = BigInt(lvl) - BigInt(250);
            result =
                lvl_250_value +
                newBaseValue *
                    BigInt(2) *
                    BigInt(4) *
                    BigInt(8) *
                    BigInt(16) *
                    BigInt(32) *
                    BigInt(64) *
                    BigInt(4) *
                    newLvl;
        }
    } else if (lvl >= 300) {
        if (lvl === 300) {
            result = lvl_300_value;
        } else {
            const newLvl = BigInt(lvl) - BigInt(300);
            result =
                lvl_300_value +
                newBaseValue *
                    BigInt(2) *
                    BigInt(4) *
                    BigInt(8) *
                    BigInt(16) *
                    BigInt(32) *
                    BigInt(64) *
                    BigInt(4) *
                    BigInt(4) *
                    newLvl;
        }
    }

    return result.toString();
};

// calculate factory value for level
export const calcFactoryValueLvl = (lvl, newBaseValue) => {
    let result = "0";

    const lvl_25_value = newBaseValue * BigInt(25) * BigInt(2);
    const lvl_50_value = (lvl_25_value + newBaseValue * BigInt(25) * BigInt(2)) * BigInt(4);
    const lvl_100_value = (lvl_25_value + lvl_50_value + newBaseValue * BigInt(50) * BigInt(2) * BigInt(4)) * BigInt(8);
    const lvl_200_value =
        (lvl_25_value + lvl_50_value + lvl_100_value + newBaseValue * BigInt(100) * BigInt(2) * BigInt(4) * BigInt(8)) *
        BigInt(16);
    const lvl_300_value =
        (lvl_25_value +
            lvl_50_value +
            lvl_100_value +
            lvl_200_value +
            newBaseValue * BigInt(100) * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16)) *
        BigInt(32);

    if (lvl >= 1 && lvl < 25) {
        if (lvl === 1) {
            result = newBaseValue * BigInt(lvl);
        } else {
            result = newBaseValue * (BigInt(lvl) - BigInt(1));
        }
    } else if (lvl >= 25 && lvl < 50) {
        if (lvl === 25) {
            result = lvl_25_value;
        } else {
            const newLvl = BigInt(lvl) - BigInt(25);
            result = lvl_25_value + newBaseValue * BigInt(2) * newLvl;
        }
    } else if (lvl >= 50 && lvl < 100) {
        if (lvl === 50) {
            result = lvl_50_value;
        } else {
            const newLvl = BigInt(lvl) - BigInt(50);
            result = lvl_50_value + newBaseValue * BigInt(2) * BigInt(4) * newLvl;
        }
    } else if (lvl >= 100 && lvl < 200) {
        if (lvl === 100) {
            result = lvl_100_value;
        } else {
            const newLvl = BigInt(lvl) - BigInt(100);
            result = lvl_100_value + newBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * newLvl;
        }
    } else if (lvl >= 200 && lvl < 300) {
        if (lvl === 200) {
            result = lvl_200_value;
        } else {
            const newLvl = BigInt(lvl) - BigInt(200);
            result = lvl_200_value + newBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16) * newLvl;
        }
    } else if (lvl >= 300) {
        if (lvl === 300) {
            result = lvl_300_value;
        } else {
            const newLvl = BigInt(lvl) - BigInt(300);
            result =
                lvl_300_value + newBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16) * BigInt(32) * newLvl;
        }
    }

    return result.toString();
};

// calculate line indicator for level (factories)
export const levelLinePercentFactory = (factoryLvl) => {
    let result = 0;

    if (factoryLvl >= 1 && factoryLvl <= 24) {
        result = Math.round((factoryLvl / 25) * 100) / 100;
    } else if (factoryLvl >= 25 && factoryLvl <= 49) {
        result = Math.round(((factoryLvl - 25) / 25) * 100) / 100;
    } else if (factoryLvl >= 50 && factoryLvl <= 99) {
        result = Math.round(((factoryLvl - 50) / 50) * 100) / 100;
    } else if (factoryLvl >= 100 && factoryLvl <= 199) {
        result = Math.round(((factoryLvl - 100) / 100) * 100) / 100;
    } else if (factoryLvl >= 200 && factoryLvl <= 299) {
        result = Math.round(((factoryLvl - 200) / 100) * 100) / 100;
    }
    // else if (factoryLvl >= 200 && factoryLvl <= 249) {
    //     result = Math.round(((factoryLvl - 200) / 200) * 100) / 100;
    // } else if (factoryLvl >= 250 && factoryLvl <= 299) {
    //     result = Math.round(((factoryLvl - 250) / 250) * 100) / 100;
    // }

    return result;
};

// calculate line indicator for level (port and lab)
export const levelLinePercentPortLab = (factoryLvl) => {
    let result = 0;

    if (factoryLvl >= 1 && factoryLvl <= 9) {
        result = Math.round((factoryLvl / 10) * 100) / 100;
    } else if (factoryLvl >= 10 && factoryLvl <= 24) {
        result = Math.round(((factoryLvl - 10) / 15) * 100) / 100;
    } else if (factoryLvl >= 25 && factoryLvl <= 49) {
        result = Math.round(((factoryLvl - 25) / 25) * 100) / 100;
    } else if (factoryLvl >= 50 && factoryLvl <= 99) {
        result = Math.round(((factoryLvl - 50) / 50) * 100) / 100;
    } else if (factoryLvl >= 100 && factoryLvl <= 149) {
        result = Math.round(((factoryLvl - 100) / 50) * 100) / 100;
    } else if (factoryLvl >= 150 && factoryLvl <= 199) {
        result = Math.round(((factoryLvl - 150) / 50) * 100) / 100;
    } else if (factoryLvl >= 200 && factoryLvl <= 249) {
        result = Math.round(((factoryLvl - 200) / 50) * 100) / 100;
    } else if (factoryLvl >= 250 && factoryLvl <= 299) {
        result = Math.round(((factoryLvl - 250) / 50) * 100) / 100;
    }

    return result;
};

// calculate line indicator for levels tasks
export const levelLinePercentTasks = (currentLvl, maxLvl) => {
    let result = 0;
    if (typeof currentLvl === "bigint" && typeof maxLvl === "bigint") {
        const newCurrentLvl = currentLvl === BigInt(0) ? BigInt(10) : currentLvl;

        let percent = Number(maxLvl) / Number(newCurrentLvl);

        if (percent < 1) {
            percent = 1;
        } else {
            percent = 1 / Number(percent);
        }
        result = Math.round(percent * 100) / 100;
    } else {
        result = Math.round((currentLvl / maxLvl) * 100) / 100;
    }

    return result;
};

// open modal
export const openModal = () => {
    document.body.parentNode.style.overflow = "hidden";
    document.body.parentNode.style.width = "calc(100% - 15px)";
    document.querySelector(".headerWrapper").style.width = "calc(100% - 15px)";
};
// close modal
export const closeModal = () => {
    document.body.parentNode.style.overflow = "auto";
    document.body.parentNode.style.overflowX = "hidden";
    document.body.parentNode.style.width = "100%";
    document.querySelector(".headerWrapper").style.width = "100%";
};

export const abbreviationNumbers = (num) => {
    const formatNum = (num, length, numName) => {
        let finalNum = "";

        const firstBit = length % 3;
        const firstFinalBit = firstBit === 0 ? 3 : firstBit;

        const firstPartNum = num.slice(0, firstFinalBit);
        const secondPartNum = num.slice(firstFinalBit, firstFinalBit + 1);
        const thirdPartNum = num.slice(firstFinalBit + 1, firstFinalBit + 2);

        if (secondPartNum === "0" && thirdPartNum === "0") {
            finalNum = `${firstPartNum}${numName}`;
        } else if (secondPartNum !== "0" && thirdPartNum === "0") {
            finalNum = `${firstPartNum}.${secondPartNum}${numName}`;
        } else if (thirdPartNum !== "0") {
            finalNum = `${firstPartNum}.${secondPartNum}${thirdPartNum}${numName}`;
        }

        return finalNum;
    };

    let number = "";
    if (typeof num === "bigint") {
        number = num.toString();
    } else {
        number = String(num);
    }

    const length = number.length;
    let result = "";

    if (length <= 3) {
        result = number;
    } else if (length > 3 && length < 7) {
        result = formatNum(number, length, "K");
    } else if (length > 6 && length < 10) {
        result = formatNum(number, length, "M");
    } else if (length > 9 && length < 13) {
        result = formatNum(number, length, "B");
    } else if (length > 12 && length < 16) {
        result = formatNum(number, length, "T");
    } else if (length > 15 && length < 19) {
        result = formatNum(number, length, "Qa");
    } else if (length > 18 && length < 22) {
        result = formatNum(number, length, "Qi");
    } else if (length > 21 && length < 25) {
        result = formatNum(number, length, "Sx");
    } else if (length > 24 && length < 28) {
        result = formatNum(number, length, "Sp");
    } else if (length >= 28) {
        result = `${number[0]}e${length - 1}`;
    }

    return String(result);
};
