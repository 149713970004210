import "./LotteryHistory.scss";

import Skeleton from "@mui/material/Skeleton";

import { updateTime } from "../../utils/updateTime";

const LotteryHistoryItem = (props) => {
    const {
        lotteryHistory,
        tokenSymbol,
        loadingLotteryData,
        errorLotteryData,
    } = props;

    let colorNumberOfTicketsHistory = false;
    if (!loadingLotteryData) {
        if (lotteryHistory.tickets >= lotteryHistory.minTickets) {
            colorNumberOfTicketsHistory = true;
        }
        if (lotteryHistory.tickets === 0 && lotteryHistory.minTickets === 0) {
            colorNumberOfTicketsHistory = false;
        }
    }

    let colorNumberOfParticipantsHistory = false;
    if (!loadingLotteryData) {
        if (lotteryHistory.participants >= lotteryHistory.minParticipants) {
            colorNumberOfParticipantsHistory = true;
        }
        if (
            lotteryHistory.participants === 0 &&
            lotteryHistory.minParticipants === 0
        ) {
            colorNumberOfParticipantsHistory = false;
        }
    }

    let colorLotteryHistoryRaffled = false;
    if (!loadingLotteryData) {
        if (lotteryHistory.raffled) {
            colorLotteryHistoryRaffled = true;
        }
    }

    const skeleton = (width, height) => {
        return (
            <Skeleton className="pveSkeleton" width={width} height={height} />
        );
    };

    return (
        <div className="pveLotteryHistoryItem">
            {/* main table */}
            <div className="pveLotteryCurrentWrapper">
                <div className="pveLotteryCurrentItem">
                    <div className="pveLotteryCurrentTitle">#</div>
                    <div className="pveLotteryCurrentContent">
                        {loadingLotteryData
                            ? skeleton(30)
                            : errorLotteryData
                            ? "error!"
                            : lotteryHistory.numberOfLottery}
                    </div>
                </div>
                <div className="pveLotteryCurrentItem">
                    <div className="pveLotteryCurrentTitle">Start, UTC</div>
                    <div className="pveLotteryCurrentContent">
                        {loadingLotteryData
                            ? skeleton(110)
                            : errorLotteryData
                            ? "error!"
                            : updateTime(lotteryHistory.dateStart).slice(0, 16)}
                    </div>
                </div>
                <div className="pveLotteryCurrentItemBreak400"></div>
                <div className="pveLotteryCurrentItem">
                    <div className="pveLotteryCurrentTitle">Finish, UTC</div>
                    <div className="pveLotteryCurrentContent">
                        {loadingLotteryData
                            ? skeleton(110)
                            : errorLotteryData
                            ? "error!"
                            : updateTime(lotteryHistory.dateFinish).slice(
                                  0,
                                  16
                              )}
                    </div>
                </div>
                <div className="pveLotteryCurrentItemBreak600"></div>
                <div className="pveLotteryCurrentItem">
                    <div className="pveLotteryCurrentTitle">Prize pool</div>
                    <div className="pveLotteryCurrentContent">
                        <span className="pveLotteryCurrentPrize">
                            {loadingLotteryData ? (
                                skeleton(70)
                            ) : errorLotteryData ? (
                                "error!"
                            ) : (
                                <>
                                    <span>{lotteryHistory.sumReward}</span>{" "}
                                    <span className="pveLotteryCurrentPrizePoolTokenSymbol">
                                        {tokenSymbol}
                                    </span>
                                </>
                            )}
                        </span>
                    </div>
                </div>
                <div className="pveLotteryCurrentItemBreak400"></div>
                <div className="pveLotteryCurrentItem">
                    <div className="pveLotteryCurrentTitle">
                        Turnover per ticket
                    </div>
                    <div className="pveLotteryCurrentContent">
                        <span className="pveLotteryCurrentPrize">
                            {loadingLotteryData ? (
                                skeleton(30)
                            ) : errorLotteryData ? (
                                "error!"
                            ) : (
                                <>
                                    <span>
                                        {lotteryHistory.turnoverForTicket}
                                    </span>{" "}
                                    <span className="pveLotteryCurrentPrizePoolTokenSymbol">
                                        {tokenSymbol}
                                    </span>
                                </>
                            )}
                        </span>
                    </div>
                </div>
                <div className="pveLotteryCurrentItemBreak900"></div>
                <div className="pveLotteryCurrentItem">
                    <div className="pveLotteryCurrentTitle">
                        Number of tickets
                    </div>
                    <div className="pveLotteryCurrentContent">
                        <span
                            className={
                                colorNumberOfTicketsHistory
                                    ? "textGreen"
                                    : "textRed"
                            }
                        >
                            {loadingLotteryData
                                ? skeleton(60)
                                : errorLotteryData
                                ? "error!"
                                : `${lotteryHistory.tickets} / ${lotteryHistory.minTickets}`}
                        </span>{" "}
                        <br /> all / minimum
                    </div>
                </div>
                <div className="pveLotteryCurrentItemBreak400"></div>
                <div className="pveLotteryCurrentItemBreak600"></div>
                <div className="pveLotteryCurrentItem">
                    <div className="pveLotteryCurrentTitle">
                        Number of participants
                    </div>
                    <div className="pveLotteryCurrentContent">
                        <span
                            className={
                                colorNumberOfParticipantsHistory
                                    ? "textGreen"
                                    : "textRed"
                            }
                        >
                            {loadingLotteryData
                                ? skeleton(60)
                                : errorLotteryData
                                ? "error!"
                                : `${lotteryHistory.participants} / ${lotteryHistory.minParticipants}`}
                        </span>{" "}
                        <br /> all / minimum
                    </div>
                </div>
                <div className="pveLotteryCurrentItem">
                    <div className="pveLotteryCurrentTitle">Raffled?</div>
                    <div
                        className={
                            colorLotteryHistoryRaffled
                                ? "pveLotteryCurrentContent textGreen"
                                : "pveLotteryCurrentContent textRed"
                        }
                    >
                        {loadingLotteryData
                            ? skeleton(30)
                            : errorLotteryData
                            ? "error!"
                            : lotteryHistory.raffled
                            ? "Yes"
                            : "No"}
                    </div>
                </div>
            </div>

            {/* place prize table */}
            {lotteryHistory.raffled ? (
                <>
                    {/* title prize table */}
                    <div className="pveLotteryPlacePastTitleWrapper">
                        <div className="pveLotteryPlaceTitleItem">Place</div>
                        <div className="pveLotteryPlaceTitleItem">Prize</div>
                        <div className="pveLotteryPlaceTitleItem">Email</div>
                        <div className="pveLotteryPlaceTitleItem">Wallet</div>
                        <div className="pveLotteryPlaceTitleItem">Tickets</div>
                    </div>

                    <div className="pveLotteryHistoryPlacesWrapper">
                        <div className="pveLotteryPlacePastTitleWrapper pveLotteryPlacePastTitleWrapperItem">
                            <div className="pveLotteryPlaceItem">1</div>
                            <div className="pveLotteryPlaceItem pveLotteryPlaceItemWlkn">
                                {loadingLotteryData ? (
                                    skeleton(60)
                                ) : errorLotteryData ? (
                                    "error!"
                                ) : (
                                    <>
                                        <span>
                                            {lotteryHistory.place1Reward}
                                        </span>{" "}
                                        <span className="pveLotteryPlaceTokenSymbol">
                                            {tokenSymbol}
                                        </span>
                                    </>
                                )}
                            </div>
                            <div className="pveLotteryPlaceItem">
                                {loadingLotteryData
                                    ? skeleton(120)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryHistory.place1Email}
                            </div>
                            <div className="pveLotteryPlaceItem">
                                {loadingLotteryData
                                    ? skeleton(100)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryHistory.place1Address}
                            </div>
                            <div className="pveLotteryPlaceItem">
                                {loadingLotteryData
                                    ? skeleton(30)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryHistory.place1Tickets}
                            </div>
                        </div>

                        <div className="pveLotteryPlacePastTitleWrapper pveLotteryPlacePastTitleWrapperItem">
                            <div className="pveLotteryPlaceItem">2</div>
                            <div className="pveLotteryPlaceItem pveLotteryPlaceItemWlkn">
                                {loadingLotteryData ? (
                                    skeleton(60)
                                ) : errorLotteryData ? (
                                    "error!"
                                ) : (
                                    <>
                                        <span>
                                            {lotteryHistory.place2Reward}
                                        </span>{" "}
                                        <span className="pveLotteryPlaceTokenSymbol">
                                            {tokenSymbol}
                                        </span>
                                    </>
                                )}
                            </div>
                            <div className="pveLotteryPlaceItem">
                                {loadingLotteryData
                                    ? skeleton(120)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryHistory.place2Email}
                            </div>
                            <div className="pveLotteryPlaceItem">
                                {loadingLotteryData
                                    ? skeleton(100)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryHistory.place2Address}
                            </div>
                            <div className="pveLotteryPlaceItem">
                                {loadingLotteryData
                                    ? skeleton(30)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryHistory.place2Tickets}
                            </div>
                        </div>

                        <div className="pveLotteryPlacePastTitleWrapper pveLotteryPlacePastTitleWrapperItem">
                            <div className="pveLotteryPlaceItem">3</div>
                            <div className="pveLotteryPlaceItem pveLotteryPlaceItemWlkn">
                                {loadingLotteryData ? (
                                    skeleton(60)
                                ) : errorLotteryData ? (
                                    "error!"
                                ) : (
                                    <>
                                        <span>
                                            {lotteryHistory.place3Reward}
                                        </span>{" "}
                                        <span className="pveLotteryPlaceTokenSymbol">
                                            {tokenSymbol}
                                        </span>
                                    </>
                                )}
                            </div>
                            <div className="pveLotteryPlaceItem">
                                {loadingLotteryData
                                    ? skeleton(120)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryHistory.place3Email}
                            </div>
                            <div className="pveLotteryPlaceItem">
                                {loadingLotteryData
                                    ? skeleton(100)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryHistory.place3Address}
                            </div>
                            <div className="pveLotteryPlaceItem">
                                {loadingLotteryData
                                    ? skeleton(30)
                                    : errorLotteryData
                                    ? "error!"
                                    : lotteryHistory.place3Tickets}
                            </div>
                        </div>
                    </div>
                </>
            ) : null}

            <img
                style={{ marginTop: "30px" }}
                src="/img/bloody-line-750-25.png"
                alt="bloody line"
            />
        </div>
    );
};

export default LotteryHistoryItem;
