import "./WarningMessage.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../axios/axios";

const WarningMessage = () => {
    // warning message from DB
    const [message, setMessage] = useState("");

    // set axios request
    const gameRequest = axios.create({
        withCredentials: true,
        baseURL: baseUrl,
    });

    useEffect(() => {
        gameRequest
            .post("/warningMessage")
            .then((res) => {
                setMessage(res.data.warningMsg);
            })
            .catch((e) => {
                console.log(e.response?.data?.message);
            });
    }, []);

    return (
        <>
            {!message === "" ? (
                <div className="pveWarningMessageWrapper">
                    <div className="pveWarningMessageText">{message}</div>;
                </div>
            ) : null}
        </>
    );
};

export default WarningMessage;
