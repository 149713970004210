import "./TasksItem.scss";
import { useSelector } from "react-redux";

import { levelLinePercentTasks } from "../functions";

const TasksItem = (props) => {
    const { type, factoryId, maxLevel, typeReward, rewardValue, completed } = props;

    const user = useSelector((state) => state.user.user);

    const factory_0_bought = useSelector((state) => state.brainFarm.factory_0_bought);
    const factory_1_bought = useSelector((state) => state.brainFarm.factory_1_bought);
    const factory_2_bought = useSelector((state) => state.brainFarm.factory_2_bought);
    const factory_3_bought = useSelector((state) => state.brainFarm.factory_3_bought);
    const factory_4_bought = useSelector((state) => state.brainFarm.factory_4_bought);
    const port_bought = useSelector((state) => state.brainFarm.port_bought);
    const lab_bought = useSelector((state) => state.brainFarm.lab_bought);

    const factory_0_lvl = useSelector((state) => state.brainFarm.factory_0_lvl);
    const factory_1_lvl = useSelector((state) => state.brainFarm.factory_1_lvl);
    const factory_2_lvl = useSelector((state) => state.brainFarm.factory_2_lvl);
    const factory_3_lvl = useSelector((state) => state.brainFarm.factory_3_lvl);
    const factory_4_lvl = useSelector((state) => state.brainFarm.factory_4_lvl);
    const port_lvl = useSelector((state) => state.brainFarm.port_lvl);
    const lab_lvl = useSelector((state) => state.brainFarm.lab_lvl);

    const factory_0_manager_lvl = useSelector((state) => state.brainFarm.factory_0_manager_lvl);
    const factory_1_manager_lvl = useSelector((state) => state.brainFarm.factory_1_manager_lvl);
    const factory_2_manager_lvl = useSelector((state) => state.brainFarm.factory_2_manager_lvl);
    const factory_3_manager_lvl = useSelector((state) => state.brainFarm.factory_3_manager_lvl);
    const factory_4_manager_lvl = useSelector((state) => state.brainFarm.factory_4_manager_lvl);
    const port_manager_lvl = useSelector((state) => state.brainFarm.port_manager_lvl);
    const lab_manager_lvl = useSelector((state) => state.brainFarm.lab_manager_lvl);

    let factoryIcon,
        managerIcon,
        turnoverIcon,
        coinIcon,
        classValueReward,
        classIcon = "";
    let factoryBought,
        factoryLvl,
        managerLvl = 0;

    const value = Math.round(rewardValue * 100 * 100) / 100;

    if (typeReward === "bronze") {
        coinIcon = "/img/farm/coinBronze.png";
        classValueReward = "brainModalTasksItemRewardPercent brainModalTasksAwardValueTextBronze";
    } else if (typeReward === "silver") {
        coinIcon = "/img/farm/coinSilver.png";
        classValueReward = "brainModalTasksItemRewardPercent brainModalTasksAwardValueTextSilver";
    } else if (typeReward === "gold") {
        coinIcon = "/img/farm/coinGold.png";
        classValueReward = "brainModalTasksItemRewardPercent brainModalTasksAwardValueTextGold";
    }

    if (factoryId === 0) {
        factoryIcon = "/img/farm/shovel100.png";
        managerIcon = "/img/farm/shovelManager200.png";
        factoryBought = factory_0_bought;
        factoryLvl = factory_0_lvl;
        managerLvl = factory_0_manager_lvl;
        classIcon = "brainModalTasksItemQuestionImg brainModalTasksItemQuestionImgSquare";
    } else if (factoryId === 1) {
        factoryIcon = "/img/farm/minerHelmet100.png";
        managerIcon = "/img/farm/minerManager200.png";
        factoryBought = factory_1_bought;
        factoryLvl = factory_1_lvl;
        managerLvl = factory_1_manager_lvl;
        classIcon = "brainModalTasksItemQuestionImg brainModalTasksItemQuestionImgRectangle";
    } else if (factoryId === 2) {
        factoryIcon = "/img/farm/book100.png";
        managerIcon = "/img/farm/templeManager200.png";
        factoryBought = factory_2_bought;
        factoryLvl = factory_2_lvl;
        managerLvl = factory_2_manager_lvl;
        classIcon = "brainModalTasksItemQuestionImg brainModalTasksItemQuestionImgRectangle";
    } else if (factoryId === 3) {
        factoryIcon = "/img/farm/canningFactory100.png";
        managerIcon = "/img/farm/canningManager200.png";
        factoryBought = factory_3_bought;
        factoryLvl = factory_3_lvl;
        managerLvl = factory_3_manager_lvl;
        classIcon = "brainModalTasksItemQuestionImg brainModalTasksItemQuestionImgSquare";
    } else if (factoryId === 4) {
        factoryIcon = "/img/farm/bank100.png";
        managerIcon = "/img/farm/bankManager200.png";
        factoryBought = factory_4_bought;
        factoryLvl = factory_4_lvl;
        managerLvl = factory_4_manager_lvl;
        classIcon = "brainModalTasksItemQuestionImg brainModalTasksItemQuestionImgRectangle";
    } else if (factoryId === 100) {
        factoryIcon = "/img/farm/creepyShip100.png";
        managerIcon = "/img/farm/portManager200.png";
        factoryBought = port_bought;
        factoryLvl = port_lvl;
        managerLvl = port_manager_lvl;
        classIcon = "brainModalTasksItemQuestionImg brainModalTasksItemQuestionImgRectangle";
    } else if (factoryId === 200) {
        factoryIcon = "/img/farm/lab100.png";
        managerIcon = "/img/farm/labManager200.png";
        factoryBought = lab_bought;
        factoryLvl = lab_lvl;
        managerLvl = lab_manager_lvl;
        classIcon = "brainModalTasksItemQuestionImg brainModalTasksItemQuestionImgRectangle";
        // turnover
    } else if (factoryId === 9000) {
        turnoverIcon = "/img/farm/pveIcon100.png";
        factoryBought = true;
        // factoryLvl = lab_lvl;
        // managerLvl = lab_manager_lvl;
        classIcon = "brainModalTasksItemQuestionImg brainModalTasksItemQuestionImgRectangle";
    }

    let currentFactoryLevel,
        currentManagerLvl,
        currentTurnover = 0;
    if (factoryLvl >= maxLevel) {
        currentFactoryLevel = maxLevel;
    } else {
        currentFactoryLevel = factoryLvl;
    }

    if (managerLvl >= maxLevel) {
        currentManagerLvl = maxLevel;
    } else {
        currentManagerLvl = managerLvl;
    }

    if (user.turnover >= maxLevel) {
        currentTurnover = maxLevel;
    } else {
        currentTurnover = user.turnover;
    }

    return (
        <div
            className="brainModalTasksItemWrapper"
            // style={{
            //     justifyContent: `${type === "factory" || type === "turnover" ? "flex-start" : "flex-end"}`,
            // }}
            style={{
                justifyContent: "flex-start",
            }}
        >
            {/* img factory/manager */}
            {factoryBought ? (
                <div className="brainModalTasksItemQuestionImgWrapper">
                    {type === "factory" ? (
                        <img src={factoryIcon} alt="icon factory" className={classIcon} />
                    ) : type === "manager" ? (
                        <img
                            src={managerIcon}
                            alt="icon manager"
                            className="brainModalTasksItemQuestionImg brainModalTasksItemQuestionImgManager"
                        />
                    ) : type === "turnover" ? (
                        <img
                            src={turnoverIcon}
                            alt="icon manager"
                            className="brainModalTasksItemQuestionImg brainModalTasksItemQuestionImgManager"
                        />
                    ) : null}
                </div>
            ) : (
                <div className="brainModalManagerInfoQuestionWrapper brainModalTasksItemQuestion">
                    <div className="brainModalManagerInfoQuestion brainModalTasksItemQuestionText">?</div>
                </div>
            )}

            {/* progress line */}
            <div className="brainFactoryProgressBarLine brainModalTasksItemProgressBarLine">
                {type === "factory" ? (
                    <>
                        <div
                            className="brainFactoryProgressBarOuter"
                            style={{
                                width: `${
                                    90 * levelLinePercentTasks(factoryBought ? currentFactoryLevel : 0, maxLevel)
                                }px`,
                            }}
                        ></div>
                        <div className="brainModalTasksItemProgressText">{`${
                            factoryBought ? currentFactoryLevel : 0
                        }/${maxLevel}`}</div>
                    </>
                ) : type === "manager" ? (
                    <>
                        <div
                            className="brainFactoryProgressBarOuter"
                            style={{
                                width: `${90 * levelLinePercentTasks(currentManagerLvl, maxLevel)}px`,
                            }}
                        ></div>
                        <div className="brainModalTasksItemProgressText">{`${currentManagerLvl}/${maxLevel}`}</div>
                    </>
                ) : type === "turnover" ? (
                    <>
                        <div
                            className="brainFactoryProgressBarOuter"
                            style={{
                                width: `${
                                    90 * levelLinePercentTasks(completed ? maxLevel : currentTurnover, maxLevel)
                                }px`,
                            }}
                        ></div>
                        <div className="brainModalTasksItemProgressText">{`${
                            completed ? maxLevel : currentTurnover
                        }/${maxLevel}`}</div>
                    </>
                ) : null}
            </div>

            {/* img coin */}
            <img src={coinIcon} alt="z-coin" className="brainModalTasksItemCoinImg" />

            {/* % reward */}
            <div className={classValueReward}>{`+ ${value}%`}</div>

            {/* check */}
            {completed ? <img src="/img/farm/check.png" alt="check" className="brainModalTasksItemCheckImg" /> : null}
        </div>
    );
};

export default TasksItem;
