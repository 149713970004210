import "./Faq.scss";

import { Container } from "@mui/material";

const Faq = () => {
    return (
        <Container>
            <div className="pveRulesTitle">Frequently Asked Questions (FAQ)</div>

            <div className="pveFaqQuestion pveFaqFirst">1. What do betting odds depend on?</div>
            <div className="pveFaqAnswer">
                At the moment the odds depend on the rarity of the zombies and are:
                <br />
                <span className="pveFaqColorCommon">- Common:</span> 1.54
                <br />
                <span className="pveFaqColorUncommon">- Uncommon:</span>
                1.43
                <br />
                <span className="pveFaqColorRare">- Rare:</span> 1.33
                <br />
                <span className="pveFaqColorEpic">- Epic:</span> 1.25
                <br />
                <span className="pveFaqColorLegendary">- Legendary:</span> 1.18
                <br />
                The dependence of coefficients on rarity and the coefficients themselves can be changed.
            </div>

            <div className="pveFaqQuestion pveFaqFirst">2. What should I do if I see an error message?</div>
            <div className="pveFaqAnswer">
                First, try to do what the error description says. <br />
                If you did what is written in the error description and it didn't help, reload the page and try again.{" "}
                <br />
                If that didn't help either, then you need to take a full screenshot of the screen where the error
                message is. Then describe what you want to do and send a message with a screenshot in{" "}
                <a className="pveInstructionLink" href="https://discord.gg/TfBFUEvTrd" target="_blank" rel="noreferrer">
                    discord 2/3/Z
                </a>{" "}
                to the z-game channel.
            </div>

            <div className="pveFaqQuestion pveFaqFirst">
                3. I get kicked out of my account periodically. What do I do?
            </div>
            <div className="pveFaqAnswer">You must allow all cookies for z-game.fun in your browser settings.</div>

            <div className="pveFaqQuestion pveFaqFirst">4. I can't register or log in. The buttons are not active.</div>
            <div className="pveFaqAnswer">
                There should be an "I am not a robot" checkbox between the "Email", "Password" fields and the buttons.
                If you don't see it, use a VPN to log in or register. After successful login/registration you can
                disable the VPN.
            </div>

            <div className="pveFaqQuestion pveFaqFirst">
                5. The "Roll" button on the faucet is not active. I can't collect the reward.
            </div>
            <div className="pveFaqAnswer">
                To make the "Roll" button active you need to disable your ad blocker and click on the "I am not a robot"
                checkbox. If you do not see the "I am not a robot" checkbox - use a VPN. Also the reward can be
                collected not earlier than 1 hour after the last collection. If a timer is displayed below the "Roll"
                button, wait until the timer reaches 0.
            </div>

            <div className="pveFaqQuestion pveFaqFirst">
                6. I can't connect my wallet for deposit or withdrawal. What should I do?
            </div>
            <div className="pveFaqAnswer">
                Select or add (
                <a className="pveInstructionLink" href="https://chainlist.org" target="_blank" rel="noreferrer">
                    chainlist.org
                </a>
                ) the "Fantom" network in your wallet and try connecting the wallet again.
            </div>

            <div className="pveFaqQuestion pveFaqFirst">
                7. The "DEPOSIT" or "WITHDRAWAL" button is not active. What to do?
            </div>
            <div className="pveFaqAnswer pveFaqLastAnswer">
                Need to start a new PvE game (click the “NEW ZOMBIE” button) and wait for a new zombie to appear, and
                then make a deposit or withdrawal.
            </div>
        </Container>
    );
};

export default Faq;
