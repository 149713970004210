import { useSelector } from "react-redux";

import LoginRegister from "../loginRegister/LoginRegister";
import Deposit from "../deposit/Deposit";
import Withdraw from "../withdraw/Withdraw";
import MobileMessage from "../mobileMessage/MobileMessage";

import Container from "@mui/material/Container";

const PaymentsPage = () => {
    const isAuth = useSelector((state) => state.authorization.isAuth);

    return (
        <>
            <Container>
                {!isAuth ? (
                    <LoginRegister />
                ) : (
                    <>
                        <Deposit />
                        <img
                            style={{ marginTop: "30px" }}
                            src="/img/bloody-line-750-25.png"
                            alt="bloody line"
                            className="bloodyLine"
                        />
                        <Withdraw />
                    </>
                )}

                <MobileMessage />
            </Container>
        </>
    );
};

export default PaymentsPage;
