// function for cut email -> test@gmail.com -> tes...@gmail.com
export const cutEmail = (email) => {
    const arr = email.split("@");
    let onePart = arr[0];
    let result = "";

    if (arr[0].length > 3) {
        onePart = arr[0][0] + arr[0][1] + arr[0][2];
        result = onePart + "...@" + arr[1];
    } else {
        result = onePart + "@" + arr[1];
    }

    return result;
};
