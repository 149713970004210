import "./Rules.scss";

import { Container } from "@mui/material";

const Rules = () => {
    return (
        <Container>
            <div className="pveRulesTitle">Rules of use</div>
            <div className="pveRulesWrapper">
                <div className="pveRulesItem">
                    1. The game is provided on an “as is” basis. Administration
                    is not responsible for possible incorrect operation of the
                    game.
                </div>
                <div className="pveRulesItem">
                    2. The administration is not responsible for the possible
                    loss of your funds under any conditions.
                </div>
                <div className="pveRulesItem">
                    3. Compensation for possible incorrect operation of the game
                    is not provided by default. Compensation decisions are made
                    on an individual basis.
                </div>
                <div className="pveRulesItem">
                    4. Any account may be blocked without explanation.
                </div>
                <div className="pveRulesItem">
                    5. All or part of the features may be disabled for a
                    specific or all accounts without explanation.
                </div>
                <div className="pveRulesItem">
                    6. New features may be added to the game or current features
                    may be removed without notice to users.
                </div>
                <div className="pveRulesItem">
                    7. All official game information is published in{" "}
                    <a
                        className="pveInstructionLink"
                        href="https://discord.gg/TfBFUEvTrd"
                        target="_blank"
                        rel="noreferrer"
                    >
                        discord 2/3/Z
                    </a>
                    .
                </div>
                <div className="pveRulesItem">
                    8. Account data can be edited without notifying users,
                    including changing the account balance.
                </div>
                <div className="pveRulesItem">
                    9. Any bet can be canceled without explanation.
                </div>
                <div className="pveRulesItem">
                    10. Developers do not give advice on blockchain, web3
                    wallets, blockchain explorer. If you do not know what
                    blockchain, web3 wallet, blockchain explorer is, you should
                    not play this game. For all questions related to these
                    terms, use{" "}
                    <a
                        className="pveInstructionLink"
                        href="https://google.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        google.com
                    </a>
                    .
                </div>
                <div className="pveRulesItem">
                    11. It is forbidden to have more than 1 account for one
                    person. If multiple accounts are found for the same person,
                    all of them will be blocked with no possibility of
                    withdrawal.
                </div>
                <div className="pveRulesItem">
                    12. By creating an account, the user agrees to all the rules
                    published on the{" "}
                    <a
                        className="pveInstructionLink"
                        href="https://z-game.fun"
                        target="_blank"
                        rel="noreferrer"
                    >
                        z-game.fun
                    </a>{" "}
                    website and assumes all possible risks.
                </div>
            </div>
        </Container>
    );
};

export default Rules;
