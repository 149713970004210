import "./Footer.scss";

import Container from "@mui/material/Container";

const Footer = () => {
    return (
        <div className="footerWrapper">
            <Container>
                <div className="footerContent">
                    <img
                        className="footerSlimeImg-1"
                        src="/img/footer-slime-1.png"
                        alt="slime"
                    ></img>
                    <div className="footerDate">
                        © {new Date().getFullYear()} Z-game created by
                        <a
                            className="footerMyLink"
                            href="https://twitter.com/Crypto_Nik22"
                        >
                            CryptoNik22
                        </a>
                    </div>
                    <div className="footerLinkWrapper">
                        <a
                            className="footerLink"
                            href="https://twitter.com/2omb3ombZomb"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                className="footerSocialImg"
                                alt="x icon"
                                src="/img/social-x.png"
                            />
                        </a>

                        <a
                            className="footerLink"
                            href="https://discord.gg/TfBFUEvTrd"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                className="footerSocialImg"
                                alt="discord icon"
                                src="/img/social-discord.png"
                            />
                        </a>
                    </div>
                    <img
                        className="footerSlimeImg-2"
                        src="/img/footer-slime-2.png"
                        alt="slime"
                    ></img>
                </div>
            </Container>
        </div>
    );
};

export default Footer;
