/* global BigInt */
import "./Factory.scss";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    setDecreaseWarehouseMain,
    setWarehouseMain,
    setDecreaseWarehousePort,
    setLab_value,
    setLab_base_value,
    setLab_lvl,
    setLab_price_lvl_up,
    setLab_work,
    setLab_manager_lvl_up,
    setLab_prod_ready,
    setLab_speed,
    setLab_process,
    setLab_auto_process,
} from "../../../store/brainFarmSlice";

import ModalManager from "./ModalManager";
import {
    stopPlayAnimation,
    disableFactoryCursorPointer,
    multipleAnimation,
    circularAnimation1Time,
    levelLinePercentPortLab,
    cursorPointer,
    openModal,
    closeModal,
    abbreviationNumbers,
} from "../functions";

import Button from "@mui/material/Button";

const Lab = (props) => {
    const { factoryId, saveIntervalId, saveInterval, circularAnimationName, shakeAnimationName, positionClass } = props;

    const dispatch = useDispatch();

    const intervalHandProdRef = useRef(0);
    const intervalShakeRef = useRef(0);
    const intervalAutoProdRef = useRef(0);

    const [showModalManager, setShowModalManager] = useState(false);
    const [factoryMultipleLvl, setFactoryMultipleLvl] = useState(2);

    const handleCloseModalManager = () => {
        setShowModalManager(false);
        closeModal();
    };
    const handleShowModalManager = () => {
        setShowModalManager(true);
        openModal();
    };

    const warehouse_main = useSelector((state) => state.brainFarm.warehouse_main);
    const warehouse_port = useSelector((state) => state.brainFarm.warehouse_port);

    const port_c_lvl_up = useSelector((state) => state.brainFarm.port_c_lvl_up);
    const labProcess = useSelector((state) => state.brainFarm.lab_process);
    const labAutoProcess = useSelector((state) => state.brainFarm.lab_auto_process);
    const saveLoaded = useSelector((state) => state.brainFarm.saveLoaded);

    const lab_value = useSelector((state) => state.brainFarm.lab_value);
    const lab_base_value = useSelector((state) => state.brainFarm.lab_base_value);
    const lab_init_base_value = useSelector((state) => state.brainFarm.lab_init_base_value);
    const lab_lvl = useSelector((state) => state.brainFarm.lab_lvl);
    const lab_speed = useSelector((state) => state.brainFarm.lab_speed);
    const lab_price_lvl_up = useSelector((state) => state.brainFarm.lab_price_lvl_up);
    const lab_work = useSelector((state) => state.brainFarm.lab_work);
    const lab_manager_lvl = useSelector((state) => state.brainFarm.lab_manager_lvl);
    const lab_manager_price = useSelector((state) => state.brainFarm.lab_manager_price);
    const lab_manager_value = useSelector((state) => state.brainFarm.lab_manager_value);
    const lab_bought = useSelector((state) => state.brainFarm.lab_bought);
    const lab_prod_ready = useSelector((state) => state.brainFarm.lab_prod_ready);

    let factoryValue,
        factoryBaseValue,
        factoryInitBaseValue,
        factoryLvl,
        factorySpeed,
        factoryPriceLvlUp,
        factoryWork,
        factoryManager,
        factoryManagerPrice,
        factoryManagerValue,
        factoryBought,
        factoryProdReady,
        setFactoryWork,
        setFactoryLvl,
        setFactoryValue,
        setFactoryBaseValue,
        setFactoryPriceLvlUp,
        setFactoryManagerLvlUp,
        setFactoryProdReady,
        setFactorySpeed = 0;

    if (factoryId === 200) {
        factoryValue = BigInt(lab_value);
        factoryBaseValue = BigInt(lab_base_value);
        factoryInitBaseValue = BigInt(lab_init_base_value);
        factoryLvl = lab_lvl;
        factorySpeed = lab_speed;
        factoryPriceLvlUp = BigInt(lab_price_lvl_up);
        factoryWork = lab_work;
        factoryManager = lab_manager_lvl;
        factoryManagerPrice = lab_manager_price;
        factoryManagerValue = lab_manager_value;
        factoryBought = lab_bought;
        factoryProdReady = lab_prod_ready;

        setFactoryWork = (action) => {
            return setLab_work(action);
        };
        setFactoryLvl = (action) => {
            return setLab_lvl(action);
        };
        setFactoryValue = (action) => {
            return setLab_value(action);
        };
        setFactoryBaseValue = (action) => {
            return setLab_base_value(action);
        };
        setFactoryPriceLvlUp = (action) => {
            return setLab_price_lvl_up(action);
        };
        setFactoryManagerLvlUp = (action) => {
            return setLab_manager_lvl_up(action);
        };
        setFactoryProdReady = (action) => {
            return setLab_prod_ready(action);
        };
        setFactorySpeed = (action) => {
            return setLab_speed(action);
        };
    }

    const managerImg = "/img/farm/labManager200.png";
    const mainIcon = "/img/farm/lab100.png";

    const factoryBuy = () => {
        // shake animation for hand collection
        const timerProdId = setTimeout(() => {
            // launch shake animation after hand collection
            stopPlayAnimation(shakeAnimationName);

            dispatch(setFactoryProdReady(true));

            const circleWrapper = document.querySelectorAll(".brainFactoryCircleWrapper");
            if (circleWrapper) {
                circleWrapper.forEach((elem) => {
                    const elemId = elem.getAttribute("data-factory-id");

                    if (elemId == factoryId) {
                        elem.style.animation = `${shakeAnimationName} 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both`;
                        elem.style.cursor = "pointer";

                        clearInterval(intervalShakeRef.current);

                        const timerIdShakeAnim = setInterval(() => {
                            stopPlayAnimation(shakeAnimationName);
                        }, 4000);

                        intervalShakeRef.current = timerIdShakeAnim;
                    }
                });
            }

            circularAnimation1Time(factoryId, circularAnimationName, 2, stopPlayAnimation);
        }, factorySpeed);
        intervalHandProdRef.current = timerProdId;
    };

    const factoryHandCollect = () => {
        if (factoryProdReady && !factoryWork) {
            clearInterval(intervalShakeRef.current);

            convertProcess();

            dispatch(setFactoryProdReady(false));

            circularAnimation1Time(factoryId, circularAnimationName, factorySpeed, stopPlayAnimation);

            disableFactoryCursorPointer(factoryId);

            factoryBuy();
        }

        // first button press
        if (!factoryProdReady && !labProcess && !factoryWork && factoryManager === 0) {
            circularAnimation1Time(factoryId, circularAnimationName, factorySpeed, stopPlayAnimation);

            disableFactoryCursorPointer(factoryId);

            dispatch(setLab_process(true));

            factoryBuy();
        }
    };

    const convertProcess = () => {
        const delta = BigInt(warehouse_port) - factoryValue;

        if (delta >= BigInt(0)) {
            dispatch(setDecreaseWarehousePort(factoryValue.toString()));
            dispatch(setWarehouseMain(factoryValue.toString()));
        } else if (delta < BigInt(0)) {
            dispatch(setDecreaseWarehousePort(warehouse_port));
            dispatch(setWarehouseMain(warehouse_port));
        }

        // console.log(delta);
    };

    const factoryPlusProduct = () => {
        circularAnimation1Time(factoryId, circularAnimationName, factorySpeed, stopPlayAnimation);

        dispatch(setLab_auto_process());
    };

    // buy managers
    useEffect(() => {
        if (!factoryWork && factoryManager > 0) {
            const timerIdFactory = setInterval(factoryPlusProduct, factorySpeed);
            intervalAutoProdRef.current = timerIdFactory;

            dispatch(setFactoryWork(true));
        }

        return () => {
            clearInterval(intervalAutoProdRef.current);
        };
    }, [factoryManager, factoryLvl]);

    const factoryPlusLvl = () => {
        // active hand mode (wait collect)
        if (factoryProdReady) {
            factoryHandCollect();
            // active hand mode (production process)
        } else if (!factoryProdReady && factoryManager === 0) {
            clearTimeout(intervalHandProdRef.current);
            factoryBuy();
        }

        stopPlayAnimation(circularAnimationName);

        clearInterval(intervalAutoProdRef.current);
        dispatch(setFactoryLvl(1));

        if (factoryLvl < 9) {
            dispatch(setFactoryValue((factoryValue + factoryBaseValue).toString()));
        } else if (factoryLvl === 9) {
            dispatch(setFactoryValue(((factoryValue + factoryBaseValue) * BigInt(2)).toString()));
            setFactoryMultipleLvl(2);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 9 && factoryLvl < 24) {
            dispatch(setFactoryValue((factoryValue + factoryBaseValue * BigInt(2)).toString()));
        } else if (factoryLvl === 24) {
            dispatch(setFactoryValue(((factoryValue + factoryBaseValue * BigInt(2)) * BigInt(4)).toString()));
            setFactoryMultipleLvl(4);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 24 && factoryLvl < 49) {
            dispatch(setFactoryValue((factoryValue + factoryBaseValue * BigInt(2) * BigInt(4)).toString()));
        } else if (factoryLvl === 49) {
            dispatch(
                setFactoryValue(((factoryValue + factoryBaseValue * BigInt(2) * BigInt(4)) * BigInt(8)).toString())
            );
            setFactoryMultipleLvl(8);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 49 && factoryLvl < 99) {
            dispatch(setFactoryValue((factoryValue + factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8)).toString()));
        } else if (factoryLvl === 99) {
            dispatch(
                setFactoryValue(
                    ((factoryValue + factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8)) * BigInt(16)).toString()
                )
            );
            setFactoryMultipleLvl(16);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 99 && factoryLvl < 149) {
            dispatch(
                setFactoryValue(
                    (factoryValue + factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16)).toString()
                )
            );
        } else if (factoryLvl === 149) {
            dispatch(
                setFactoryValue(
                    (
                        (factoryValue + factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16)) *
                        BigInt(32)
                    ).toString()
                )
            );
            setFactoryMultipleLvl(32);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 149 && factoryLvl < 199) {
            dispatch(
                setFactoryValue(
                    (
                        factoryValue +
                        factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16) * BigInt(32)
                    ).toString()
                )
            );
        } else if (factoryLvl === 199) {
            dispatch(
                setFactoryValue(
                    (
                        (factoryValue +
                            factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16) * BigInt(32)) *
                        BigInt(64)
                    ).toString()
                )
            );
            setFactoryMultipleLvl(64);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 199 && factoryLvl < 249) {
            dispatch(
                setFactoryValue(
                    (
                        factoryValue +
                        factoryBaseValue * BigInt(2) * BigInt(4) * BigInt(8) * BigInt(16) * BigInt(32) * BigInt(64)
                    ).toString()
                )
            );
        } else if (factoryLvl === 249) {
            dispatch(
                setFactoryValue(
                    (
                        (factoryValue +
                            factoryBaseValue *
                                BigInt(2) *
                                BigInt(4) *
                                BigInt(8) *
                                BigInt(16) *
                                BigInt(32) *
                                BigInt(64)) *
                        BigInt(4)
                    ).toString()
                )
            );
            setFactoryMultipleLvl(4);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 249 && factoryLvl < 299) {
            dispatch(
                setFactoryValue(
                    (
                        factoryValue +
                        factoryBaseValue *
                            BigInt(2) *
                            BigInt(4) *
                            BigInt(8) *
                            BigInt(16) *
                            BigInt(32) *
                            BigInt(64) *
                            BigInt(4)
                    ).toString()
                )
            );
        } else if (factoryLvl === 299) {
            dispatch(
                setFactoryValue(
                    (
                        (factoryValue +
                            factoryBaseValue *
                                BigInt(2) *
                                BigInt(4) *
                                BigInt(8) *
                                BigInt(16) *
                                BigInt(32) *
                                BigInt(64) *
                                BigInt(4)) *
                        BigInt(4)
                    ).toString()
                )
            );
            setFactoryMultipleLvl(4);
            multipleAnimation(factoryId);
        } else if (factoryLvl > 299) {
            dispatch(
                setFactoryValue(
                    (
                        factoryValue +
                        factoryBaseValue *
                            BigInt(2) *
                            BigInt(4) *
                            BigInt(8) *
                            BigInt(16) *
                            BigInt(32) *
                            BigInt(64) *
                            BigInt(4) *
                            BigInt(4)
                    ).toString()
                )
            );
        }

        dispatch(
            setFactoryPriceLvlUp(
                ((factoryPriceLvlUp * BigInt(Math.round(port_c_lvl_up * 100))) / BigInt(100)).toString()
            )
        );
        dispatch(setDecreaseWarehouseMain(factoryPriceLvlUp.toString()));
        dispatch(setFactoryWork(false));
    };

    useEffect(() => {
        if (!factoryWork && factoryManager === 0) {
            cursorPointer(factoryId);
        }

        return () => {
            clearInterval(intervalHandProdRef.current);
            clearInterval(intervalShakeRef.current);
            clearInterval(intervalAutoProdRef.current);
            dispatch(setFactoryWork(false));
        };
    }, []);

    useEffect(() => {
        if (factoryManager !== 0) {
            convertProcess();
        }
    }, [labAutoProcess]);

    // save loaded
    useEffect(() => {
        if (saveLoaded) {
            if (factoryManager > 0) {
                factoryPlusProduct();
            }
        }
    }, [saveLoaded]);

    return (
        <div className={positionClass}>
            <ModalManager
                open={showModalManager}
                close={handleCloseModalManager}
                type={"lab"}
                factoryManager={factoryManager}
                factoryManagerPrice={factoryManagerPrice}
                factoryBought={factoryBought}
                factoryManagerValue={factoryManagerValue}
                factoryProdReady={factoryProdReady}
                shakeAnimationTimer={intervalShakeRef.current}
                factoryValue={factoryValue}
                setFactoryBaseValue={setFactoryBaseValue}
                factoryInitBaseValue={factoryInitBaseValue}
                setFactoryProdReady={setFactoryProdReady}
                circularAnimationName={circularAnimationName}
                factoryId={factoryId}
                setFactoryWork={setFactoryWork}
                setFactoryManagerLvlUp={setFactoryManagerLvlUp}
                setFactoryValue={setFactoryValue}
                handProductionTimer={intervalHandProdRef.current}
                autoCollectTimer={intervalAutoProdRef.current}
                factorySpeed={factorySpeed}
                setFactorySpeed={setFactorySpeed}
                factoryPlusProduct={factoryPlusProduct}
                factoryLvl={factoryLvl}
                managerImg={managerImg}
                managerIcon={mainIcon}
                saveIntervalId={saveIntervalId}
                saveInterval={saveInterval}
            />

            <div className="brainFactoryWrapper">
                <div
                    className="brainFactoryManagerWrapper brainFactoryManagerWrapperLab"
                    onClick={handleShowModalManager}
                >
                    {factoryManager > 0 ? (
                        <img src={managerImg} alt="manager icon" className="brainFactoryManagerImg" />
                    ) : (
                        <div className="brainFactoryManagerNoImg">?</div>
                    )}

                    <img src="/img/farm/farmArrow.png" alt="green arrow" className="brainFactoryManagerArrow" />
                </div>

                <div className="brainFactoryCircleWrapper" data-factory-id={factoryId} onClick={factoryHandCollect}>
                    <img src="/img/farm/factoryBaseCircle.png" alt="factory circle" className="brainFactoryCircleImg" />
                    <div className="brainFactoryCircle">
                        <img src={mainIcon} alt="factory icon" className="brainFactoryMainIcon200" />
                        <div className="brainFactoryCircleValue">{abbreviationNumbers(factoryValue)}</div>
                        <div className="brainFactoryMultiple" data-factory-id={factoryId}>
                            x {factoryMultipleLvl}
                        </div>
                    </div>

                    <div className="brainFactoryCircular">
                        <div className="brainFactoryCircularProgressWrapper" data-factory-id={factoryId}>
                            <progress className="brainFactoryCircularProgress " min="0" max="100"></progress>
                        </div>
                    </div>
                </div>

                <div className="brainFactoryRectangle">
                    <>
                        {/* <img src="/img/farm/factoryBaseRectangle.png" alt="factory circle" /> */}
                        <div className="brainFactoryLabRectangle"></div>
                        <div className="brainFactoryLevel brainFactoryLevelLab">{factoryLvl}</div>
                        <div className="brainFactoryProgressBarLine brainFactoryProgressBarLineLab">
                            <div
                                className="brainFactoryProgressBarOuter"
                                style={{
                                    width: `${70 * levelLinePercentPortLab(factoryLvl)}px`,
                                }}
                            ></div>
                        </div>
                        <Button
                            className="brainFactoryBtnLvl brainFactoryBtnLab"
                            variant="contained"
                            color="farmMain"
                            onClick={factoryPlusLvl}
                            disabled={BigInt(warehouse_main) < factoryPriceLvlUp}
                            startIcon={
                                <img
                                    src="/img/farm/brainIcon.png"
                                    alt="brain"
                                    className={
                                        BigInt(warehouse_main) < factoryPriceLvlUp
                                            ? "brainFactoryBtnImg brainFactoryBtnImgDisabled"
                                            : "brainFactoryBtnImg"
                                    }
                                />
                            }
                            sx={{
                                "&.Mui-disabled": {
                                    background: "#818181",
                                    color: "#c0c0c0",
                                    border: "2px solid #706363 !important",
                                },
                            }}
                        >
                            {abbreviationNumbers(factoryPriceLvlUp)}
                        </Button>
                    </>
                </div>
            </div>
        </div>
    );
};

export default Lab;
